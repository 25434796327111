import { get } from '../../../../api/FetchAPI';
import { WITHOUT_PRESCRIBING_REASONS } from '../../../../api/Api';
import { setApiErrorCode } from '../../chatList/store/action';

// 以下、内部用reduxのデータ処理
const setReasons = (reasons: any) => ({
  type: 'set_reasons',
  reasons
})

// 以下、外部用reduxのデータ処理
export const updateEndDiagnosisErrCode = (errCode: any) => ({
  type: 'update_end_diagnosis_err_code',
  errCode
})

export const updateEndDiagnosisErrMessage = (errMessage: any) => ({
  type: 'update_end_diagnosis_err_message',
  errMessage
})

export const updateEndDiagnosisLoading = (flag: boolean) => ({
  type: 'update_end_diagnosis_loading',
  flag
})

export const updateEndDiagnosisModal = (flag: boolean) => ({
  type: 'update_end_diagnosis_modal',
  flag
})

export const updateWithoutReasonModal = (flag: boolean) => ({
  type: 'update_without_reasons_modal',
  flag
})

export const updateConfirmMedcineModal = (flag: boolean) => ({
  type: 'update_confirm_medicine_modal',
  flag
})

//処方せず診療終了理由の取得
export const getWithoutPrescribingReason = (auth: any) => {
  return async (dispatch: any) => {
    try {
      const response = await get(WITHOUT_PRESCRIBING_REASONS, null, auth) as any;
      if (response.data.errorCode === null) {
        await dispatch(setReasons(response.data.WithoutPrescribingReasons));
      } else {
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
    } catch (e: any) {
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}