//データフォーマット:
const defaultState = ({
  //診療前一覧リスト
  beforeDiagnoses: [],
  //担当済み、患者返信しない件数
  assignedCount: 0,
  //未担当件数
  unAssignedCount: 0,
  //検索エラー
  showSearchErrorModal: false,
  //担当モード
  showTakeOnModal: false,
  //担当診療コード
  takeOnDiagnosisCode: "",
  //担当済みモード
  showAlreadyTakeOnModal: false,
  //ページ
  pages: {},
  //検索モードかとうか
  isSearchBeforeMode: false,
  searchBeforeCondition: {
    pageNumber: 1,
    baseListCount: 20
  },
  //ローディング
  beforeLoading: true,
  //検索ローディング
  searchBeforeLoading: false,
  //担当処理中ローディング
  takeOnLoading: false,
  //担当エラーコード
  takeOnErrorCode: null,
  //担当エラーメッセージ
  takeOnErrorMessage: null,
  //診療前一覧情報によって変化された随机数
  beforeRandomNumber: -1,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'set_before_diagnoses':
      return { ...state, beforeDiagnoses: action.list }
    case 'set_assigned_count':
      return { ...state, assignedCount: action.count }
    case 'set_un_assigned_count':
      return { ...state, unAssignedCount: action.count }
    case 'update_search_error_modal':
      return { ...state, showSearchErrorModal: action.flag }
    case 'update_take_on_modal':
      return { ...state, showTakeOnModal: action.flag }
    case 'update_take_on_diagnosis_code':
      return { ...state, takeOnDiagnosisCode: action.diagnosisCode }
    case 'update_already_take_on_modal':
      return { ...state, showAlreadyTakeOnModal: action.flag }
    case 'update_before_pages':
      return { ...state, pages: action.page }
    case 'update_before_pages_now':
      { return { ...state, pages: { ...state.pages, now: action.now } } }
    case 'update_search_before_mode':
      return { ...state, isSearchBeforeMode: action.flag }
    case 'update_before_loading':
      return { ...state, beforeLoading: action.loading }
    case 'update_search_before_loading':
      return { ...state, searchBeforeLoading: action.loading }
    case 'update_take_on_loading':
      return { ...state, takeOnLoading: action.loading }
    case 'update_take_on_error_code':
      return { ...state, takeOnErrorCode: action.errorCode }
    case 'update_take_on_error_msg':
      return { ...state, takeOnErrorMessage: action.errorMsg }
    case 'update_before_random_number':
      return { ...state, beforeRandomNumber: action.number }
    default:
      return state;
  }
}

export default variable;
