import { ERR_MSG_005, TOP_MSG } from "../config/constant";

function Err404Page() {
  return (
    <section className="main-content columns">
      <div className="container column is-10" style={{ margin: '0px auto' }}>
        <div className="content" style={{ margin: '40px' }}>
          <h1 className="has-margin-1" style={{ paddingTop: '0px' }}>
            {ERR_MSG_005}
          </h1>
          <a href="/" className="has-margin-1 link-text-gray nuxt-link-active">
            {TOP_MSG}
          </a>
        </div>
      </div>
    </section>
  )
}

export default Err404Page;