export const ERR_CODE_A001 = "A001";
export const ERR_CODE_A002 = "A002";
export const ERR_CODE_A003 = "A003";
export const ERR_CODE_A013 = "A013";
export const ERR_CODE_A014 = "A014";
export const ERR_CODE_A017 = "A017";
export const ERR_CODE_A018 = "A018";
export const ERR_CODE_A019 = "A019";
export const ERR_CODE_B001 = "B001";
export const ERR_CODE_C002 = "C002";

export const ERR_MSG_001 = "エラーが発生しました。\nしばらくたってからもう一度お試しください。";
export const ERR_MSG_002 = "Error!!\n過去の日付・時間は選択できません。";
export const ERR_MSG_003 = "エラーが発生しました。\nしばらく時間が経ってからアクセスしてください。";
export const ERR_MSG_004 = "エラーコード：";
export const ERR_MSG_005 = "ページが見つかりません。";
export const ERR_MSG_006 = "※既に診療が終了しています。";
export const ERR_MSG_007 = "※不正なリクエストです。";
export const ERR_MSG_008 = "エラーが発生しました。\n下記の診療コードとエラーコードをコピーし、管理者に連絡してください。\n";
export const ERR_MSG_009 = "診療終了しましたが、診療終了時のチャットメッセージの送信ができませんでした。\n下記の診療コードとエラーコードをコピーし、管理者に連絡してください。\n※診療終了済みの診療室を開く時、チャット履歴で診療終了チャットメッセージが確認できません。";
export const ERR_MSG_010 = "提案中処方薬の登録に失敗しました。\nしばらく時間をおいてから、再度薬剤提案を行ってください。";
export const ERR_MSG_011 = "ログインできませんでした。";
export const ERR_MSG_012 = "指定されたファイルが存在しません";
export const ERR_MSG_013 = "特定ユーザーCSVファイルのフォーマットが適切ではありません";
export const ERR_MSG_014 = "データがみつかりません";
export const ERR_MSG_VIDEO_FILTER_1 = "条件を入力してください。";
export const ERR_MSG_VIDEO_FILTER_2 = "終了日時は開始日時よりも遅い日時を指定してください。";

export const TOP_MSG = "トップへ";

export const PROPOSE_MESSAGE_001 = "今回は下記のお薬を提案いたします。";
export const PROPOSE_MESSAGE_002 = "服用方法や副作用、詳細なプランについてのご案内ですので、必ず全てご確認ください。";
export const PROPOSE_MESSAGE_003 = "■今回処方のご提案をするお薬・プラン名";
export const PROPOSE_MESSAGE_004 = "■お薬に関する説明";
export const PROPOSE_MESSAGE_005 = "■よくある質問（飲み始め、副作用、服用時間のずれ、飲み忘れ、生理日移動、決済予定日変更など）";
export const PROPOSE_MESSAGE_006 = "https://secure.okbiz.jp/mti7/?site_domain=lnlnokusuribinfaq001";
export const PROPOSE_MESSAGE_007 = "✔お薬の処方にあたり、以下のカード内容を必ず全て確認ください。";
export const PROPOSE_MESSAGE_008 = "✔よくある質問もご確認ください。";
export const PROPOSE_MESSAGE_009 = "✔処方内容の最終確定は、ビデオ診療で同意いただいた内容で確定します。";
export const PROPOSE_MESSAGE_010 = "✔ご不明点は遠慮なくチャットにてご質問ください。";
export const PROPOSE_MESSAGE_011 = "■■■■■\n上記をご確認いただき、問題なければチャットで「確認しました」とお送りください。";

export const VIDEO_MESSAGE_001 = "ビデオ診療用のURLを発行いたしました。\n予約日時は、【";
export const VIDEO_MESSAGE_002 = "～】です。\n開始時刻になりましたら下記のURLから入室してください。";
export const VIDEO_MESSAGE_003 = "順番に診療しますので、ビデオとマイクをONにして、「ビデオ診療への入室を医師に通知する」をタップして医師に通知したら、そのままビデオ診療画面でお待ちください。";
export const VIDEO_MESSAGE_004 = "※事前準備※";
export const VIDEO_MESSAGE_005 = "・写真付きの身分証明書（免許証・マイナンバーカードなど）1種類 または 公的身分証明書2種類を準備してお待ちください。";
export const VIDEO_MESSAGE_006 = "・ビデオの使い方についてはこちらをご確認ください。";
export const VIDEO_MESSAGE_007 = "https://secure.okbiz.jp/mti7/faq/show/31787";

export const VIDEO_DATETIME_MESSAGE_001 = "それでは、【";
export const VIDEO_DATETIME_MESSAGE_002 = "～】の枠で宜しくお願いいたします。\nお時間になる前にこちらのチャットにてビデオ診療用のURLをお送りいたします。\n必ず下記注意事項をご確認いただき、お手元に身分証明書をご用意の上、URLをタップしてください。";
export const VIDEO_DATETIME_MESSAGE_003 = "★ビデオ診療についての注意事項★";
export const VIDEO_DATETIME_MESSAGE_004 = "■電波の良い場所で、お手元に写真付きの身分証明書（免許書・マイナンバーカードなど）1種類　または　公的身分証明書2種類を準備してお待ちください。";
export const VIDEO_DATETIME_MESSAGE_005 = "■ビデオ/マイクONが必須となります。ビデオはブラウザで立ち上がりますので、事前にブラウザのビデオ/マイクの権限の設定をご確認いただき、ビデオ/マイクのボタンが正常に押せるか（マイクのマーク/ビデオのマークを斜線が入っていない状況にできるか）を確認した状態でご対応をお願いいたします。";
export const VIDEO_DATETIME_MESSAGE_006 = "ブラウザの権限設定方法はこちら→";
export const VIDEO_DATETIME_MESSAGE_007 = "https://secure.okbiz.jp/mti7/faq/show/31699";
export const VIDEO_DATETIME_MESSAGE_008 = "■お時間にご入室いただけない場合、当日のビデオ診療ができない可能性がございますのでご了承ください。日時の変更が必要な場合、事前にこちらのチャットでのご連絡をお願いいたします。";

export const PROPOSE_PRICE_PLAN_001 = "定期プラン";
export const PROPOSE_PRICE_PLAN_002 = "おまとめプラン";
export const PROPOSE_SHEET_001 = "13シート";
export const PROPOSE_SHEET_002 = "6シート";

// ビデオ診療　希望する曜日・時間帯
export const DESIRED_VIDEO_TIME_ITEM = {
  // 平日　午前/午後/夜
  WEEKDAY_MORNING:'1',
  WEEKDAY_AFTERNOON:'2',
  WEEKDAY_NIGHT:'3',
  // 土日祝日　午前/午後/夜
  HOLIDAY_MORNING:'4',
  HOLIDAY_AFTERNOON:'5',
  HOLIDAY_NIGHT:'6',
}

export const RELEASE_VERSION = "2.4.0";
export const SENTRT_CONTEXT_ID = "okusuribin";