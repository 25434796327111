import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from 'react-router-dom';
import AuthRouter from './components/AuthRouter';
import Login from './components/login/Login';
import CommonModal from './components/CommonModal';
import Err404Page from './components/Err404Page';
import ErrApiPage from './components/ErrApiPage';
import { refreshAcsToken, setPersonalAcsToken, setSelectedDiagnosisCode } from './components/diagnosing/chatList/store/action';
import './style/app.scss';
import './style/design.scss';

function App() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const apiErrorCode = useSelector<any, any>((state) => state.chat.apiErrorCode);
  const apiErrorMessage = useSelector<any, any>((state) => state.chat.apiErrorMessage);
  const personalAcsTokenExpiredOnJst = useSelector<any, any>((state) => state.chat.personalAcsTokenExpiredOnJst);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const dateDiff = useMemo(() => {
    return new Date(personalAcsTokenExpiredOnJst).getTime() - new Date().getTime();
  }, [personalAcsTokenExpiredOnJst]);

  const countDown = useMemo(() => {
    return dateDiff - 30 * 60 * 1000;
  }, [dateDiff]);

  const params = useMemo(() => {
    return new URLSearchParams(window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  const historyDiagnosisCode = useMemo(() => {
    //URLにDiagnosisCodeの取得
    return params.get("diagnosisCode") as string;
  }, [params]);

  //URLに検索パラメータの取得
  const searchParams = useMemo(() => {
    const pageNo = params.get("page");
    const surname = params.get("surname") as string;
    const givenName = params.get("givenName") as string;
    return {
      pageNo: pageNo,
      surname: surname,
      givenName: givenName,
    };
  }, [params]);

  useEffect(() => {
    if (isManager && (window.location.href.endsWith("/notice") || window.location.href.endsWith("/notice/"))) {
      navigate("/notice/search");
    }
  }, [isManager, navigate]);

  useEffect(() => {
    //ACSトーケンを更新
    if (personalAcsTokenExpiredOnJst !== '') {
      const timer = setTimeout(async () => {
        const acsToken = await refreshAcsToken(msalToken, dispatch);
        if (acsToken !== null) {
          dispatch(setPersonalAcsToken(acsToken));
        }
      }, countDown);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsTokenExpiredOnJst]);

  //ブラウザロールバックイベント
  useEffect(() => {
    window.onpopstate = function () {
      //患者選択の状態を消去
      dispatch(setSelectedDiagnosisCode(''));
    };
    return () => {
      window.onpopstate = null;
    };
  }, [dispatch]);

  //diagnosisTab 0:診療終了 1:診療中一覧 2:サイトポリシ 3:診療前一覧 4:配送管理 5:お知らせ 9:ログイン
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/beforeDiagnoses" element={<AuthRouter diagnosisTab={3} searchParams={searchParams}><CommonModal diagnosisTab={3} searchParams={searchParams} /></AuthRouter>}></Route>
      <Route path="/diagnosis" element={<AuthRouter diagnosisTab={1}><CommonModal diagnosisTab={1} /></AuthRouter>}></Route>
      <Route path="/" element={<AuthRouter diagnosisTab={3} searchParams={searchParams}><CommonModal diagnosisTab={3} searchParams={searchParams} /></AuthRouter>}></Route>
      <Route path="/err" element={<ErrApiPage errCode={apiErrorCode} errMsg={apiErrorMessage} />}></Route>
      <Route path="/history" element={<AuthRouter diagnosisTab={0} diagnosisCode={historyDiagnosisCode} searchParams={searchParams}><CommonModal diagnosisTab={0} diagnosisCode={historyDiagnosisCode} searchParams={searchParams} /></AuthRouter>}></Route>
      <Route path="/sitePolicy" element={<AuthRouter diagnosisTab={2}><CommonModal diagnosisTab={2} /></AuthRouter>}></Route >
      <Route path="/deliveries" element={<AuthRouter diagnosisTab={4}> <CommonModal diagnosisTab={4} /></AuthRouter>}></Route>
      <Route path="/notice/*" element={<AuthRouter diagnosisTab={5}> <CommonModal diagnosisTab={5} /></AuthRouter>}></Route>
      <Route path="/*" element={<Err404Page />}></Route>
    </Routes>
  )
}

export default App;