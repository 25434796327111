import { configureStore } from '@reduxjs/toolkit';
import chatReducer from '../components/diagnosing/chatList/store/reducer';
import loginReducer from '../components/login/store/reducer';
import historyReducer from '../components/history/store/reducer';
import clinicReducer from '../components/diagnosing/clinicRoom/store/reducer';
import prescriptionReducer from '../components/diagnosing/prescription/store/reducer';
import videoReducer from '../components/diagnosing/video/store/reducer';
import beforeReducer from '../components/beforeDiagnose/store/reducer';
import deliveriesReducer from '../components/deliveries/store/reducer';
import noticeReducer from '../components/notice/store/reducer';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

const store = configureStore({
  reducer: {
    chat: chatReducer,
    login: loginReducer,
    clinic: clinicReducer,
    history: historyReducer,
    prescription: prescriptionReducer,
    video: videoReducer,
    before: beforeReducer,
    deliveries: deliveriesReducer,
    notice: noticeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;