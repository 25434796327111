import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStatefulChatClient } from "@azure/communication-react";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import ClinicRoom from "./diagnosing/clinicRoom/ClinicRoom";
import ChatWindow from "./diagnosing/chatRoom/ChatWindow";
import PrescriptionModal from "./diagnosing/prescription/PrescriptionModal";
import { updateEndDiagnosisModal } from './diagnosing/prescription/store/action';
import { setApiErrorCode } from './diagnosing/chatList/store/action';
import { ENDPOINT } from '../config';
import { sendSentryLog } from '../utils/SentryFuc';

function DiagnosisModal() {
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const personalAcsId = useSelector<any, any>((state) => state.chat.personalAcsId);
  const personalAcsToken = useSelector<any, any>((state) => state.chat.personalAcsToken);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const showVideoRelatedModalIndex = useSelector<any, any>((state) => state.clinic.showVideoRelatedModalIndex);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const showEndDiagnosisModal = useSelector<any, any>((state) => state.prescription.showEndDiagnosisModal);

  const dispatch = useDispatch() as any;

  const chatThreadId = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.chatInfo.chatThreadId as any
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //「処方・診療終了」ダイアログをオープン
  function openEndDiagnosisModal() {
    //sentry log
    sendSentryLog('処方&診療終了ダイアログを開く', 'openEndDiagnosisDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateEndDiagnosisModal(true));
  }

  //statefulChatClientを作成する
  const statefulChatClient = useMemo(() => {
    try {
      return createStatefulChatClient({
        userId: { communicationUserId: personalAcsId },
        displayName: msalName,
        endpoint: ENDPOINT,
        credential: new AzureCommunicationTokenCredential(personalAcsToken),
      })
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsId, msalName, personalAcsToken]);

  //chatThreadIdによってchatThreadClientを作成する
  const chatThreadClient = useMemo(() => {
    try {
      if (statefulChatClient) {
        return statefulChatClient.getChatThreadClient(
          chatThreadId
        )
      }
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statefulChatClient, selectedDiagnosisCode]);

  statefulChatClient?.startRealtimeNotifications();

  return (
    <>
      <ClinicRoom chatThreadClient={chatThreadClient} />
      <div className="column medicine">
        <div id="chat_area" className="chat_area">
          <ChatWindow statefulChatClient={statefulChatClient} chatThreadClient={chatThreadClient} />
        </div>
        <div className="btn_end">
          <a
            href="#!"
            id="prescription_end_btn"
            className={isManager || showVideoRelatedModalIndex === 3 ? 'disabled' : ''}
            onClick={(e) => {
              e.preventDefault();
              openEndDiagnosisModal();
            }}
          >
            処方<br />
            ・<br />
            診療<br />
            終了
          </a>
        </div>
        {showEndDiagnosisModal ? <PrescriptionModal /> : ''}
      </div>
    </>
  )
}

export default DiagnosisModal;