import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Sidebar from 'react-sidebar';
import { Icon } from '@iconify/react';
import '../style/headerNav.scss';
import MsalAuth from '../utils/MsalAuth';
import { getBeforeDiagnoses, updateBeforeLoading } from './beforeDiagnose/store/action';
import { setSelectedDiagnosisCode } from '../components/diagnosing/chatList/store/action';
import { setAllowClick } from '../components/login/store/action';
import { getCompletedDiagnoses, updateCompletedLoading } from "./history/store/action";
import { RELEASE_VERSION } from "../config/constant";

function HeaderNav() {
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const isDeliver = useSelector<any, any>((state) => state.login.isDeliver);
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const msalMail = useSelector<any, any>((state) => state.login.msalMail);
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isAllowClick = useSelector<any, any>((state) => state.login.isAllowClick);
  const unreadPatientNumber = useSelector<any, any>((state) => state.chat.unreadPatientNumber);
  const diagnosisLists = useSelector<any, any>((state) => state.chat.diagnosisLists);
  const fetchingDiagnosesFlagA = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagA);
  const fetchingDiagnosesFlagB = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagB);
  const showVideoRelatedModalIndex = useSelector<any, any>((state) => state.clinic.showVideoRelatedModalIndex);

  //ログアウトドロップダウンボックスを表示するかどうかフラグ
  const [showLogoutDrop, setShowLogoutDrop] = useState(false);
  //サイドバーを開くかどうかフラグ
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const clickRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const msal = MsalAuth();

  //サイドバーのスタイル
  const sideBarStyles =
  {
    sidebar: {
      zIndex: "32",
      background: "white",
      width: "250px",
      position: "fixed",
    },
    overlay: {
      zIndex: "31",
    }
  };

  const handleClick = useCallback((e: any) => {
    if (!clickRef.current?.contains(e.target)) {
      setShowLogoutDrop(false);
    }
  }, []);

  function isBeforeDiagnoses() {
    if (window.location.href.indexOf('/beforeDiagnoses') !== -1) return true
    return false
  }

  function isDiagnosis() {
    if (window.location.href.indexOf('/diagnosis') !== -1) return true
    return false
  }

  function isHistory() {
    if (window.location.href.indexOf('/history') !== -1
      && window.location.href.indexOf('diagnosisCode') === -1) return true
    return false
  }

  function isDeliveries() {
    if (window.location.href.indexOf('/deliveries') !== -1) return true
    return false
  }

  function isNotice() {
    if (window.location.href.indexOf('/notice') !== -1) return true
    return false
  }

  //サイドバーのクリック事件
  function onSetSidebarOpen(open: boolean) {
    setSidebarOpen(open);
  }

  //サイドバーのコンテンツを定義する
  function getSidebarContent() {
    return (
      <div id="sidebar_content" className="sidebarContent">
        <img
          src="images/icon_menu_arrow.png"
          onClick={() => onSetSidebarOpen(false)}
          alt="icon_menu_arrow.png"
          className="sidebarArrow"
        ></img>
        <a
          id="privacy_policy"
          href="https://www.mti.co.jp/?page_id=17"
          className="sidebarLink"
          target="_blank"
          rel="noreferrer"
        >
          個人情報保護方針
        </a>
        <Link
          id="site_policy"
          to="/sitePolicy"
          className="sidebarLink"
          style={{ marginTop: '30px' }}
          onClick={() => onSetSidebarOpen(false)}
        >
          サイトポリシー
        </Link>
        <div id='menu_version' className="menu-version">version {RELEASE_VERSION}</div>
      </div>
    )
  }

  //「診療前一覧」ボタンのクリック事件
  async function loadBeforeDiagnoses() {
    if (isBeforeDiagnoses() && !window.location.href.endsWith('/beforeDiagnoses')) {
      dispatch(updateBeforeLoading(true));
      dispatch(getBeforeDiagnoses(msalToken, 1, 20, navigate));
    }
    dispatch(setSelectedDiagnosisCode(''));
    if (!diagnosisLists[0]?.doctorAcsId && !(fetchingDiagnosesFlagA || fetchingDiagnosesFlagB)) {
      dispatch(setAllowClick(false));
    }
  }

  //「診療中一覧」ボタンのクリック事件
  function loadDiagnosising() {
    if (!diagnosisLists[0]?.doctorAcsId && !fetchingDiagnosesFlagA) {
      dispatch(setAllowClick(false));
    }
  }

  //「診療終了一覧」ボタンのクリック事件
  async function loadHistoryDiagnoses() {
    if (isHistory() && !window.location.href.endsWith('/history')) {
      dispatch(updateCompletedLoading(true));
      dispatch(getCompletedDiagnoses(msalToken, 1, navigate));
    }
    dispatch(setSelectedDiagnosisCode(''));
    if (!diagnosisLists[0]?.doctorAcsId && !(fetchingDiagnosesFlagA || fetchingDiagnosesFlagB)) {
      dispatch(setAllowClick(false));
    }
  }

  //右上の氏名をクリックする時の処理
  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sidebar
      sidebar={getSidebarContent()}
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      styles={sideBarStyles}
    >
      <div id="header_bar" className="navbar header has-shadow">
        <div className="navbar-brand">
          <div
            id="navbar_burger"
            className="navbar-burger"
            style={showVideoRelatedModalIndex === 3 ? { pointerEvents: 'none' } : {}}
            onClick={() => onSetSidebarOpen(true)}>
            <span />
            <span />
            <span />
          </div>
          <div id="header_title" className="header-title has-text-primary">ルナルナ おくすり便</div>
          <div className="navbar-glow">
            <div className="tabs is-toggle">
              <ul>
                <li id="header_before" className={isBeforeDiagnoses() ? 'is-active' : ''}>
                  <Link
                    to='/beforeDiagnoses'
                    className={(showVideoRelatedModalIndex === 3) || !(isManager || isDoctor) || !isAllowClick ? 'is-disabled' : ''}
                    onClick={() => { loadBeforeDiagnoses() }}
                  >
                    <span>診療前一覧</span>
                  </Link>
                </li>
                <li id="header_diagnosis" className={isDiagnosis() ? 'is-active' : ''}>
                  <Link
                    to='/diagnosis'
                    className={(showVideoRelatedModalIndex === 3) || !(isManager || isDoctor) || !isAllowClick ? 'is-disabled' : ''}
                    onClick={() => { loadDiagnosising() }}
                  >
                    <span>診療中一覧</span>
                    {unreadPatientNumber > 0 ?
                      <span id="tabUreadIcon" className="icon is-right is-clickable">
                        <Icon icon="mdi:circle" style={{ fontSize: '8px', color: '#51B6EB' }} />
                      </span>
                      : ''}
                  </Link>
                </li>
                <li id="header_history" className={isHistory() ? 'is-active' : ''}>
                  <Link
                    to='/history'
                    className={(showVideoRelatedModalIndex === 3) || !(isManager || isDoctor) || !isAllowClick ? 'is-disabled' : ''}
                    onClick={() => { loadHistoryDiagnoses() }}
                  >
                    <span>診療終了一覧</span>
                  </Link>
                </li>
                <li id="header_deliveries" className={isDeliveries() ? 'is-active' : ''}>
                  <Link
                    to='/deliveries'
                    className={(!isManager && !isDeliver) ? 'is-disabled' : ''}
                  >
                    <span>配送管理</span>
                  </Link>
                </li>
                <li id="header_notice" className={isNotice() ? 'is-active' : ''}>
                  <Link
                    to='/notice'
                    className={!isManager ? 'is-disabled' : ''}
                  >
                    <span>お知らせ</span>
                  </Link>
                </li>
              </ul>
              <div
                id="header_msal"
                className="nav-login-cursor"
                style={showVideoRelatedModalIndex === 3 ? { pointerEvents: 'none' } : {}}
                ref={clickRef}
                onClick={() => setShowLogoutDrop(!showLogoutDrop)}>
                <div className={showLogoutDrop ? 'nav-login-border-onclick' : 'nav-login-border'}>
                  <span className="header-msal-span">
                    <div id="header_msal_name" className="has-text-weight-bold has-text-left">
                      {msalName}
                    </div>
                    <div id="header_msal_mail">{msalMail}</div>
                  </span>
                  <span className="chevron-down">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                </div>
                <div id="header_dropdown" className={showLogoutDrop ? 'dropdown-menu-show' : 'dropdown-menu'}>
                  <div className="dropdown-content">
                    <div id="header_dropdown_item" className="dropdown-item" onClick={() => { msal.logout() }}>ログアウト</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  )
}

export default HeaderNav;