function SitePolicy() {
  return (
    <div className="content" style={{ paddingTop: '60px', position: 'absolute' }}>
      <header>
        <div className="division">
          <h3 className="site-policy-title">
            『ルナルナ おくすり便（管理ツール）』サイトポリシー
          </h3>
          <p className="section-note has-margin-top-30">
            本サイト・プライバシーポリシー（以下、『本サイトポリシー』という）は、株式会社エムティーアイ（以下、『当社』という）がスマートフォン等に向けて提供する『ルナルナ
            おくすり便（管理ツール）』サービス（以下、『本サービス』という）から送信される利用者情報とその取り扱いについて説明するものです。
          </p>
          <p className="subsection">
            本サービスのご利用に際しましては、本サイトポリシーの内容をあらかじめご確認いただき、ご了承のうえでご利用ください。（本サイトポリシーは、本サービスのご利用開始後も本サービス内のメニューから随時参照いただけます。）
          </p>
        </div>
      </header>
      <section>
        <div className="division">
          <p className="section has-margin-top-30 has-margin-bottom-15">
            1. 本サービス本体から送信される利用者情報、目的、送信先等について
          </p>
          <p className="subsection">【送信される情報】</p>
          <p className="subsection-note">
            ・氏名、経歴、医師免許証の写し、オンライン診療研修終了証の写しをサービス機能利用（診察時の患者への医師情報の開示、およびアプリ内診療計画で診療担当医師の掲載）のために、株式会社エムティーアイへユーザーにより手動送信（参画時に提出）します。
          </p>
          <p className="subsection-note has-margin-bottom-15">
            ・担当医決定時間、処方薬剤等の管理ツールの操作ログをサービス機能利用,マーケティング,サービス改善（不具合発生時の調査やサービス改善のため、お医者様が操作された画面の操作履歴を収集・管理）のために、株式会社エムティーアイへ本サービスが自動送信します。
          </p>
          <p className="subsection">【第三者提供の有無】</p>
          <p className="subsection-note has-margin-bottom-15">
            当社に送信される情報は、当社以外の第三者に提供しておりません。
          </p>
          <p className="subsection">【情報送信の停止手段・影響など】</p>
          <p className="subsection-note">
            送信される情報を停止する手段はありません。やむを得ず送信停止をご希望される場合は、契約の解除のうえ本サービスのご利用を中止してください。
          </p>
          <p className="section has-margin-top-30 has-margin-bottom-15">
            2. 情報収集モジュールから送信される利用者情報、目的、送信先等について
          </p>
          <p className="section-note">
            本サービスでは情報収集モジュールによる情報の送信は行っておりません。
          </p>
          <p className="comment has-margin-bottom-15">
            ※情報収集モジュールとは、サービスの利用状況等の統計データ収集やアプリ内での広告表示等を行うために、サービス本体の機能とは独立した、第三者が作成したプログラムのことです。
          </p>
          <p className="section has-margin-top-30 has-margin-bottom-15">
            3. 個人情報の収集および取り扱いについて
          </p>
          <p className="section-note">
            本サービスおよび本サービスに付随するサービスにて収集するお客様の情報に個人情報が含まれる場合、実際に収集を行う場面（個人情報の入力・送信を行う画面等）で利用目的、第三者提供の有無、その他法令に定められた事項を都度提示し、お客様の明示的な同意をあらかじめ取得したうえで収集するものとします。
          </p>
          <p className="comment">
            ※個人情報とは、個人に関する情報であって、それら情報に含まれる氏名、生年月日、その他の記述によって特定の個人が識別できる情報を指します。（他の情報と容易に照合することができ、それによって特定の個人を識別することが可能となる情報も含みます。）
          </p>
          <p className="comment">
            ※当社が個人情報の収集を行う場合の当社全体の保護方針および汎用的な取り扱い基準については、以下の文書をご参照ください。
          </p>
          <p className="section-note">
            [個人情報保護方針]:&nbsp;
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.mti.co.jp/?page_id=17" target="_blank">
              https://www.mti.co.jp/?page_id=17
            </a>
          </p>
          <p className="section has-margin-top-30 has-margin-bottom-15">
            4. 利用者情報の取り扱いに関するお問い合わせ先
          </p>
          <p className="section-note">
            [株式会社エムティーアイ 苦情・相談窓口]:<a
              href="mailto:privacy@cc.mti.co.jp"
              className="has-pink-link"
            >privacy@cc.mti.co.jp</a
            >（メール）
          </p>
          <p className="section has-margin-top-30 has-margin-bottom-15">
            5. 本サイトポリシーの変更について
          </p>
          <p className="section-note">
            本サービスのバージョンアップに伴い、送信される利用者情報の項目、目的、送信先等が変更される場合があり、変更が生じた際には本サイトポリシーを改定します。変更内容等については、本サービスの新バージョンに付随する最新の本サイトポリシーをご参照ください。
          </p>
          <p className="subsection has-padding-bottom-30">
            なお、変更内容によっては、必要に応じてお客様の同意確認を改めて取らせていただく場合があります。
          </p>
        </div>
      </section>
    </div>
  )
}

export default SitePolicy;