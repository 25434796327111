import { useSelector } from "react-redux";
import { dateFormat } from '../../../utils/TimeFormat';

function PersonalInfo() {
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const formatPersonalZipCodeJp = () => {
    // 郵便番号はハイフンなしで登録されるためハイフンを付与する
    const zipCode = personalInfoDetail.personalZipCodeJp
    if (!zipCode) {
      return null
    } else if (zipCode.length >= 3 && !zipCode.includes('-')) {
      return '〒' + zipCode.slice(0, 3) + '-' + zipCode.slice(3)
    } else {
      return '〒' + zipCode
    }
  };

  return (
    <div id="tab-personal" className="tab-content" style={{ display: 'block' }}>
      <table id="table_border" className="table is-bordered is-fullwidth">
        <tbody>
          <tr>
            <th id="clinic_birth" className="has-text-right">生年月日</th>
            <td id="clinic_birth_content">{dateFormat(personalInfoDetail.personalBirthDay)}</td>
          </tr>
          <tr>
            <th id="clinic_age" className="has-text-right">年齢</th>
            <td id="clinic_age_content">{personalInfoDetail.personalAge}歳</td>
          </tr>
          <tr>
            <th id="clinic_address" className="has-text-right">住所</th>
            <td id="clinic_address_content"> {formatPersonalZipCodeJp()}<br />{personalInfoDetail.personalAddress} </td>
          </tr>
          <tr>
            <th id="clinic_email" className="has-text-right">メールアドレス</th>
            <td id="clinic_email_content">{personalInfoDetail.personalEmail}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PersonalInfo; 