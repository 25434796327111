import { useDispatch, useSelector } from "react-redux";
import { updateCommentModal } from "./store/action";

function CommentModal() {
  const selectedComment = useSelector<any, any>((state) => state.clinic.selectedComment);
  const dispatch = useDispatch() as any;

  function closeVideoScheduleModal() {
    dispatch(updateCommentModal(false));
  }

  return (
    <div id="history_comment_dialog" className="dialog">
      <div className="wrap">
        <div id="close_history_comment_dialog" className="close" onClick={closeVideoScheduleModal}></div>
        <div className="inner">
          <p id="history_comment_dialog_title" className="read">診療コメント</p>
          <div
            id="history_comment_dialog_text"
            className="
              has-margin-left-right-2
              has-margin-top-1
              has-margin-bottom-1
              has-padding-1
              continue-comment-box
            "
          >
            {selectedComment}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentModal;