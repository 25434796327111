import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { ColumnsType, TableProps } from "antd/es/table";
import { Table } from "antd";
import { getNoticeDetailByNo } from './store/action';

function PublishNoticeFrame(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch() as any;

  const selectedPublishRowKeys = props.selectedRowKeys as [];

  //お知らせ開始終了日時をフォマードする
  function dateTimeFormat(value: any) {
    if (value !== '') {
      const dateTime = new Date(value)

      const y = dateTime.getFullYear()
      const m = (dateTime.getMonth() + 1 + '').padStart(2, '0')
      const d = (dateTime.getDate() + '').padStart(2, '0')

      const hh = (dateTime.getHours() + '').padStart(2, '0')
      const mm = (dateTime.getMinutes() + '').padStart(2, '0')
      const ss = (dateTime.getSeconds() + '').padStart(2, '0')

      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    } else {
      return ''
    }
  }

  let data = [...props.publishNotices];
  for (let item of data) {
    item.key = item.noticeID;
  }

  //テーブルフッター
  const defaultFooter = () => {
    let footer = props.publishNotices.length + " 件中 " +
      (props.publishNotices.length > 0 ? 1 : 0) + " から " +
      props.publishNotices.length + " まで表示";
    return footer;
  }

  //テーブルカラム
  const columns: ColumnsType<any> = [
    {
      title: "管理No.",
      dataIndex: "noticeID",
      sorter: (a, b) => a.noticeID - b.noticeID,
      defaultSortOrder: 'descend',
    },
    {
      title: "掲載順",
      dataIndex: "noticeDisplayOrder",
      sorter: (a, b) => a.noticeDisplayOrder - b.noticeDisplayOrder
    },
    {
      title: "お知らせタイプ",
      dataIndex: "noticeTypeText",
      sorter: (a, b) => a.noticeTypeText.localeCompare(b.noticeTypeText),
    },
    {
      title: "タイトル",
      dataIndex: "noticeTitle",
      sorter: (a, b) => a.noticeTitle.localeCompare(b.noticeTitle),
      render: (text, row) =>
        <Link
          className="notice-title-link"
          to={"/notice?noticeNo=" + row["noticeNo"]}
          onClick={() => { dispatch(getNoticeDetailByNo(msalToken, row["noticeNo"])) }}

        >
          {row["noticeTitle"]}
        </Link >
    },
    {
      title: "掲載ステータス",
      dataIndex: "noticeDisplayStatusText",
      sorter: (a, b) => a.noticeDisplayStatusText.localeCompare(b.noticeDisplayStatusText)
    },
    {
      title: "開始終了日時",
      dataIndex: "noticeDisplayStartTime",
      sorter: (a, b) => {
        const aTime = new Date(a.noticeDisplayStartTime).getTime();
        const bTime = new Date(b.noticeDisplayStartTime).getTime();
        return aTime - bTime;
      },
      render: (text, row) =>
        <>
          {text === null ? ''
            : dateTimeFormat(new Date(text).toLocaleString())
          }
          <br />
          {row["noticeDisplayEndTime"] === null ? ''
            : dateTimeFormat(new Date(row["noticeDisplayEndTime"]).toLocaleString())
          }
        </>
    },
    {
      title: "有効／無効",
      dataIndex: "isDeletedText",
      sorter: (a, b) => a.isDeletedText.localeCompare(b.isDeletedText)
    }
  ];

  const tableProps: TableProps<any> = {
    bordered: false,
    loading,
    size: "small",
    footer: defaultFooter,
    locale: { emptyText: () => <div style={{ color: '#000031' }}>データはありません。</div> },
  };

  //行選択のプロパティ
  const rowSelection = {
    selectedRowKeys: selectedPublishRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const arr = [] as any;
      props.setSelectedRowKeys(selectedRowKeys);
      selectedRows.forEach((row: any) => {
        arr.push(row.noticeNo)
      })
      props.setCheckedRowsNoticeNo(arr);
    },
  };

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <div className='search-content'>
      <Table
        {...tableProps}
        pagination={false}
        columns={columns}
        dataSource={data}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        rowClassName={
          (record, index) => {
            let className = ''
            className = index % 2 === 0 ? '' : 'odd'
            return className
          }
        }
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </div>
  )
}

export default PublishNoticeFrame;