import { get, post } from '../../../../api/FetchAPI';
import { sortByMessageDate } from '../../../../utils/SortList';
import { DIAGNOSIS_INFO, REFRESH_ACS_TOKEN, ACS_CHAT_INFO, DIAGNOSIS_INFO_BY_THEAD_ID, DOCTOR_MASTER } from '../../../../api/Api';
import { setHistoryClinicRoomFlag } from '../../../history/store/action';
import { ERR_CODE_A014, ERR_CODE_A001, SENTRT_CONTEXT_ID } from '../../../../config/constant';
import { sendSentryLog, sentryContext } from '../../../../utils/SentryFuc';

// 以下、内部用reduxのデータ処理
const setDoctors = (doctors: any) => ({
  type: 'set_doctors',
  doctors
})

const updatePatientNumberOfManager = (length: number) => ({
  type: 'set_patient_number_of_manager',
  length
})

const updateDiagnosesOfManager = (list: any, index: number, doctorAcsId: string) => ({
  type: 'update_diagnoses_of_manager',
  list,
  index,
  doctorAcsId
})

//医師：初期化診療リストとディスプレー用患者リストはreducerに保存
const putDiagnosisListsIntoReducerOfDoctor = async (doctorACSId: any, patientInfoLists: any, dispatch: any) => {
  let diagnosisLists = [];
  patientInfoLists = sortByMessageDate(patientInfoLists);
  diagnosisLists.push({ 'doctorAcsId': doctorACSId, 'patientInfoLists': patientInfoLists })
  //初期化診療リストはreducerに保存
  await dispatch(setDiagnosisList(diagnosisLists));
  //ディスプレー用患者リストはreducerに保存
  let displayPatientInfoList = JSON.parse(JSON.stringify(diagnosisLists[0].patientInfoLists));
  await dispatch(setDisplayPatientsLists(displayPatientInfoList));
}

//医師：スレッドIDに基づいてACSへアクセスに失敗した診療情報がある場合、３回リトライして取得
const getAcsAcessFailedDiagnosisOfDoctor = (auth: string, patientInfo: any, dispatch: any) => {
  //sentry log
  sendSentryLog('患者が初回返信して診療中一覧に移動する(医師)', 'addNewPatientOfDoctor', 'log',
    { 'event_name': 'add_new_patient_doctor', 'patientUid': patientInfo.personalUid, 'diagnosisCode': patientInfo.diagnosisCode }
  );
  //アクセスACSが失敗した診療コードを取得
  const acsAccessFailedDiagnosisCodeList = getAcsAccessFailedDiagnosisCodeList(patientInfo);
  if (acsAccessFailedDiagnosisCodeList.length > 0) {
    //ACSへアクセスに失敗の診療コードが存在の場合は
    getAcsAccessFailedChatInfo(auth, patientInfo, acsAccessFailedDiagnosisCodeList, 3, dispatch).then((patientInfo) => {
      dispatch(updateDiagnoses(patientInfo));
    });
  } else {
    //ACSへアクセスに失敗の診療コードが存在しない場合は
    dispatch(updateUnreadPatientNumber(1));
    dispatch(updateDiagnoses(patientInfo));
  }
}

//共通：診療コードリストによってACSチャット情報取得
const getAcsAccessFailedChatInfo = async (auth: string, patientInfoLists: any, diagnosisCodeList: any, retry: number, dispatch: any) => {
  let acsAccessFailedDiagnosisCodeList = diagnosisCodeList;
  let addUnreadNumber = 0;
  let retryCount = 0;
  while (acsAccessFailedDiagnosisCodeList.length > 0 && retryCount < retry) {
    let data = { diagnosisCodeList: acsAccessFailedDiagnosisCodeList }
    const chatResponse = await post(ACS_CHAT_INFO, data, auth) as any;
    if (chatResponse.data.errorCode === null) {
      acsAccessFailedDiagnosisCodeList = [];
      if (Array.isArray(patientInfoLists)) {
        for (let chatInfo of chatResponse.data.chatInfos) {
          for (let patientInfo of patientInfoLists) {
            if (chatInfo.diagnosisCode === patientInfo.diagnosisCode) {
              if (!chatInfo.acsAccessFailed) {
                if (chatInfo.hasUnread) {
                  addUnreadNumber++;
                }
                patientInfo.chatInfo.hasUnread = chatInfo.hasUnread;
                patientInfo.chatInfo.lastChatSendDatetimeJst = chatInfo.lastChatSendDatetimeJst;
                patientInfo.chatInfo.lastPatientChatSendDatetimeJst= chatInfo.lastPatientChatSendDatetimeJst;
                patientInfo.chatInfo.lastMessage = chatInfo.lastMessage;
                patientInfo.chatInfo.acsAccessFailed = chatInfo.acsAccessFailed;
                //チャットリストに存在しない患者来信　未返信状態に設定
                patientInfo.chatInfo.hasNoReply= chatInfo.hasNoReply;
              } else {
                acsAccessFailedDiagnosisCodeList.push(chatInfo.diagnosisCode);
              }
            }
          }
        }
      }
      dispatch(updateUnreadPatientNumber(addUnreadNumber));
    } else {
      //sentry log
      const sentryContextApiError: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          api: ACS_CHAT_INFO,
          diagnosisCodeList: acsAccessFailedDiagnosisCodeList,
          errCode: chatResponse.data.errorCode
        }
      };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
    }
    retryCount++;
  }
  return patientInfoLists;
}

// 以下、外部用reduxのデータ処理
export const updateDiagnosisLoading = (flag: boolean) => ({
  type: 'set_diagnosis_loading',
  flag
})

const updateGetDiagnosesSuccess = (flag: boolean) => ({
  type: 'update_get_diagnoses_success',
  flag
})

export const setPersonalAcsId = (id: string) => ({
  type: 'set_personal_acsid',
  id
})

export const setPersonalAcsToken = (token: string) => ({
  type: 'set_personal_acstoken',
  token
})

export const setPersonalAcsTokenExpiredOn = (time: any) => ({
  type: 'set_personal_acstoken_expiredon',
  time
})

export const setDiagnosisList = (list: any) => ({
  type: 'set_diagnosis_list',
  list
})

export const setApiErrorCode = (errCode: any) => ({
  type: 'set_api_err_code',
  errCode
})

export const setApiErrorMessage = (errMsg: any) => ({
  type: 'set_api_err_msg',
  errMsg
})

export const setDiagnosisPatientInfoList = (list: any) => ({
  type: 'set_diagnosis_patient_info_list',
  list
})

export const setDiagnosisPatientInfoListEndDiagnosis = (selectedDiagnosisCode: string) => ({
  type: 'set_diagnosis_patient_info_list_end_diagnosis',
  selectedDiagnosisCode
})

export const setDisplayPatientsLists = (list: any) => ({
  type: 'set_display_patients_list',
  list
})

export const setDisplayPatientsListsByNoReply = () => ({
  type: 'set_display_patients_lists_by_no_reply'
})

export const setDisplayPatientsListsOfManager = (doctorAcsId: string, list: any) => ({
  type: 'set_display_patients_list_of_manager',
  doctorAcsId,
  list
})

export const setDisplayPatientsListsOfManagerAcs = () => ({
  type: 'set_display_patient_list_of_manager_acs',
})

export const setSelectedDiagnosisCode = (diagnosisCode: string) => ({
  type: 'set_diagnosis_code',
  diagnosisCode
})

export const setInitUnreadMark = (diagnosisCode: string, flag: boolean) => ({
  type: 'set_init_unread_mark',
  diagnosisCode,
  flag
})

export const setDisplayUnreadMark = (diagnosisCode: string, flag: boolean) => ({
  type: 'set_display_unread_mark',
  diagnosisCode,
  flag
})

export const updateSearchFlag = (flag: boolean) => ({
  type: 'update_search_flag',
  flag
})

export const updateSearchModal = (flag: boolean) => ({
  type: 'update_search_modal',
  flag
})

export const updateDiagnoses = (list: any) => ({
  type: 'update_diagnoses',
  list
})

export const updateVideoDateTime = (startVideoTime: any, endVideoTime: any) => ({
  type: 'update_video_date',
  startVideoTime,
  endVideoTime
})

export const updateSelectedDoctorAcsId = (doctorAcsId: string) => ({
  type: 'set_selected_doctor_acsid',
  doctorAcsId
})

export const updateVideoStatus = (flag: boolean) => ({
  type: 'update_video_status',
  flag
})

export const playSound = (senderUserId: string) => ({
  type: 'play_sound',
  senderUserId
})

export const updateAcsRandomNumber = (number: number) => ({
  type: 'update_acs_random_number',
  number
})

export const updateDiagnosesChatInfosOfDoctor = (chatInfos: any) => ({
  type: 'update_diagnoses_chat_infos_of_doctor',
  chatInfos
})

export const updateDiagnosesChatInfosOfManager = (chatInfos: any) => ({
  type: 'update_diagnoses_chat_infos_of_manager',
  chatInfos
})

export const updateFetchDiagnosesFlagA = (flag: boolean) => ({
  type: 'update_fetch_diagnoses_flag_a',
  flag
})

export const updateFetchDiagnosesFlagB = (flag: boolean) => ({
  type: 'update_fetch_diagnoses_flag_b',
  flag
})

export const updateUnreadPatientNumber = (number: number) => ({
  type: 'update_unread_patient_number',
  number
})

export const setTakeOnPatientNumber = (number: number) => ({
  type: 'set_take_on_patient_number',
  number
})

export const updateTakeOnPatientNumber = (number: number, isManager: boolean, doctorAcsId: string) => ({
  type: 'update_take_on_patient_number',
  number,
  isManager,
  doctorAcsId
})

export const setClinicLoading = (flag: boolean, diagnosisCode: string) => ({
  type: 'set_clinic_loading',
  flag,
  diagnosisCode
})

export const updateSearchRadioValue = (radio: string) => ({
  type: 'update_search_radio_value',
  radio
})

//医師マスタ取得
export const getDoctors = (auth: any) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: DOCTOR_MASTER
    }
  };
  return async (dispatch: any) => {
    try {
      const response = await get(DOCTOR_MASTER, null, auth) as any;
      if (response.data.errorCode === null) {
        await dispatch(setDoctors(response.data.doctors));
        await dispatch(updateSelectedDoctorAcsId(response.data.doctors[0].doctorPersonalACSId));
        return response.data.doctors;
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//共通：アクセスACSが失敗した診療コードを取得
export const getAcsAccessFailedDiagnosisCodeList = (patientInfoLists: any) => {
  let acsAccessFailedDiagnosisCodeList: string[] = [];
  if (Array.isArray(patientInfoLists)) {
    patientInfoLists.forEach((item: any) => {
      if (item.chatInfo.acsAccessFailed) {
        acsAccessFailedDiagnosisCodeList.push(item.diagnosisCode);
      }
    })
  } else {
    if (patientInfoLists.chatInfo.acsAccessFailed) {
      acsAccessFailedDiagnosisCodeList.push(patientInfoLists.diagnosisCode);
    }
  }
  return acsAccessFailedDiagnosisCodeList;
}

//共通：ACSトーケンと有効期限の更新
export const refreshAcsToken = async (auth: any, dispatch: any) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: REFRESH_ACS_TOKEN
    }
  };
  try {
    const acsTokenResponse = await get(REFRESH_ACS_TOKEN, null, auth) as any;
    if (acsTokenResponse.data.errorCode === null) {
      dispatch(setPersonalAcsTokenExpiredOn(acsTokenResponse.data.acsTokenExpiredDTJst));
      return acsTokenResponse.data.acsToken;
    } else {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': acsTokenResponse.data.errorCode } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      await dispatch(setApiErrorCode(acsTokenResponse.data.errorCode));
      return null;
    }
  } catch (e: any) {
    //sentry log
    const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
    sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
    await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    return null;
  }
}

//タイマの場合：診療コードリストによってACSチャット情報取得(医師)
export const getAcsAccessFailedChatInfosOfDoctorTimer = async (auth: string, diagnosisCodeList: any) => {
  let acsAccessFailedDiagnosisCodeList = diagnosisCodeList;
  let chatResponse = [];
  let data = { diagnosisCodeList: acsAccessFailedDiagnosisCodeList }
  let response = await post(ACS_CHAT_INFO, data, auth) as any;
  if (response.data.errorCode === null) {
    chatResponse = response.data.chatInfos;
  } else {
    //sentry log
    const sentryContextApiError: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        api: ACS_CHAT_INFO,
        diagnosisCodeList: acsAccessFailedDiagnosisCodeList,
        errCode: response.data.errorCode
      }
    };
    sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
  }

  return chatResponse;
}

//タイマの場合：診療コードリストによってACSチャット情報取得(管理者)
export const getAcsFailedChatInfosOfManagerTimer = async (auth: string, diagnosisCodeList: any) => {
  const data = { diagnosisCodeList: diagnosisCodeList };
  let chatResponse = [];
  const response = await post(ACS_CHAT_INFO, data, auth) as any;
  if (response.data.errorCode === null) {
    chatResponse = response.data.chatInfos;
  } else {
    //sentry log
    const sentryContextApiError: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        api: ACS_CHAT_INFO,
        diagnosisCodeList: diagnosisCodeList,
        errCode: response.data.errorCode
      }
    };
    sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
  }
  return chatResponse;
}

//医師：診療リストの取得
export const getDiagnosisListsOfDoctor = (auth: string) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: DIAGNOSIS_INFO,
      mode: '1'
    }
  };
  return async (dispatch: any) => {
    await dispatch(updateDiagnosisLoading(true));
    try {
      dispatch(setHistoryClinicRoomFlag(false));
      const data = {
        mode: 1
      };
      const response = await get(DIAGNOSIS_INFO, data, auth) as any;
      //自身（医者/管理者のACS Tokenの有効期限が切れている場合
      if (response.data.errorCode === ERR_CODE_A014) {
        //ACSトーケンと有効期限の更新
        const acsToken = await refreshAcsToken(auth, dispatch);
        if (acsToken === null) {
          return;
        }
        //診療リストの再取得
        const response = await get(DIAGNOSIS_INFO, data, auth) as any;
        if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
          //自身（医者/管理者）のACS IDはreducerに保存
          const personalAcsId = response.data.personalAcsId;
          await dispatch(setPersonalAcsId(personalAcsId));
          await dispatch(setPersonalAcsToken(acsToken));
          //ACSへアクセスに失敗の診療コードリスト
          let patientInfoLists = response.data.diagnoses;
          if (patientInfoLists !== null) {
            //医師が担当する患者数はreducerに保存
            dispatch(setTakeOnPatientNumber(patientInfoLists.length));
            putDiagnosisListsIntoReducerOfDoctor(patientInfoLists[0].doctorPersonalACSId, patientInfoLists, dispatch);
          }
        } else {
          //sentry log
          const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
          sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
          await dispatch(setApiErrorCode(response.data.errorCode));
        }
      } else if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        const personalAcsId = response.data.personalAcsId;
        const personalAcsToken = response.data.personalAcsToken;
        const personalAcsTokenExpiredOnJst = response.data.personalAcsTokenExpiredDTJst;
        await dispatch(setPersonalAcsId(personalAcsId));
        await dispatch(setPersonalAcsToken(personalAcsToken));
        await dispatch(setPersonalAcsTokenExpiredOn(personalAcsTokenExpiredOnJst));
        let patientInfoLists = response.data.diagnoses;
        if (patientInfoLists !== null) {
          //医師が担当する患者数はreducerに保存
          dispatch(setTakeOnPatientNumber(patientInfoLists.length));
          putDiagnosisListsIntoReducerOfDoctor(patientInfoLists[0].doctorPersonalACSId, patientInfoLists, dispatch);
        }
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
      await dispatch(updateGetDiagnosesSuccess(true));
      await dispatch(updateDiagnosisLoading(false));
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//医師：スレッドIDに基づいて診療中診療情報を取得
export const getDiagnosisByThreadIdOfDoctor = (auth: string, threadId: string, patientAcsId: string, personalAcsId: string) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: DIAGNOSIS_INFO_BY_THEAD_ID,
      threadId: threadId,
      patientAcsId: patientAcsId,
      doctorAcsId: personalAcsId
    }
  };
  return async (dispatch: any) => {
    try {
      const data = {
        threadId: threadId,
        patientAcsId: patientAcsId,
        doctorAcsId: personalAcsId
      }
      let diagnosisByThreadIdFlag = false;
      const response = await post(DIAGNOSIS_INFO_BY_THEAD_ID, data, auth) as any;
      //自身（医者/管理者のACS Tokenの有効期限が切れている場合
      if (response.data.errorCode === ERR_CODE_A014) {
        //ACSトーケンと有効期限の更新
        const acsToken = await refreshAcsToken(auth, dispatch);
        if (acsToken === null) {
          return;
        } else {
          dispatch(setPersonalAcsToken(acsToken));
        }
        //スレッドIDに基づいて診療中診療情報を取得
        const diagnosisInfoResponse = await post(DIAGNOSIS_INFO_BY_THEAD_ID, data, auth) as any;
        if (diagnosisInfoResponse.data.errorCode === null || diagnosisInfoResponse.data.errorCode === ERR_CODE_A001) {
          let patientInfo = diagnosisInfoResponse.data.diagnosis;
          if (patientInfo !== null) {
            //医師が担当する患者数
            dispatch(updateTakeOnPatientNumber(1, false, ''));
            getAcsAcessFailedDiagnosisOfDoctor(auth, patientInfo, dispatch);
            diagnosisByThreadIdFlag = true;
          }
        } else {
          //sentry log
          const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
          sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
          await dispatch(setApiErrorCode(diagnosisInfoResponse.data.errorCode));
        }
      } else if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        let patientInfo = response.data.diagnosis;
        if (patientInfo !== null) {
          //医師が担当する患者数
          dispatch(updateTakeOnPatientNumber(1, false, ''));
          getAcsAcessFailedDiagnosisOfDoctor(auth, patientInfo, dispatch);
          diagnosisByThreadIdFlag = true;
        }
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
      return diagnosisByThreadIdFlag;
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//管理者：診療リストの取得
export const getDiagnosisListsOfManager = (auth: string, doctors: any, selectedDoctorAcsId: string) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: DIAGNOSIS_INFO,
      mode: '1'
    }
  };
  return async (dispatch: any) => {
    dispatch(updateDiagnosisLoading(true));
    try {
      const data = {
        mode: 1
      };
      const response = await get(DIAGNOSIS_INFO, data, auth) as any;
      //自身（医者/管理者のACS Tokenの有効期限が切れている場合
      if (response.data.errorCode === ERR_CODE_A014) {
        //ACSトーケンと有効期限の更新
        const acsToken = await refreshAcsToken(auth, dispatch);
        if (acsToken === null) {
          return;
        }
        //診療リストの再取得
        const response = await get(DIAGNOSIS_INFO, data, auth) as any;
        if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
          //自身（医者/管理者）のACS IDはreducerに保存
          const personalAcsId = response.data.personalAcsId;
          await dispatch(setPersonalAcsId(personalAcsId));
          await dispatch(setPersonalAcsToken(acsToken));
          //ACSへアクセスに失敗の診療コードリスト
          let patientInfoLists = response.data.diagnoses;
          //診療情報一覧リストの初期化処理
          putDiagnosisListsIntoReducerOfManager(doctors, selectedDoctorAcsId, patientInfoLists, dispatch);
          if (patientInfoLists !== null) {
            dispatch(updatePatientNumberOfManager(patientInfoLists.length));
          }
        } else {
          //sentry log
          const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
          sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
          await dispatch(setApiErrorCode(response.data.errorCode));
        }
      } else if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        const personalAcsId = response.data.personalAcsId;
        const personalAcsToken = response.data.personalAcsToken;
        const personalAcsTokenExpiredOnJst = response.data.personalAcsTokenExpiredDTJst;
        await dispatch(setPersonalAcsId(personalAcsId));
        await dispatch(setPersonalAcsToken(personalAcsToken));
        await dispatch(setPersonalAcsTokenExpiredOn(personalAcsTokenExpiredOnJst));
        let patientInfoLists = response.data.diagnoses;
        //診療情報一覧リストの初期化処理
        putDiagnosisListsIntoReducerOfManager(doctors, selectedDoctorAcsId, patientInfoLists, dispatch);
        if (patientInfoLists !== null) {
          dispatch(updatePatientNumberOfManager(patientInfoLists.length));
        }
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
      await dispatch(updateGetDiagnosesSuccess(true));
      await dispatch(updateDiagnosisLoading(false));
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//管理者：スレッドIDに基づいて診療中診療情報を取得
export const getDiagnosisByThreadIdOfManager = (auth: string, threadId: string, patientAcsId: string, diagnosisLists: any, patientNumber: number) => {
  const sentryContextApi: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      api: DIAGNOSIS_INFO_BY_THEAD_ID,
      threadId: threadId,
      patientAcsId: patientAcsId,
    }
  };
  return async (dispatch: any) => {
    try {
      const data = {
        threadId: threadId,
        patientAcsId: patientAcsId
      };
      let doctorAcsIdResult = "";
      const response = await post(DIAGNOSIS_INFO_BY_THEAD_ID, data, auth) as any;
      //自身（医者/管理者のACS Tokenの有効期限が切れている場合
      if (response.data.errorCode === ERR_CODE_A014) {
        //ACSトーケンと有効期限の更新
        const acsToken = await refreshAcsToken(auth, dispatch);
        if (acsToken === null) {
          return;
        } else {
          dispatch(setPersonalAcsToken(acsToken));
        }
        //スレッドIDに基づいて診療中診療情報を取得
        const diagnosisInfoResponse = await post(DIAGNOSIS_INFO_BY_THEAD_ID, data, auth) as any;
        if (diagnosisInfoResponse.data.errorCode === null || diagnosisInfoResponse.data.errorCode === ERR_CODE_A001) {
          let patientInfo = diagnosisInfoResponse.data.diagnosis;
          doctorAcsIdResult = patientInfo.doctorPersonalACSId;
          if (patientInfo !== null) {
            //医師が担当する患者数
            dispatch(updateTakeOnPatientNumber(1, true, doctorAcsIdResult));
            //患者からメッセージを送信した場合は処理
            let doctorIndex = -1;
            diagnosisLists.forEach((diagnosis: any, index: number) => {
              if (diagnosis.doctorAcsId === doctorAcsIdResult) {
                doctorIndex = index;
              }
            });
            if (doctorIndex >= 0) {
              await getAcsAcessFailedDiagnosisOfManager(auth, patientInfo, doctorIndex, doctorAcsIdResult, dispatch);
              dispatch(updatePatientNumberOfManager(patientNumber + 1));
            }
          }
        } else {
          //sentry log
          const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
          sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
          await dispatch(setApiErrorCode(diagnosisInfoResponse.data.errorCode));
        }
      } else if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        if (response.data.diagnosis !== null) {
          const patientInfo = response.data.diagnosis;
          doctorAcsIdResult = patientInfo.doctorPersonalACSId;
          //医師が担当する患者数
          dispatch(updateTakeOnPatientNumber(1, true, doctorAcsIdResult));
          //患者からメッセージを送信した場合は処理
          let doctorIndex = -1;
          diagnosisLists.forEach((diagnosis: any, index: number) => {
            if (diagnosis.doctorAcsId === doctorAcsIdResult) {
              doctorIndex = index;
            }
          });
          if (doctorIndex >= 0) {
            await getAcsAcessFailedDiagnosisOfManager(auth, patientInfo, doctorIndex, doctorAcsIdResult, dispatch);
            dispatch(updatePatientNumberOfManager(patientNumber + 1));
          }
        }
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error', { 'event_name': 'api_error' }, [sentryContextApiError]);
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//管理者：スレッドIDに基づいてACSへアクセスに失敗した診療情報を取得
const getAcsAcessFailedDiagnosisOfManager = async (auth: string, patientInfo: any, index: number, doctorACSId: string, dispatch: any) => {
  //sentry log
  sendSentryLog('患者が初回返信して診療中一覧に移動する(管理監督者)', 'addNewPatientOfManager', 'log',
    { 'event_name': 'add_new_patient_manager', 'patientUid': patientInfo.personalUid, 'diagnosisCode': patientInfo.diagnosisCode, 'doctorUid': patientInfo.doctorPersonalUid }
  );
  //アクセスACSが失敗した診療コードを取得
  const acsAccessFailedDiagnosisCodeList = getAcsAccessFailedDiagnosisCodeList(patientInfo);
  if (acsAccessFailedDiagnosisCodeList.length > 0) {
    //ACSへアクセスに失敗の診療コードが存在の場合は
    await getAcsAccessFailedChatInfo(auth, patientInfo, acsAccessFailedDiagnosisCodeList, 3, dispatch).then((patientInfo) => {
      dispatch(updateDiagnosesOfManager(patientInfo, index, doctorACSId));
    });
  } else {
    //ACSへアクセスに失敗の診療コードが存在しない場合は
    dispatch(updateUnreadPatientNumber(1));
    await dispatch(updateDiagnosesOfManager(patientInfo, index, doctorACSId));
  }
}

//管理者：初期化診療リストとディスプレー用患者リストはreducerに保存
const putDiagnosisListsIntoReducerOfManager = async (doctors: any, selectedDoctorAcsId: string, patientInfoLists: any, dispatch: any) => {
  let diagnosisLists = [];
  let filterPatients = [];
  for (const doctor of doctors) {
    if (patientInfoLists !== null) {
      filterPatients = patientInfoLists.filter((item: any) => { return doctor.doctorPersonalACSId === item.doctorPersonalACSId });
      filterPatients = sortByMessageDate(filterPatients);
      diagnosisLists.push({ 'doctorAcsId': doctor.doctorPersonalACSId, 'patientInfoLists': filterPatients });
    } else {
      diagnosisLists.push({ 'doctorAcsId': doctor.doctorPersonalACSId, 'patientInfoLists': [] });
    }
  }
  //選択した医師が担当する患者数はreducerに保存
  const patientNumberOfSelectedDoctorLen = diagnosisLists.filter((item: any) => {
    return item.doctorAcsId === selectedDoctorAcsId
  })[0].patientInfoLists.length;
  dispatch(setTakeOnPatientNumber(patientNumberOfSelectedDoctorLen));
  //初期化診療リストはreducerに保存
  await dispatch(setDiagnosisList(diagnosisLists));
  //ディスプレー用患者リストはreducerに保存
  const patientInfoListsOfDoctor = diagnosisLists.filter((item: any) => {
    return item.doctorAcsId === selectedDoctorAcsId;
  })[0]?.patientInfoLists;
  let displayPatientInfoList = JSON.parse(JSON.stringify(patientInfoListsOfDoctor));
  await dispatch(setDisplayPatientsListsOfManager('', displayPatientInfoList));
}