import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDiagnosisCode, setInitUnreadMark, setDisplayUnreadMark, setClinicLoading, updateUnreadPatientNumber, setDisplayPatientsListsByNoReply } from './store/action';
import { videoTimeFormat, dateFormat, timeFormat, convertStringUtcToJst } from '../../../utils/TimeFormat';
import { getAllPersonalDetail, setClinicErrorCode, updateProposeMedicineProductName, updateProposePricePlan } from '../clinicRoom/store/action';
import { updateEndDiagnosisModal } from '../prescription/store/action';
import { sendSentryLog, sentryContext, setSentryContext } from '../../../utils/SentryFuc';

function ChatListItem(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const diagnosisLists = useSelector<any, any>((state) => state.chat.diagnosisLists);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const isSearching = useSelector<any, any>((state) => state.chat.isSearching);
  const showVideoRelatedModalIndex = useSelector<any, any>((state) => state.clinic.showVideoRelatedModalIndex);
  const searchRadioValue = useSelector<any, any>((state) => state.chat.searchRadioValue);

  const dispatch = useDispatch() as any;
  const firstUpdate = useRef(true);

  async function handleDiagnosisCode(diagnosisCode: string, personalUid: string) {
    if (selectedDiagnosisCode !== diagnosisCode) {
      //sentryのglobal context情報を設定する    
      const patientInfoContext: sentryContext = {
        contextID: 'Last Patient Info',
        info: {
          patientUid: personalUid,
          diagnosisCode: diagnosisCode,
        }
      }
      setSentryContext(patientInfoContext);
      //sentry log
      sendSentryLog('患者選択', 'selectPatient', 'log',
        { 'event_name': 'select_patient', 'patientUid': personalUid, 'diagnosisCode': diagnosisCode }
      );
      dispatch(setClinicErrorCode(null));
      //クリックした患者診療コードの設定
      dispatch(setSelectedDiagnosisCode(diagnosisCode));
      //「処方・診療終了」ダイアログをクロース
      dispatch(updateEndDiagnosisModal(false));
      //提案中薬剤をクリア
      dispatch(updateProposePricePlan(null));
      dispatch(updateProposeMedicineProductName(null));
      //患者情報、問診結果情報、診療履歴、ビデオ診療情報とACSビデオ情報の取得
      const patientUid = await dispatch(getAllPersonalDetail(msalToken, diagnosisCode, isDoctor));
      sendSentryLog('診療室を開く', 'openClinicRoom', 'log',
        { 'event_name': 'select_patient', 'patientUid': patientUid, 'diagnosisCode': diagnosisCode }
      );
      if (isDoctor) {
        const isSetInitUnreadMarkFlag = diagnosisLists[0].patientInfoLists.some((item: any) => item.diagnosisCode === diagnosisCode && item.chatInfo.hasUnread);
        if (isSetInitUnreadMarkFlag) {
          //医師の場合、初期化診療リストの未読マークを更新
          dispatch(setInitUnreadMark(diagnosisCode, false));
          dispatch(updateUnreadPatientNumber(-1));
        } else if (isSearching && searchRadioValue === "noReply") {
          //医師の場合かつ未返信絞込状態で、表示用診療リストを更新
          dispatch(setDisplayPatientsListsByNoReply());
        }
      }
      //絞込状態ではない、かつ医師の場合は表示用診療リストの未読マークを更新
      if (isDoctor && !isSearching) {
        const isSetDisplayUnreadMarkFlag = displayPatientsLists.some((item: any) => item.diagnosisCode === diagnosisCode && item.chatInfo.hasUnread);
        if (isSetDisplayUnreadMarkFlag) {
          dispatch(setDisplayUnreadMark(diagnosisCode, false));
        }
      }
      dispatch(setClinicLoading(false, diagnosisCode));
    }
  }

  //チャートアイテムのクラス設定
  function handleChatItemClass(diagnosisCode: string, unReadMark: boolean) {
    let chatItemClass = "";
    if (diagnosisCode === selectedDiagnosisCode) {
      if (isManager && unReadMark) {
        chatItemClass = "name_box hold on";
      } else {
        chatItemClass = "name_box hold";
      }
    } else if (diagnosisCode !== selectedDiagnosisCode && unReadMark) {
      chatItemClass = "name_box on";
    } else {
      chatItemClass = "name_box";
    }
    return chatItemClass;
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      dispatch(setClinicLoading(true, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagnosisCode]);

  return (
    <div
      className={handleChatItemClass(props.item.diagnosisCode, props.item.chatInfo.hasUnread)}
      style={showVideoRelatedModalIndex === 3 ? { pointerEvents: 'none' } : {}}
      onClick={() => handleDiagnosisCode(props.item.diagnosisCode, props.item.personalUid)}
    >
      <div className="top">
        <div className="left">
          <div className="name">{props.item.personalFamilyName} {props.item.personalFirstName}</div>
          <div className="ruby">{props.item.personalFamilyNameFurigana} {props.item.personalFirstNameFurigana}</div>
        </div>
        <div className="right" style={{ minWidth: '5.83em' }}>
          <div className="date">
            {
              props.item.chatInfo.lastChatSendDatetimeJst ?
                <>
                  {dateFormat(convertStringUtcToJst(props.item.chatInfo.lastChatSendDatetimeJst))}
                  <br />
                  {timeFormat(convertStringUtcToJst(props.item.chatInfo.lastChatSendDatetimeJst))}
                </>
                : '未取得'
            }
          </div>
        </div>
      </div>
      {props.item.diagnosisScheduledStartDatetimeJst === null || props.item.diagnosisScheduledStartDatetimeJst === '' ?
        <div className="time">ビデオ日時: 未実施</div>
        : <div className="time">ビデオ日時: <br />{videoTimeFormat(props.item.diagnosisScheduledStartDatetimeJst, props.item.diagnosisScheduledEndDatetimeJst)}</div>
      }
      <div className="last_message">{
        props.item.chatInfo.lastMessage === undefined || props.item.chatInfo.lastMessage === null ?
          '未取得' : props.item.chatInfo.lastMessage
      }</div>
    </div>
  )
}
export default ChatListItem;