import { DOWNLOAD_POSSIBILITY } from "../../../api/Api";
import { get } from '../../../api/FetchAPI';
import { setApiErrorCode } from '../../diagnosing/chatList/store/action';

// 以下、内部用reduxのデータ処理
const setDownloadPossibility = (value: any) => ({
  type: 'set_download_possibility',
  value
})

const updateDeliveryLoading = (flag: boolean) => ({
  type: 'update_delivery_loading',
  flag
})

// 以下、外部用reduxのデータ処理
export const updateShippingMethod = (value: number) => ({
  type: 'update_shipping_method',
  value
})

export const updatDownloadLoading = (flag: boolean) => ({
  type: 'update_download_loading',
  flag
})

export const updateDownloadErrorCode = (code: any) => ({
  type: 'update_download_error_code',
  code
})

export const updateDownloadErrorMsg = (msg: string) => ({
  type: 'update_download_error_msg',
  msg
})

export const updateDeliveryAuthLoading = (flag: boolean) => ({
  type: 'update_delivery_auth_loading',
  flag
})

//プランごとにダウンロード可能性を取得する
export const getDownloadPossibility = (auth: string) => {
  return async (dispatch: any) => {
    try {
      const response = await get(DOWNLOAD_POSSIBILITY, null, auth) as any;
      if (!response.data.errorCode) {
        const result = {
          regularNewPossible: response.data.regularNewPossible,
          regularContinuationPossible: response.data.regularContinuationPossible,
          sixSummaryNewPossible: response.data.sixSummaryNewPossible,
          sixSummaryContinuationPossible: response.data.sixSummaryContinuationPossible
        };
        dispatch(setDownloadPossibility(result));
      } else {
        dispatch(setApiErrorCode(response.data.errorCode));
      }
      dispatch(updateDeliveryLoading(false));
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}