import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_ENV, SENTRY_ENABLE } from "../config";

export interface sentryTag {
  [key: string]: string;
};

export interface sentryContext {
  contextID: string, info: { [key: string]: string; }
}

/**
 * sentryの初期化処理
 */
export const initSentry = () => {
  if (SENTRY_DSN !== undefined && SENTRY_ENV !== undefined && SENTRY_ENABLE !== undefined) {
    const sentryEnabled = SENTRY_ENABLE === 'true';
    Sentry.init({
      ignoreErrors: [
        "ResizeObserver loop completed with undelivered notifications.",
      ],
      dsn: SENTRY_DSN,
      defaultIntegrations: false,
      integrations: [
        Sentry.breadcrumbsIntegration(),
        Sentry.functionToStringIntegration(),
        Sentry.globalHandlersIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.inboundFiltersIntegration(),
        Sentry.linkedErrorsIntegration(),
        Sentry.browserApiErrorsIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: SENTRY_ENV,
      enabled: sentryEnabled,
      beforeSend(event) {
        const userIdFlg = event.user?.id?.toString().indexOf('global_user');
        if (event.user?.id && userIdFlg === 0) {
          event.user.id = 'unkwoun_user';
          event.user.username = 'unkwoun_user';
        }
        return event;
      },
    });
  }
}

/**
 * sentryのuser情報を設定する
 *
 * @param uid 自身のuid
 */
export const setSentryUser = (uid: string) => {
  Sentry.setUser({ id: uid, });
}

/**
 * sentryのBreadcrumb追加用共通化関数
 *
 * @param logMsg logメッセージ
 * @param finger logグループ化用識別子
 * @param level log level: "fatal", "error", "warning", "log", "info", "debug"
 * @param tags logに付けるtag
 * @param contexts logに付けるcontext
 */
export const setSentryBreadcrumb = (breadCrumb: Sentry.Breadcrumb) => {
  Sentry.addBreadcrumb(breadCrumb);
}

/**
 * sentryのglobal context情報を設定する
 *
 * @param context context内容
 */
export const setSentryContext = (context: sentryContext) => {
  Sentry.setContext(context.contextID, context.info);
}

/**
 * sentryのlog出力用共通化関数
 *
 * @param logMsg logメッセージ
 * @param finger logグループ化用識別子
 * @param level log level: "fatal", "error", "warning", "log", "info", "debug"
 * @param tags logに付けるtag
 * @param contexts logに付けるcontext
 */
export const sendSentryLog = (
  logMsg: string,
  finger: string,
  level: Sentry.SeverityLevel = 'log',
  tags?: sentryTag,
  contexts?: sentryContext[],
) => {
  Sentry.withScope(function (scope) {
    // group errors together based on their request and response
    scope.setFingerprint([finger]);
    if (tags) {
      const tagKeyList = Object.keys(tags);
      tagKeyList.forEach((item) => {
        scope.setTag(item, tags[item]);
      })
    }
    // Sentry.setTag("test_tag", 'log_test');
    if (contexts && contexts.length > 0) {
      contexts.forEach((item: sentryContext) => {
        scope.setContext(item.contextID, item.info);
      })
    }
    // Sentry.setContext("scope_debug_info",{'func':'checkOneTimeToken','scopeArea':'sc3', 'context_msg': '一時tokenの検証です。'})
    scope.captureMessage(logMsg, level);
  });
}