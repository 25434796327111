import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { setTableCurrentPage, setTableSortField, setTableSortOrder, getTableData } from "./store/action";

function NoticeSearchTableFrame(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);

  const dispatch = useDispatch() as any;

  const query = {
    noticeID: props.noticeID,
    freeWord: props.freeWord,
    noticeDisplayStatus: props.noticeDisplayStatus,
    isDeleted: props.isDeleted,
    isPublished: props.isPublished,
    noticeType: props.noticeType,
  };

  //テーブルフッター
  const defaultFooter = () => {
    let footer = props.table.totalDisplayRecords + " 件中 " +
      props.table.pageFirstNum + " から " +
      props.table.pageLastNum + " まで表示";
    if (props.table.totalRecords !== props.table.totalDisplayRecords) {
      footer = footer + "（全 " + props.table.totalRecords + " 件より抽出）";
    }
    return footer;
  };

  let data = [...props.table.data];
  for (let item of data) {
    item.key = item.noticeID;
  }

  //お知らせ開始終了日時をフォマードする
  function dateTimeFormat(value: any) {
    if (value !== '') {
      const dateTime = new Date(value)

      const y = dateTime.getFullYear()
      const m = (dateTime.getMonth() + 1 + '').padStart(2, '0')
      const d = (dateTime.getDate() + '').padStart(2, '0')

      const hh = (dateTime.getHours() + '').padStart(2, '0')
      const mm = (dateTime.getMinutes() + '').padStart(2, '0')
      const ss = (dateTime.getSeconds() + '').padStart(2, '0')

      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    } else {
      return ''
    }
  }

  //テーブルカラム
  const columns: ColumnsType<any> = [
    {
      title: "管理No.",
      dataIndex: "noticeID",
      sorter: true,
      defaultSortOrder: 'descend'
    },
    {
      title: "掲載順",
      dataIndex: "noticeDisplayOrder",
      sorter: true,
    },
    {
      title: "お知らせタイプ",
      dataIndex: "noticeTypeText",
    },
    {
      title: "管理名称",
      dataIndex: "internalManageName",
      sorter: true,
    },
    {
      title: "タイトル",
      dataIndex: "noticeTitle",
      sorter: true,
      render: (text, row) =>
        <Link
          className="notice-title-link"
          to={"/notice?noticeNo=" + row["noticeNo"]}
        >
          {row["noticeTitle"]}
        </Link>
    },
    {
      title: "掲載ステータス",
      dataIndex: "noticeDisplayStatusText",
      sorter: true,
    },
    {
      title: "開始終了日時",
      dataIndex: "noticeDisplayStartTime",
      sorter: true,
      render: (text, row) =>
        <>
          {text === null ? ''
            : dateTimeFormat(new Date(text).toLocaleString())
          }
          <br />
          {row["noticeDisplayEndTime"] === null ? ''
            : dateTimeFormat(new Date(row["noticeDisplayEndTime"]).toLocaleString())
          }
        </>
    },
    {
      title: "有効／無効",
      dataIndex: "isDeletedText",
      sorter: true,
    },
    {
      title: "本番反映",
      dataIndex: "isPublishedText",
      sorter: true,
    },
  ];

  const tableProps: TableProps<any> = {
    bordered: false,
    loading: props.table.isLoading,
    size: "small",
    footer: defaultFooter,
    locale: { emptyText: () => <div style={{ color: '#000031' }}>データはありません。</div> }
  };

  //テーブルのコールバック
  const handleTableChange: TableProps<any>['onChange'] = (...args: any) => {
    //APIで必要なパラメータに変換する
    if (args[2].order === "descend") {
      args[2].order = "desc";
    } else {
      args[2].order = "asc";
    }
    if (args[2].field === "noticeDisplayStatusText") {
      args[2].field = "noticeDisplayStatus";
    }
    if (args[2].field === "noticeDisplayStartTime") {
      args[2].field = "displayDateTime";
    }
    if (args[2].field === "isDeletedText") {
      args[2].field = "isDeleted";
    }
    if (args[2].field === "isPublishedText") {
      args[2].field = "isPublished";
    }
    //テーブルソートオ—ダ—の設定
    dispatch(setTableSortOrder(args[2].order));
    //テーブルソートフィールドの設定
    dispatch(setTableSortField(args[2].field));
    //テーブルカレントページNOの設定
    dispatch(setTableCurrentPage(args[0].current));
    //お知らせテーブルデータの取得
    dispatch(getTableData(msalToken, query, props.table.perPage, args[0].current, args[2].field, args[2].order));
  };

  return (
    <Table
      {...tableProps}
      pagination={{
        position: ["bottomRight"],
        pageSize: props.table.perPage,
        total: props.table.totalDisplayRecords,
        showSizeChanger: false,
        current: props.table.currentPage
      }}
      columns={columns}
      dataSource={data}
      onChange={handleTableChange}
      rowClassName={
        (record: any, index: number) => {
          let className = ''
          className = index % 2 === 0 ? '' : 'odd'
          return className
        }
      }
      sortDirections={['ascend', 'descend', 'ascend']}
    />
  );
}

export default NoticeSearchTableFrame;