import { dateFullFormat, timeFormat } from "../../utils/TimeFormat";

function DiagnosisHistoryCardFrame(props: any) {
  const historyClinicUrl = '/history?diagnosisCode=' + props.diagnosis.diagnosisCode;

  return (
    <div>
      <div className="history-card">
        <div className="columns" style={{ marginBottom: '0.75rem' }}>
          <div className="history-patient-name column column-left-1 is-4 name">
            <div>
              {props.diagnosis.personalFamilyName}&nbsp;
              {props.diagnosis.personalFirstName}
            </div>
            <div className="small-font">
              {props.diagnosis.personalFamilyNameFurigana}&nbsp;
              {props.diagnosis.personalFirstNameFurigana}
            </div>
          </div>
          <div className="column column-right colum-align-center is-8 has-text-right">
            <div className="diagnosis-room-link-div">
              <a
                className="diagnosis-room-link"
                href={historyClinicUrl}
                target="_blank"
                rel="noreferrer"
              >
                診療室
              </a>
            </div>
          </div>
        </div>
        <div className="columns" style={{ marginBottom: '-0.75rem' }}>
          <div className="column column-left-2 is-5">
            <div className="patient-uid">患者ID：{props.diagnosis.personalUid}</div>
            <div className="diagnosis-code history-card-margintop">診療コード：{props.diagnosis.diagnosisCode}</div>
          </div>
          <div className="column column-mid is-3">
            <div className="medi-interview-datatime">
              問診完了：{
                dateFullFormat(props.diagnosis.medicalInterviewEndDatetimeJst)
              }&nbsp;{
                timeFormat(props.diagnosis.medicalInterviewEndDatetimeJst)
              }
            </div>
            <div className="diagnosis-end-datetime history-card-margintop">
              診療完了：{
                dateFullFormat(props.diagnosis.diagnosisEndDatetimeJst)
              }&nbsp;{timeFormat(props.diagnosis.diagnosisEndDatetimeJst)}
            </div>
          </div>
          <div className="column column-right is-4 has-text-right">
            {props.diagnosis.medicineProductName ?
              <div className="medicine-info">
                <span className="medicine-product-name">
                  {props.diagnosis.medicineProductName}&nbsp;&nbsp;
                  {props.diagnosis.sheetCount}シート×{props.diagnosis.times}回
                </span>
              </div>
              :
              <div className="medicine-info">
                処方なし
                {props.diagnosis.reasonContent ?
                  <span className="small-font">
                    （{props.diagnosis.reasonContent}）
                  </span> : ''}
              </div>
            }
            <div className="doctor-info history-card-margintop">
              担当医： {props.diagnosis.doctorPersonalFamilyName}&nbsp;{
                props.diagnosis.doctorPersonalFirstName
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosisHistoryCardFrame;