import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { isAfter, parseISO } from "date-fns";
import { setMsalToken, setMsalExpiresOn, setMsalName, setMsalMail, getGroupList, setRedirect, setMsalAuthenticated } from '../components/login/store/action';
import { setHistoryClinicRoomFlag } from '../components/history/store/action';
import MsalAuth from '../utils/MsalAuth';
import { REDIRECT_URL } from "../config";
import Loading from "./Loading";

function AuthRouter(props: any) {
  const msalAuthenticated = useSelector<any, any>((state) => state.login.msalAuthenticated);
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isLoginable = useSelector<any, any>((state) => state.login.isLoginable);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDeliver = useSelector<any, any>((state) => state.login.isDeliver);
  const apiErrorCode = useSelector<any, any>((state) => state.chat.apiErrorCode);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const patientNumberOfManager = useSelector<any, any>((state) => state.chat.patientNumberOfManager);
  const doctors = useSelector<any, any>((state) => state.chat.doctors);
  const fetchingDiagnosesFlagA = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagA);
  const fetchingDiagnosesFlagB = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagB);

  //このコンポーネント用変数の定義
  //msal情報の取得状態を示すflag、true:取得中、false:取得完了。※取得できなっかた場合もfalseになる
  const [waitingForGetMsalInfo, setWaitingForGetMsalInfo] = useState(true);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();
  const msal = MsalAuth();

  //現在ログイン画面にいるかどうかの判定
  const isLoginUrl = window.location.href.indexOf("/login");

  useEffect(() => {
    if (msalToken === undefined || msalToken === "") {
      //トークンを取得
      msal.getTokens().then((res: any) => {
        if (res) {
          //トークン取得できればreducerに設定する
          const expired = isAfter(new Date(), parseISO(res.expiresOn));
          const isAuthenticated = res.accessToken != null && !expired;
          dispatch(setMsalAuthenticated(isAuthenticated));
          dispatch(setMsalToken(res.accessToken));
          dispatch(setMsalExpiresOn(res.expiresOn));
          if (res.idTokenClaims && res.idTokenClaims.name) {
            dispatch(setMsalName(res.idTokenClaims.name));
          }
          if (res.idTokenClaims && res.idTokenClaims.preferred_username) {
            dispatch(setMsalMail(res.idTokenClaims.preferred_username));
          }
        }
        setWaitingForGetMsalInfo(false);
      })
    }
    //トーケンを取得したの場合は
    if (msalToken) {
      //診療終了診療室のフラグを設定する
      if (props.diagnosisTab === 0 && props.diagnosisCode) {
        dispatch(setHistoryClinicRoomFlag(true));
      } else {
        dispatch(setHistoryClinicRoomFlag(false));
      }
      //認証切れかどうかの判定
      if (msalAuthenticated) {
        //グループ情報一覧とページ情報の取得
        dispatch(getGroupList(
          msalToken, isLoginable, isDoctor, isManager, isDeliver, props.diagnosisCode, displayPatientsLists.length,
          patientNumberOfManager, props.diagnosisTab, props.searchParams, navigate, doctors, fetchingDiagnosesFlagA, fetchingDiagnosesFlagB
        ));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalToken, props.diagnosisTab])

  //ログインページ以外での遷移で認証確認
  if (isLoginUrl === -1) {
    //認証切れかどうかの判定
    if (!msalAuthenticated) {
      //トークンを取得しています
      if (waitingForGetMsalInfo) {
        return <Loading />
      } else {
        if (msalAuthenticated) {
          //ログイン権限のありグループのユーザー
          if (isLoginable) {
            if (apiErrorCode === null) {
              return (
                <>{props.children}</>
              )
            } else {
              return <Navigate to='/err' replace />
            }
            //ログイン権限の無いグループのユーザーはログインさせない
          } else {
            return <Navigate to='/login' replace />
          }
        } else {
          //msalでトークン取得できない場合、ログイン画面にリダイレクトする
          //今いる画面をreducerに保存してログイン後にリダイレクトする
          dispatch(setRedirect(window.location.href));
          return <Navigate to='/login' replace />
        }
      }
    } else {
      if (apiErrorCode === null) {
        //ダイレクト先がない場合は特定ページへ遷移する
        if (window.location.href === REDIRECT_URL) {
          //配送者の場合は配送管理ページへ遷移する
          if (isDeliver) {
            return <Navigate to='/deliveries' replace />
            //医師または管理監督者の場合は診療前一覧ページへ遷移する
          } else if (isManager || isDoctor) {
            return <Navigate to='/beforeDiagnoses' replace />
          } else {
            //グループ情報を取得しない場合はローディングページへ遷移する
            return <Loading />
          }
        } else {
          //ダイレクト先がある場合はそちらへ遷移する
          return (
            <>{props.children}</>
          )
        }
      } else {
        return <Navigate to='/err' replace />
      }
    }
  } else {
    return <>{props.children}</>
  }
}

export default AuthRouter;