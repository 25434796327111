import { useSelector } from "react-redux";
import {
  FluentThemeProvider,
  ChatClientProvider,
  ChatThreadClientProvider,
  DEFAULT_COMPONENT_ICONS,
} from "@azure/communication-react";
import { initializeIcons, registerIcons } from "@fluentui/react";
import ChatComponentsStateful from "./ChatComponentsStateful";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function ChatWindow(props: any) {
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);

  return (
    <FluentThemeProvider>
      <ChatClientProvider chatClient={props.statefulChatClient}>
        <ChatThreadClientProvider chatThreadClient={props.chatThreadClient}>
          <ChatComponentsStateful key={selectedDiagnosisCode} />
        </ChatThreadClientProvider>
      </ChatClientProvider>
    </FluentThemeProvider>
  )
}

export default ChatWindow;