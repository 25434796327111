import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import DiagnosisCard from "./DiagnosisCard";
import { updateTakeOnModal, updateTakeOnDiagnosisCode } from "./store/action";

function DiagnosisCardFrame(props: any) {
  const isManager = useSelector<any, any>((state) => state.login.isManager);

  const dispatch = useDispatch() as any;

  const isLink = useMemo(() => {
    // 管理監督者の場合は全部リンク
    // 更新時にはdoctorPersonalFamilyNameに値を入れている
    return props.diagnosis.doctorPersonalFamilyName || isManager
  }, [props.diagnosis.doctorPersonalFamilyName, isManager]);

  //診療担当ダイアログをオープンする
  function handleTakeOnModal() {
    dispatch(updateTakeOnDiagnosisCode(props.diagnosis.diagnosisCode));
    dispatch(updateTakeOnModal(true));
  }

  return (
    <>
      {
        !isLink ?
          <div className="is-clickable" onClick={handleTakeOnModal}>
            <DiagnosisCard diagnosis={props.diagnosis} />
          </div>
          :
          <div>
            <DiagnosisCard diagnosis={props.diagnosis} />
          </div>
      }
    </>
  )
}

export default DiagnosisCardFrame;