import * as msal from '@azure/msal-browser';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { isAfter, parseISO } from "date-fns";
import { setMsalAuthenticated, setMsalToken, setMsalExpiresOn, setMsalName, setMsalMail } from '../components/login/store/action';
import { SCOPE_DOMAIN, CLIENT_ID, AUTHORITY, REDIRECT_URL } from '../config';

function MsalAuth() {
  // ログインの設定変数
  const scopeDomain = SCOPE_DOMAIN;
  const clientId = CLIENT_ID;
  const authority = AUTHORITY;
  const redirectUri = REDIRECT_URL;

  const msalConfig = {
    auth: {
      authority: authority,
      clientId: clientId,
      // portalで設定したURLと同じにする必要がある
      redirectUri: redirectUri,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };

  const loginRequest = {
    // スコープの指定がフルパスでないと通らないので環境変数から作っている
    scopes: [scopeDomain + clientId + '/execute'],
  };

  // どのアカウントをログアウトするかのリクエスト
  const logoutRequest = {
    account: null,
    mainWindowRedirectUri: redirectUri,
  };

  // トークン取得用のリクエスト(MSAL側でキャッシュから取るか問い合わせるか自動でやってる模様)
  const tokenRequest = {
    // スコープの指定がフルパスでないと通らないので環境変数から作っている
    scopes: [scopeDomain + clientId + '/execute'],
    account: null,
  };

  const myMSALObj = new msal.PublicClientApplication(msalConfig);

  const dispatch = useDispatch() as any;

  const navigate = useNavigate();

  return {
    async login() {
      const result = await myMSALObj.loginPopup(loginRequest);
      //認証の確認
      const expired = isAfter(new Date(), parseISO(result.expiresOn as any));
      const isAuthenticatedFlag = result.accessToken != null || expired;
      //トークン取得できればreducerに設定する
      dispatch(setMsalAuthenticated(isAuthenticatedFlag));
      dispatch(setMsalToken(result.accessToken));
      dispatch(setMsalExpiresOn(result.expiresOn as Date));
      let idTokenClaims = result.idTokenClaims as any;
      if (idTokenClaims && idTokenClaims.name) {
        dispatch(setMsalName(idTokenClaims.name));
      }
      if (idTokenClaims && idTokenClaims.preferred_username) {
        dispatch(setMsalMail(idTokenClaims.preferred_username));
      }
      return result
    },

    async logout() {
      const acounts = await myMSALObj.getAllAccounts();
      if (acounts && acounts.length > 0 && acounts[0].homeAccountId) {
        logoutRequest.account = myMSALObj.getAccountByHomeId(acounts[0].homeAccountId) as any;
        // logoutPopupは引数なしでも実行できるが明確にアカウントを指定した方が良い気がするので設定している
        // ドキュメントにそのあたりの記述は見当たらない
        await myMSALObj.logoutPopup(logoutRequest);
        navigate("/login");
      } else {
        // アカウント取得できない場合なのでそのままログイン画面へ
        navigate("/login");
      }
    },

    async getTokens() {
      const acounts = myMSALObj.getAllAccounts();
      if (acounts && acounts.length > 0 && acounts[0].homeAccountId) {
        let result = null;
        try {
          tokenRequest.account = acounts[0].homeAccountId as any;
          result = await myMSALObj.acquireTokenSilent(tokenRequest as any);
        } catch (err) {
          // 認証がうまく行ってない状態で取得を試みた場合
          return null;
        } finally {
          return result;
        }
      } else {
        // ここに入るケースはStorageに値がない場合とMSのセッションが切れてる場合
        return null;
      }
    }
  }
}

export default MsalAuth;