import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { Checkbox, Form, Input, Button, InputNumber, ConfigProvider } from 'antd';
import NoticeSearchTableFrame from './NoticeSearchTableFrame';
import { setTableCurrentPage, getTableData, setTableSortOrder, setTableSortField } from './store/action';

function Search() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const tableProps = useSelector<any, any>((state) => state.notice.tableProps);

  const [noticeID, setNoticeId] = useState('');
  const [freeWord, setFreeWord] = useState('');
  const [noticeDisplayStatus, setNoticeDisplayStatus] = useState([]);
  const [isDeleted, setIsDeleted] = useState([]);
  const [isPublished, setIsPublished] = useState([]);
  const [noticeType, setNoticeType] = useState([]);

  const [form] = Form.useForm();
  const defaultQuery = {
    noticeID: '',
    freeWord: '',
    noticeDisplayStatus: [],
    isDeleted: [],
    isPublished: [],
    noticeType: []
  };

  const dispatch = useDispatch() as any;

  //掲載ステータス
  const statusOptions = [
    { label: '掲載前', value: '0' },
    { label: '掲載中', value: '1' },
    { label: '掲載終了', value: '2' }
  ];
  //有効／無効
  const deleteOption = [
    { label: '有効', value: 'false' },
    { label: '無効', value: 'true' }
  ];
  //本番反映
  const publishOption = [
    { label: '未反映', value: 'false' },
    { label: '反映済', value: 'true' }
  ];
  //お知らせタイプ
  const noticeTypeOption = [
    { label: '通常お知らせ', value: '0' },
    { label: '特定ユーザーお知らせ', value: '1' }
  ];

  //「検索」ボタンのクリック事件
  const onFinish = async (values: any) => {
    const query = {
      noticeID: values["noticeID"] ?? '',
      freeWord: values["freeWord"] ?? '',
      noticeDisplayStatus: values["noticeDisplayStatus"] ?? [],
      isDeleted: values["isDeleted"] ?? [],
      isPublished: values["isPublished"] ?? [],
      noticeType: values["noticeType"] ?? []
    };
    setNoticeId(query.noticeID);
    setFreeWord(query.freeWord);
    setNoticeDisplayStatus(query.noticeDisplayStatus);
    setIsDeleted(query.isDeleted);
    setIsPublished(query.isPublished);
    setNoticeType(query.noticeType);
    dispatch(setTableCurrentPage(1));
    dispatch(getTableData(msalToken, query, tableProps.perPage, 1, tableProps.sortField, tableProps.sortOrder));
  };

  //「条件クリア」ボタンのクリック事件
  const onReset = () => {
    form.resetFields();
    onFinish(defaultQuery);
  };

  useEffect(() => {
    if (isManager) {
      dispatch(setTableSortOrder('desc'));
      dispatch(setTableSortField('noticeID'));
      dispatch(setTableCurrentPage(1));
      dispatch(getTableData(msalToken, defaultQuery, tableProps.perPage, 1, 'noticeID', 'desc'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <h1 id='notice_search_title' className="search-title">お知らせ 検索</h1>
      <div className="search">
        <div className="search-content">
          <div id='notice_search_header' className="search-header">
            <span className="icon is-medium">
              <Icon icon="mdi:magnify" />
            </span>
            <label>検索条件</label>
          </div>
          <ConfigProvider theme={{
            components: {
              Form: {
                itemMarginBottom: 10,
              },
            },
          }}>
            <Form
              colon={false}
              labelCol={{ span: 3 }}
              form={form}
              onFinish={onFinish}
              onKeyDown={(e: any) => { if (e.keyCode === 13) { e.preventDefault() } }}
            >
              <div id='notice_search_condition' className="search-condition">
                <Form.Item name='noticeID' label="管理No." style={{ fontWeight: 700 }}
                  rules={[{ type: 'number', min: 0, message: '値は0以上数値にする必要があります。' }]}
                >
                  <InputNumber controls={false} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name='freeWord' label="フリーワード" style={{ fontWeight: 700 }}>
                  <Input style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name='noticeDisplayStatus' label="掲載ステータス" style={{ fontWeight: 700 }}>
                  <Checkbox.Group options={statusOptions} style={{ fontWeight: 500 }} />
                </Form.Item>
                <Form.Item name='isDeleted' label="有効／無効" style={{ fontWeight: 700 }}>
                  <Checkbox.Group options={deleteOption} style={{ fontWeight: 500 }} />
                </Form.Item>
                <Form.Item name='isPublished' label="本番反映" style={{ fontWeight: 700 }}>
                  <Checkbox.Group options={publishOption} style={{ fontWeight: 500 }} />
                </Form.Item>
                <Form.Item name='noticeType' label="お知らせタイプ" style={{ fontWeight: 700 }}>
                  <Checkbox.Group options={noticeTypeOption} style={{ fontWeight: 500 }} />
                </Form.Item>
              </div>
              <div id='notice_search_footer' className="search-footer">
                <Button className="button clear-button" onClick={onReset}>
                  <span>条件クリア</span>
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className="button search-button"
                >
                  <span>検索</span>
                </Button>
              </div>
            </Form>
          </ConfigProvider>
        </div>
        <div id='notice_search_table' className="search-table">
          <NoticeSearchTableFrame
            table={tableProps}
            noticeID={noticeID}
            freeWord={freeWord}
            noticeDisplayStatus={noticeDisplayStatus}
            isDeleted={isDeleted}
            isPublished={isPublished}
            noticeType={noticeType}
          />
        </div>
      </div>
    </section>
  )
}

export default Search;