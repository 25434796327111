import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import moment from "moment";
import { TimePicker, DatePicker } from 'antd';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { setDisplayPatientsLists, updateSearchModal, updateSearchFlag, setSelectedDiagnosisCode, updateSearchRadioValue } from './store/action';
import { setBeforeHalfHour, setAfterHalfHour } from '../../../utils/TimeFormat';
import { ERR_MSG_VIDEO_FILTER_1, ERR_MSG_VIDEO_FILTER_2, SENTRT_CONTEXT_ID } from '../../../config/constant';
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';

function SearchModal(props: any) {
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const searchRadioValue = useSelector<any, any>((state) => state.chat.searchRadioValue);

  const formatDateTime = "YYYY/MM/DD HH:mm";
  const formatDate = "YYYY/MM/DD";
  const formatTime = "HH:mm";

  const initFromDate = dayjs(parseISO(moment().format()));
  const initFromTime = dayjs(setBeforeHalfHour());

  //絞込でインプットした氏名
  const [inputName, setInputName] = useState("");
  //開始日付
  const [dateFrom, setDateFrom] = useState<any>(initFromDate);
  //終了日付
  const [dateTo, setDateTo] = useState<any>(null);
  //開始時間
  const [timeFrom, setTimeFrom] = useState<any>(initFromTime);
  //終了時間
  const [timeTo, setTimeTo] = useState<any>(null);
  //ビデオで絞込エラーメッセージ
  const [filterVideoNoSetErrMsg, setFilterVideoNoSetErrMsg] = useState<string>("");

  const dispatch = useDispatch() as any;

  const inputRef = useRef<HTMLInputElement>(null);

  //絞込ダイアログの高さの設定
  function setFilterDialogCSS() {
    if (filterVideoNoSetErrMsg) {
      return { height: '525px', display: 'block' };
    } else {
      return { height: '490px', display: 'block' };
    }
  }

  //検索・絞込ダイアログをクロース
  function closeSearchModal() {
    //sentry log
    sendSentryLog('絞り込みダイアログを閉じる', 'closeFilterDialog', 'log', { 'event_name': 'filter_chat_list' });
    dispatch(updateSearchModal(false));
    //ラジオ値を消去
    dispatch(updateSearchRadioValue(''));
  }

  //「氏名で検索」インプット値の更新
  function handleInputName(event: any) {
    setInputName(event.target.value);
  }

  //ラジオ値の更新
  function handleRadioValue(event: any) {
    dispatch(updateSearchRadioValue(event.target.value));
  }

  //「氏名で検索」ラジオをクリックして入力ボックスがオートフォーカス
  function handleInputRef() {
    inputRef.current?.focus();
  }

  //「条件で検索・絞込する」ボタンのクリック事件
  function handleFilterChatList() {
    //sentry log
    const sentryContext: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        filterCondition: searchRadioValue
      }
    };
    sendSentryLog('絞り込みを実施する', 'execFilter', 'log', { 'event_name': 'filter_chat_list' }, [sentryContext]);
    //検索・絞込ダイアログをクロース
    //エラーメッセージをクリア
    setFilterVideoNoSetErrMsg('');
    let filterErrMsg = "";
    //氏名で検索
    if (searchRadioValue === "name") {
      let splitName = inputName.trim().split(/\s+/);
      let filterFamilyName = splitName[0];
      let filterFirstName = splitName[1];
      let displayPatientListsByName = [];
      if (inputName.trim().length !== 0) {
        if (filterFirstName !== undefined && filterFirstName !== null) {
          //氏と名で検索
          displayPatientListsByName = displayPatientsLists.filter(
            (item: any) => { return (item.personalFamilyName === filterFamilyName && item.personalFirstName.indexOf(filterFirstName) !== -1) || (item.personalFamilyNameFurigana === filterFamilyName && item.personalFirstNameFurigana.indexOf(filterFirstName) !== -1) })
        } else {
          //氏または名で検索
          displayPatientListsByName = displayPatientsLists.filter(
            (item: any) => { return item.personalFamilyName.indexOf(filterFamilyName) !== -1 || item.personalFirstName.indexOf(filterFamilyName) !== -1 || item.personalFamilyNameFurigana.indexOf(filterFamilyName) !== -1 || item.personalFirstNameFurigana.indexOf(filterFamilyName) !== -1 })
        }
        if (displayPatientListsByName.length <= 0) {
          props.setNoSearchResult(true);
        }
        dispatch(setDisplayPatientsLists(displayPatientListsByName));
      } else {
        filterErrMsg = ERR_MSG_VIDEO_FILTER_1;
        setFilterVideoNoSetErrMsg(filterErrMsg);
      }
    }
    //未読で検索
    if (searchRadioValue === "unread") {
      let displayPatientsListsByUnread = [];
      displayPatientsListsByUnread = displayPatientsLists.filter(
        (item: any) => { return item.chatInfo.hasUnread === true })
      if (displayPatientsListsByUnread.length <= 0) {
        props.setNoSearchResult(true);
      }
      dispatch(setDisplayPatientsLists(displayPatientsListsByUnread));
    }
    //未返信で検索
    if (searchRadioValue === "noReply") {
      let displayPatientsListsByNoReply = [];
      displayPatientsListsByNoReply = displayPatientsLists.filter(
        (item: any) => { return item.chatInfo.hasNoReply === true })
      if (displayPatientsListsByNoReply.length <= 0) {
        props.setNoSearchResult(true);
      }
      dispatch(setDisplayPatientsLists(displayPatientsListsByNoReply));
    }
    //診療終了対象で検索
    if (searchRadioValue === "end") {
      const nowJst = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
      let nowJstDate = new Date(nowJst.getTime());
      let sevenDaysAgoJst = new Date(nowJstDate.setDate(nowJstDate.getDate() - 7));
      let displayPatientsListsByEnd = [];
      displayPatientsListsByEnd = displayPatientsLists.filter(
        (item: any) => {
          // ビデオ日時が過去日の場合はtrue
          const videoDateAgoFlg = moment(item.diagnosisScheduledEndDatetimeJst).format(formatDateTime) < moment(nowJst).format(formatDateTime);
          // ビデオ日時が空の場合はtrue
          const videoDateNullFlg = !item.diagnosisScheduledEndDatetimeJst && !item.diagnosisScheduledStartDatetimeJst;
          // 最後のチャットが患者のメッセージ送信で8日以上経過しているの場合はtrue
          const lastMsgDate8dayAgoFlg = moment(item.chatInfo.lastPatientChatSendDatetimeJst).format(formatDateTime) < moment(sevenDaysAgoJst).format(formatDateTime);
          return (videoDateAgoFlg || videoDateNullFlg) && lastMsgDate8dayAgoFlg;
        });
      if (displayPatientsListsByEnd.length <= 0) {
        props.setNoSearchResult(true);
      }
      dispatch(setDisplayPatientsLists(displayPatientsListsByEnd));
    }
    //ビデオ日時で検索
    if (searchRadioValue === "video") {
      //絞込ビデオ開始日時
      let startDate = null;
      let startTime = null;
      let videoFilterStartTime = null as any;
      //絞込ビデオ終了日時
      let endDate = null;
      let endTime = null;
      let videoFilterEndTime = null as any;
      //すべての入力欄が未入力である
      if (!dateFrom && !timeFrom && !dateTo && !timeTo) {
        filterErrMsg = ERR_MSG_VIDEO_FILTER_1;
        setFilterVideoNoSetErrMsg(filterErrMsg);
        //開始日付または開始時間のみが入力される
        //開始日付または開始時間のみが補足する
      } else if ((dateFrom || timeFrom) && (!dateTo && !timeTo)) {
        //開始日付補足
        if (dateFrom) {
          startDate = moment(dateFrom.toDate()).format(formatDate);
        } else {
          setDateFrom(dayjs(parseISO(moment().format())));
          startDate = moment().format(formatDate);
        }
        //開始時間補足
        if (timeFrom) {
          startTime = moment(timeFrom.toDate()).format(formatTime);
        } else {
          setTimeFrom(dayjs(setBeforeHalfHour()));
          startTime = moment(setBeforeHalfHour()).format(formatTime);
        }
        videoFilterStartTime = startDate + ' ' + startTime;
        //終了日付または開始時間のみが入力される
        //終了日付または開始時間のみが補足する
      } else if ((dateTo || timeTo) && (!dateFrom && !timeFrom)) {
        //終了日付補足
        if (dateTo) {
          endDate = moment(dateTo.toDate()).format(formatDate);
        } else {
          setDateTo(dayjs(parseISO(moment().format())));
          endDate = moment().format(formatDate);
        }
        //終了時間補足
        if (timeTo) {
          endTime = moment(timeTo.toDate()).format(formatTime);
        } else {
          if (moment().format(formatTime) > '23:30') {
            const toDate = dayjs(parseISO(moment(dateTo.toDate()).add(1, 'days').format()));
            setDateTo(toDate);
            endDate = moment(toDate.toDate()).format(formatDate);
          }
          setTimeTo(dayjs(setAfterHalfHour()));
          endTime = moment(setAfterHalfHour()).format(formatTime);
        }
        videoFilterEndTime = endDate + ' ' + endTime;
      } else {
        //未入力項目がある場合補足する
        //開始日付補足
        if (dateFrom) {
          startDate = moment(dateFrom.toDate()).format(formatDate);
        } else {
          setDateFrom(dayjs(parseISO(moment().format())));
          startDate = moment().format(formatDate);
        }
        //開始時間補足
        if (timeFrom) {
          startTime = moment(timeFrom.toDate()).format(formatTime);
        } else {
          setTimeFrom(dayjs(setBeforeHalfHour()));
          startTime = moment(setBeforeHalfHour()).format(formatTime);
        }
        videoFilterStartTime = startDate + ' ' + startTime;
        //終了日付補足
        if (dateTo) {
          endDate = moment(dateTo.toDate()).format(formatDate);
        } else {
          setDateTo(dayjs(parseISO(moment().format())));
          endDate = moment().format(formatDate);
        }
        //終了時間補足
        if (timeTo) {
          endTime = moment(timeTo.toDate()).format(formatTime);
        } else {
          if (moment().format(formatTime) > '23:30') {
            const toDate = dayjs(parseISO(moment(dateTo.toDate()).add(1, 'days').format()));
            setDateTo(toDate);
            endDate = moment(toDate.toDate()).format(formatDate);
          }
          setTimeTo(dayjs(setAfterHalfHour()));
          endTime = moment(setAfterHalfHour()).format(formatTime);
        }
        videoFilterEndTime = endDate + ' ' + endTime;
        if (videoFilterStartTime >= videoFilterEndTime) {
          filterErrMsg = ERR_MSG_VIDEO_FILTER_2;
          setFilterVideoNoSetErrMsg(filterErrMsg);
        }
      }
      let displayPatientsListsByVideo = [];
      //開始日時と終了日時の設定した
      if (videoFilterStartTime !== null && videoFilterEndTime !== null) {
        displayPatientsListsByVideo = displayPatientsLists.filter(
          (item: any) => {
            return moment(item.diagnosisScheduledStartDatetimeJst).format(formatDateTime) >= videoFilterStartTime
              && moment(item.diagnosisScheduledEndDatetimeJst).format(formatDateTime) <= videoFilterEndTime
          })
        //開始日時のみ設定した
      } else if (videoFilterStartTime !== null && videoFilterEndTime === null) {
        displayPatientsListsByVideo = displayPatientsLists.filter(
          (item: any) => {
            return moment(item.diagnosisScheduledStartDatetimeJst).format(formatDateTime) === videoFilterStartTime
          })
        //終了日時のみ設定した
      } else if (videoFilterStartTime === null && videoFilterEndTime !== null) {
        displayPatientsListsByVideo = displayPatientsLists.filter(
          (item: any) => {
            return moment(item.diagnosisScheduledEndDatetimeJst).format(formatDateTime) === videoFilterEndTime
          })
      }
      if (filterErrMsg === "") {
        if (displayPatientsListsByVideo.length <= 0) {
          props.setNoSearchResult(true);
        }
        dispatch(setDisplayPatientsLists(displayPatientsListsByVideo));
      }
    }
    if (filterErrMsg === "") {
      //検索・絞込ダイアログをクロース
      dispatch(updateSearchModal(false));
      //条件で絞込中の表示
      dispatch(updateSearchFlag(true));
      //患者選択の状態を消去
      dispatch(setSelectedDiagnosisCode(''));
    }
  }

  return (
    <>
      <div id="filter_modal" className="modalsort" style={setFilterDialogCSS()}>
        <div id="close_filter_btn" className="closebtn" onClick={closeSearchModal}></div>
        <div id="filter_inner" className="inner">
          <h2>条件で検索・絞込する</h2>
          <ul>
            <li>
              <label id="filter_label_name">
                <input
                  id="filter_radio_name"
                  type="radio"
                  value="name"
                  name="myRadio"
                  checked={searchRadioValue === "name"}
                  onChange={handleRadioValue}
                  onClick={() => { handleInputRef() }}
                /> 氏名で検索
                <input
                  id="filter_input_name"
                  type="text"
                  value={inputName}
                  onChange={handleInputName}
                  onFocus={() => { dispatch(updateSearchRadioValue("name")); }}
                  ref={inputRef} />
              </label>
            </li>
            <li>
              <label id="filter_label_unread">
                <input
                  id="filter_radio_unread"
                  type="radio"
                  value="unread"
                  name="myRadio"
                  checked={searchRadioValue === "unread"}
                  onChange={handleRadioValue}
                /> 未読
              </label>
            </li>
            <li>
              <label id="filter_label_no_reply">
                <input
                  id="filter_radio_no_reply"
                  type="radio"
                  value="noReply"
                  name="myRadio"
                  checked={searchRadioValue === "noReply"}
                  onChange={handleRadioValue}
                /> 未返信
              </label>
            </li>
            <li>
              <label id="filter_label_end">
                <input
                  id="filter_radio_end"
                  type="radio"
                  value="end"
                  name="myRadio"
                  checked={searchRadioValue === "end"}
                  onChange={handleRadioValue}
                /> 診療終了対象（ビデオ日時が過去日かつ、一定期間メッセージの返信がなかった患者）
              </label>
            </li>
            <li>
              <label id="filter_label_video">
                <input
                  id="filter_radio_video"
                  type="radio"
                  value="video"
                  name="myRadio"
                  checked={searchRadioValue === "video"}
                  onChange={handleRadioValue}
                /> ビデオ日時
                <DatePicker
                  className="picker"
                  value={dateFrom}
                  onFocus={() => { dispatch(updateSearchRadioValue("video")); }}
                  onChange={(date) => {
                    if (date) {
                      setDateFrom(date);
                    }
                    else {
                      setDateFrom(null);
                      dispatch(updateSearchRadioValue("video"));
                    }
                  }}
                />
                <TimePicker
                  className="picker"
                  style={{ marginRight: '8px' }}
                  value={timeFrom}
                  format={'HH:mm'}
                  minuteStep={30}
                  onFocus={() => { dispatch(updateSearchRadioValue("video")); }}
                  onChange={(date) => {
                    if (date) {
                      setTimeFrom(date)
                    } else {
                      setTimeFrom(null);
                      dispatch(updateSearchRadioValue("video"));
                    }
                  }}
                />
                ～
                <DatePicker
                  className="picker"
                  value={dateTo}
                  onFocus={() => { dispatch(updateSearchRadioValue("video")); }}
                  onChange={(date) => {
                    if (date) {
                      setDateTo(date);
                    }
                    else {
                      setDateTo(null);
                      dispatch(updateSearchRadioValue("video"));
                    }
                  }}
                />
                <TimePicker
                  className="picker"
                  value={timeTo}
                  format={'HH:mm'}
                  minuteStep={30}
                  onFocus={() => { dispatch(updateSearchRadioValue("video")); }}
                  onChange={(date) => {
                    if (date) {
                      setTimeTo(date)
                    } else {
                      setTimeTo(null);
                      dispatch(updateSearchRadioValue("video"));
                    }
                  }}
                />
              </label>
            </li>
            {filterVideoNoSetErrMsg ?
              <div className="filter_video_err">
                {filterVideoNoSetErrMsg}
              </div>
              : ''}
          </ul>
          <button
            id="filter_on_btn"
            className="sort"
            disabled={searchRadioValue === "" ? true : false}
            onClick={handleFilterChatList}>
            条件で検索・絞込する
          </button>
        </div>
      </div>
      <div className="modalbg" style={{ display: 'block' }}></div>
    </>
  )
}

export default SearchModal;