// データフォーマット:
const defaultState = ({
  //ビデオグローブID
  videoGroupId: null,
  //ビデオ入室のトーケン						
  videoToken: "",
  //ビデオ入室のトーケンの有効期限						
  videoExpiredOn: "",
  //ビデオエラーコード
  videoErrCode: null,
  //初回入室のflag
  firstStartVideo: true,
  //再入室の場合は新たにビデオリンクを発行するか
  issueNewLink: true,
  //ACSビデオ情報取得時間
  getAcsVideoInfoTime: null,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'set_video_group_id':
      return { ...state, videoGroupId: action.groupId }
    case 'set_video_token':
      return { ...state, videoToken: action.token }
    case 'set_video_expired_on':
      return { ...state, videoExpiredOn: action.expiredOn }
    case 'set_video_err_code':
      return { ...state, videoErrCode: action.errorCode }
    case 'set_first_start_video_flag':
      return { ...state, firstStartVideo: action.flag }
    case 'set_issue_new_link_flag':
      return { ...state, issueNewLink: action.flag }
    case 'set_get_acs_video_info_time':
      return { ...state, getAcsVideoInfoTime: action.time }
    default:
      return state;
  }
}

export default variable;
