import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAfter, parseISO } from 'date-fns';
import { setIssueNewLink } from '../video/store/action';
import { setShowVideoRelatedModalIndex } from './store/action';
import { setSentryBreadcrumb, sendSentryLog } from '../../../utils/SentryFuc';
import { startVideo } from '../../../utils/VideoFuc';

function VideoSelectModal() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const videoExpiredOn = useSelector<any, any>((state) => state.video.videoExpiredOn);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const [isClickable, setIsClickable] = useState<boolean>(true);

  const dispatch = useDispatch() as any;

  //ビデオ入室ダイアログを閉じる
  function closeVideoSelectModal() {
    //sentry log
    sendSentryLog('ビデオ入室ダイアログを閉じる(初回以後)', 'selectVideoTypeDialog', 'log',
      { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(setShowVideoRelatedModalIndex(0));
  }

  //ビデオリンクを発行し、入室する
  async function startVideoByNewLink() {
    //sentry breadcrumbs
    setSentryBreadcrumb({
      type: 'info',
      category: 'startVideo',
      message: '初回以後で「新しいビデオリンクを発行して入室する」ボタンを押す diagnosisCode:' + selectedDiagnosisCode + ' & patientUid:' + personalInfoDetail.personalUid,
      level: 'info',
    });
    //ビデオ診療開始時、一時トークン、一時トークンの有効期限、パスワードなどを作成
    const response = await dispatch(startVideo(msalToken, selectedDiagnosisCode));
    if (response.data.errorCode === null) {
      //新たにビデオリンクの発行flagをtrueに設定
      dispatch(setIssueNewLink(true));
      //「VideoWindow」を開く
      dispatch(setShowVideoRelatedModalIndex(3));
    }
  }

  //「ビデオリンクを発行し、入室する」ボタンのクリック事件
  const handleClick = () => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      startVideoByNewLink();
      setTimeout(() => {
        setIsClickable(true);
      }, 3000)
    }
  }

  function startVideoByOldLink() {
    //sentry breadcrumbs
    setSentryBreadcrumb({
      type: 'info',
      category: 'startVideo',
      message: '「以前発行したビデオリンクで再入室」ボタンを押す diagnosisCode:' + selectedDiagnosisCode + ' & patientUid:' + personalInfoDetail.personalUid,
      level: 'info',
    });
    const expired = isAfter(new Date(), parseISO(videoExpiredOn));
    //ビデオトーケン期限切いない
    if (!expired) {
      //新たにビデオリンクの発行flagをfalseに設定
      dispatch(setIssueNewLink(false));
      //「VideoWindow」を開く
      dispatch(setShowVideoRelatedModalIndex(3));
    } else {
      //「VideoTimeErrorModal」を開く
      dispatch(setShowVideoRelatedModalIndex(4));
    }
  }

  return (
    <div id='videoSelectDialog' className="dialog">
      <div className="wrap">
        <div id='closeVideoSelectDialog' className="close" onClick={() => { closeVideoSelectModal() }}></div>
        <div className="inner">
          <p id='videoSelectDialogText' className="read">
            選択肢を選ぶと<br />患者とのビデオ通話を開始します。
          </p>
          <div className="col">
            <button
              id='startVideoByNewLinkBtn'
              style={{ marginRight: '2.5px' }}
              onClick={handleClick}
            >
              新たにビデオリンクを<br />発行し、入室する
            </button>
            <button
              id='startVideoByOldLinkBtn'
              style={{ marginLeft: '2.5px' }}
              onClick={startVideoByOldLink}>
              以前発行した<br />ビデオリンクに再入室
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoSelectModal;