import { GROUP_LIST, SAVE_BROWSE_HISTORY } from "../../../api/Api";
import { get, post } from '../../../api/FetchAPI';
import {
  getDiagnosisListsOfDoctor, getDiagnosisListsOfManager, getDoctors,
  setApiErrorCode, setApiErrorMessage, updateFetchDiagnosesFlagA, updateFetchDiagnosesFlagB
} from '../../diagnosing/chatList/store/action';
import { getCompletedAllPersonalDetail } from '../../history/store/action';
import { getWithoutPrescribingReason } from '../../diagnosing/prescription/store/action';
import { getMedicineList } from '../../diagnosing/clinicRoom/store/action';
import { getBeforeDiagnoses, searchBeforeDiagnosis, updateBeforeLoading } from '../../beforeDiagnose/store/action';
import { getCompletedDiagnoses, searchCompletedDiagnoses, updateCompletedLoading } from '../../history/store/action';
import { updateNoticeAuthLoading } from '../../notice/store/action';
import { getDownloadPossibility, updateDeliveryAuthLoading } from "../../deliveries/store/action";
import { ERR_MSG_011 } from '../../../config/constant';
import { sendSentryLog, setSentryUser } from '../../../utils/SentryFuc';

// 以下、内部用reduxのデータ処理
const setManager = (flag: boolean) => ({
  type: 'set_manager',
  flag
})

const setDoctor = (flag: boolean) => ({
  type: 'set_doctor',
  flag
})

const setDeliver = (flag: boolean) => ({
  type: 'set_deliver',
  flag
})

const setLoginable = (flag: boolean) => ({
  type: 'set_loginable',
  flag
})

// 以下、外部用reduxのデータ処理
export const setRedirect = (redirect: any) => ({
  type: 'set_redirect',
  redirect
})

export const setMsalAuthenticated = (isAuthenticated: boolean) => ({
  type: 'set_msal_authenicated',
  isAuthenticated
})

export const setMsalToken = (token: string) => ({
  type: 'set_msal_token',
  token
})

export const setMsalExpiresOn = (expiresOn: any) => ({
  type: 'set_msal_expires_on',
  expiresOn
})

export const setMsalName = (name: string) => ({
  type: 'set_msal_name',
  name
})

export const setMsalMail = (mail: string) => ({
  type: 'set_msal_mail',
  mail
})

export const setAllowClick = (flag: boolean) => ({
  type: 'set_allow_click',
  flag
})

// グループ情報一覧取得
export const getGroupList = (
  auth: any, isLoginable: boolean, isDoctor: boolean, isManager: boolean, isDeliver: boolean, diagnosisCode: string, patientNumberOfDoctor: number, patientNumberOfManager: number,
  pageTab: number, searchParams: any, navigate: any, doctors: any, fetchingDiagnosesFlagA: boolean, fetchingDiagnosesFlagB: boolean
) => {
  return async (dispatch: any) => {
    try {
      let loginableFlgInFunc = false;
      let isManagerInFunc = false;
      let isDoctorInFunc = false;
      let isDeliverInFunc = false;
      if (!isLoginable) {
        let response = await get(GROUP_LIST, null, auth) as any;
        const result = response.data.groups;
        if (response.data.errorCode !== null) {
          await dispatch(setApiErrorCode(response.data.errorCode));
          await dispatch(setApiErrorMessage(ERR_MSG_011));
          return false;
        } else {
          const isManager = result.length === 1 && result[0].groupName === 'Manager';
          const isDoctor = result.length === 1 && result[0].groupName === 'Doctor';
          const isDeliver = result.length === 1 && result[0].groupName === 'Deliver';
          const isLoginableRes = result.length === 1 && ['Manager', 'Doctor', 'Deliver'].includes(result[0].groupName);
          dispatch(setManager(isManager));
          dispatch(setDoctor(isDoctor));
          dispatch(setDeliver(isDeliver));
          dispatch(setLoginable(isLoginableRes));
          if (isLoginableRes) {
            //sentry user設定
            setSentryUser(response.data.uid);
            //sentry log
            sendSentryLog('ログイン成功。', 'login', 'log', { 'event_name': 'login' });
            loginableFlgInFunc = isLoginableRes;
            isManagerInFunc = isManager;
            isDoctorInFunc = isDoctor;
            isDeliverInFunc = isDeliver;
          }
        }
      } else {
        loginableFlgInFunc = isLoginable;
        isManagerInFunc = isManager;
        isDoctorInFunc = isDoctor;
        isDeliverInFunc = isDeliver;
      }
      if (loginableFlgInFunc) {
        //「診療終了診療室」ページへアクセス
        if (pageTab === 0 && diagnosisCode) {
          if (!isDeliverInFunc) {
            dispatch(setAllowClick(true));
            //診療終了診療室の情報を取得
            await dispatch(getCompletedAllPersonalDetail(auth, diagnosisCode));
            //患者情報閲覧履歴を保存
            const data = {
              diagnosisCode: diagnosisCode,
              browsingDiagnosisStatus: 1
            }
            post(SAVE_BROWSE_HISTORY, data, auth);
          } else {
            navigate("/404");
          }
        }
        //「診療終了」ページへアクセス
        if (pageTab === 0 && !diagnosisCode) {
          if (!isDeliverInFunc) {
            //パラメータの検証
            if (searchParams.pageNo && (isNaN(parseInt(searchParams.pageNo)) || parseInt(searchParams.pageNo) <= 0)) {
              navigate("/404");
              return
            }
            if (searchParams.surname) {
              const para = ((searchParams.surname ?? '') + ' ' + (searchParams.givenName ?? '')).trim()?.split(/\s/)?.filter((v: any) => v) ?? [];
              if (para.length < 1 || para.length > 2) {
                navigate("/404");
                return
              }
            }
            await dispatch(updateCompletedLoading(true));
            //診療終了一覧の診療情報を取得
            if (!searchParams.pageNo && !searchParams.surname) {
              dispatch(getCompletedDiagnoses(auth, 1, navigate));
            }
            if (searchParams.pageNo && !searchParams.surname) {
              dispatch(getCompletedDiagnoses(auth, parseInt(searchParams.pageNo), navigate));
            }
            if (searchParams.surname) {
              dispatch(searchCompletedDiagnoses(auth, searchParams.surname, searchParams.givenName));
            }
            //診療中一覧の診療情報を取得
            if (isDoctorInFunc) {
              if ((patientNumberOfDoctor <= 0 || patientNumberOfDoctor === undefined) && !fetchingDiagnosesFlagB) {
                dispatch(updateFetchDiagnosesFlagA(true));
                dispatch(setAllowClick(true));
                await dispatch(getDiagnosisListsOfDoctor(auth));
                dispatch(updateFetchDiagnosesFlagA(false));
              }
            }
            if (isManagerInFunc) {
              if (doctors.length <= 0) {
                const doctorMaster = await dispatch(getDoctors(auth));
                if (doctorMaster) {
                  if ((patientNumberOfManager <= 0 || patientNumberOfManager === undefined) && !fetchingDiagnosesFlagB) {
                    dispatch(updateFetchDiagnosesFlagA(true));
                    dispatch(setAllowClick(true));
                    await dispatch(getDiagnosisListsOfManager(auth, doctorMaster, doctorMaster[0].doctorPersonalACSId));
                    dispatch(updateFetchDiagnosesFlagA(false));
                  }
                }
              }
            }
          } else {
            navigate("/404");
          }
        }
        //診療中一覧ページへアクセス
        if (pageTab === 1) {
          //医師の場合しか診療終了理由マスタと処方薬の情報を取れない
          if (isDoctorInFunc) {
            dispatch(getMedicineList(auth));
            dispatch(getWithoutPrescribingReason(auth));
            //診療中一覧の診療情報を取得
            if ((patientNumberOfDoctor <= 0 || patientNumberOfDoctor === undefined) && !fetchingDiagnosesFlagA) {
              dispatch(updateFetchDiagnosesFlagB(true));
              dispatch(setAllowClick(true));
              await dispatch(getDiagnosisListsOfDoctor(auth));
              dispatch(updateFetchDiagnosesFlagB(false));
            }
          }
          if (isManagerInFunc) {
            if (doctors.length <= 0) {
              const doctorMaster = await dispatch(getDoctors(auth));
              if (doctorMaster) {
                if ((patientNumberOfManager <= 0 || patientNumberOfManager === undefined) && !fetchingDiagnosesFlagA) {
                  dispatch(updateFetchDiagnosesFlagB(true));
                  dispatch(setAllowClick(true));
                  await dispatch(getDiagnosisListsOfManager(auth, doctorMaster, doctorMaster[0].doctorPersonalACSId));
                  dispatch(updateFetchDiagnosesFlagB(false));
                }
              }
            } else {
              if ((patientNumberOfManager <= 0 || patientNumberOfManager === undefined) && !fetchingDiagnosesFlagA) {
                dispatch(updateFetchDiagnosesFlagB(true));
                dispatch(setAllowClick(true));
                await dispatch(getDiagnosisListsOfManager(auth, doctors, doctors[0].doctorPersonalACSId));
                dispatch(updateFetchDiagnosesFlagB(false));
              }
            }
          }
          if (isDeliverInFunc) {
            navigate("/404");
          }
        }
        //サイトポリシーページへアクセス
        if (pageTab === 2) {
          dispatch(setAllowClick(true));
        }
        //「診療前一覧」ページへアクセス
        if (pageTab === 3) {
          if (!isDeliverInFunc) {
            //パラメータの検証
            if (searchParams.pageNo && (isNaN(parseInt(searchParams.pageNo)) || parseInt(searchParams.pageNo) <= 0)) {
              navigate("/404");
              return
            }
            if (searchParams.surname) {
              const para = ((searchParams.surname ?? '') + ' ' + (searchParams.givenName ?? '')).trim()?.split(/\s/)?.filter((v: any) => v) ?? [];
              if (para.length < 1 || para.length > 2) {
                navigate("/404");
                return
              }
            }
            await dispatch(updateBeforeLoading(true));
            //診療前一覧の診療情報を取得
            if (!searchParams.pageNo && !searchParams.surname) {
              dispatch(getBeforeDiagnoses(auth, 1, 20, navigate));
            }
            if (searchParams.pageNo && !searchParams.surname) {
              dispatch(getBeforeDiagnoses(auth, parseInt(searchParams.pageNo), 20, navigate));
            }
            if (searchParams.surname) {
              dispatch(searchBeforeDiagnosis(auth, searchParams.surname, searchParams.givenName));
            }
            //診療中一覧の診療情報を取得
            if (isDoctorInFunc) {
              if ((patientNumberOfDoctor <= 0 || patientNumberOfDoctor === undefined) && !fetchingDiagnosesFlagB) {
                dispatch(updateFetchDiagnosesFlagA(true));
                dispatch(setAllowClick(true));
                await dispatch(getDiagnosisListsOfDoctor(auth));
                dispatch(updateFetchDiagnosesFlagA(false));
              }
            }
            if (isManagerInFunc) {
              if (doctors.length <= 0) {
                const doctorMaster = await dispatch(getDoctors(auth));
                if (doctorMaster) {
                  if ((patientNumberOfManager <= 0 || patientNumberOfManager === undefined) && !fetchingDiagnosesFlagB) {
                    dispatch(updateFetchDiagnosesFlagA(true));
                    dispatch(setAllowClick(true));
                    await dispatch(getDiagnosisListsOfManager(auth, doctorMaster, doctorMaster[0].doctorPersonalACSId));
                    dispatch(updateFetchDiagnosesFlagA(false));
                  }
                }
              }
            }
          } else {
            dispatch(updateBeforeLoading(false));
          }
        }
        //「配送管理」ページへアクセス
        if (pageTab === 4) {
          if (isDoctorInFunc) {
            navigate("/404");
          } else {
            dispatch(setAllowClick(true));
            dispatch(getDownloadPossibility(auth));
            dispatch(updateDeliveryAuthLoading(false));
          }
        }
        //「お知らせ」ページへアクセス
        if (pageTab === 5) {
          dispatch(setAllowClick(true));
          dispatch(updateNoticeAuthLoading(false));
        }
      }
      return loginableFlgInFunc;
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}