import '../style/footer.scss';
import { RELEASE_VERSION } from '../config/constant';

function Footer() {
  return (
    <footer>
      <div className="footer-top">
        <a
          id='privacy_policy'
          className="link-text-gray"
          href="https://www.mti.co.jp/?page_id=17"
          target="_blank"
          rel="noopener noreferrer"
        >個人情報保護方針
        </a>
      </div>
      <div id='version' className="footer-bottom">version {RELEASE_VERSION}</div>
    </footer>
  )
}

export default Footer;