// データフォーマット:					
const defaultState = ({
  //終了状態診療情報
  completedDiagnosisInfo: {},
  //ビデオ診療情報
  videoDiagnosisInfo: {},
  //診療終了ローディング
  completedLoading: true,
  //検索ローディング
  searchHistoryLoading: false,
  //診療終了診療室の判断
  isHistoryClinicRoom: false,
  //診療終了一覧リスト
  completedDiagnoses: [],
  //ページ
  pages: {},
  //検索モードかとうか
  isSearchHistoryMode: false,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'set_completed_diagnosis_info':
      return { ...state, completedDiagnosisInfo: action.info }
    case 'set_completed_video_diagnosis_info':
      return { ...state, videoDiagnosisInfo: action.info }
    case 'update_completed_loading':
      return { ...state, completedLoading: action.flag }
    case 'update_search_history_loading':
      return { ...state, searchHistoryLoading: action.loading }
    case 'set_history_clinic_room_flag':
      return { ...state, isHistoryClinicRoom: action.flag }
    case 'set_completed_diagnoses':
      return { ...state, completedDiagnoses: action.list }
    case 'update_completed_pages':
      return { ...state, pages: action.page }
    case 'update_completed_pages_now':
      return { ...state, pages: { ...state.pages, now: action.now } }
    case 'update_search_history_mode':
      return { ...state, isSearchHistoryMode: action.flag }
    default:
      return state;
  }
}

export default variable;