import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import {
  updateEndDiagnosisModal,
  updateConfirmMedcineModal,
  updateEndDiagnosisErrCode,
  updateEndDiagnosisErrMessage,
  updateEndDiagnosisLoading,
  updateWithoutReasonModal
} from './store/action';
import './style/prescription.scss';
import ConfirmMedicineModal from '../prescription/ConfirmMedicineModal';
import ConfirmWithoutPrescribingModal from "./ConfirmWithoutPrescribingModal";
import { post } from '../../../api/FetchAPI';
import { END_DIAGNOSIS_WITHOUT_PRESCRIBING, END_DIAGNOSIS_SIXSUMMARYPLAN, END_DIAGNOSIS } from '../../../api/Api';
import {
  ERR_CODE_A003, ERR_CODE_C002, ERR_CODE_A017, ERR_CODE_A018, ERR_CODE_A019, ERR_MSG_001, ERR_MSG_006, ERR_MSG_007,
  ERR_MSG_008, ERR_MSG_009, PROPOSE_PRICE_PLAN_001, PROPOSE_PRICE_PLAN_002, PROPOSE_SHEET_001, PROPOSE_SHEET_002,
  SENTRT_CONTEXT_ID
} from "../../../config/constant";
import { setSelectedDiagnosisCode, updateTakeOnPatientNumber, setDiagnosisPatientInfoListEndDiagnosis } from '../chatList/store/action';
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';

function PrescriptionModal() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const medicines = useSelector<any, any>((state) => state.clinic.medicines);
  const proposeMedicineProductName = useSelector<any, any>((state) => state.clinic.proposeMedicineProductName);
  const proposePricePlan = useSelector<any, any>((state) => state.clinic.proposePricePlan);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const showConfirmMedicineModal = useSelector<any, any>((state) => state.prescription.showConfirmMedicineModal);
  const showWithoutPrescribingReasonsModal = useSelector<any, any>((state) => state.prescription.showWithoutPrescribingReasonsModal);
  const endDiagnosisLoading = useSelector<any, any>((state) => state.prescription.endDiagnosisLoading);

  const [medicineRadio, setMedicineRadio] = useState<any>();
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();

  //「処方・診療終了」ダイアログをクロース
  function closeEndDiagnosisModal() {
    //sentry log
    sendSentryLog('処方&診療終了ダイアログを閉じる', 'closeEndDiagnosisDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateEndDiagnosisModal(false));
  }

  //処方薬提案ラジオを変更
  function changeMedicineRadio(e: any) {
    setMedicineRadio(JSON.parse(e.target.value));
  }

  //「処方内容の確認」ダイアログを開く
  function openConfirmMedicineModal() {
    //sentry log
    const sentryContextEndDiagnosis: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        medicineProductName: medicineRadio.medicineProductName,
        sheetCount: medicineRadio.sheetCount
      }
    };
    sendSentryLog('「処方確定する」ボタンを押す', 'openWithPrescribeDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
      , [sentryContextEndDiagnosis]
    );
    dispatch(updateConfirmMedcineModal(true));
  }

  //「処方せずに診療終了」ダイアログを開く
  function openWithoutPrescribingReasonsModal() {
    //sentry log
    sendSentryLog('「処方せず診療終了」ボタンを押す', 'openWithoutPrescribeDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateWithoutReasonModal(true));
  }

  //診療終了のコメントを更新
  function handleInputComment(event: any) {
    setComment(event.target.value);
  }

  //診療終了対象をDBに更新
  const endDiagnosis = async (reasonId: number) => {
    try {
      dispatch(updateEndDiagnosisLoading(true));
      const param = {
        diagnosisCode: selectedDiagnosisCode,
        comments: comment,
      } as any;
      if (showConfirmMedicineModal) {
        //sentry log
        const sentryContextEndDiagnosis: sentryContext = {
          contextID: SENTRT_CONTEXT_ID,
          info: {
            medicineProductName: medicineRadio.medicineProductName,
            sheetCount: medicineRadio.sheetCount
          }
        };
        sendSentryLog('処方確定して診療終了する', 'execEndWithPrescribe', 'log',
          { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextEndDiagnosis]
        );
        Object.assign(param, { medicineId: medicineRadio.medicineMasterId });
      } else {
        //sentry log
        const sentryContextEndDiagnosis: sentryContext = {
          contextID: SENTRT_CONTEXT_ID,
          info: {
            reasonId: reasonId.toString(),
          }
        };
        sendSentryLog('処方せず診療を終了する', 'execEndWithoutPrescribe', 'log',
          { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextEndDiagnosis]
        );
        Object.assign(param, { reasonId: reasonId });
      }
      let path = '';
      if (param.reasonId) {
        path = END_DIAGNOSIS_WITHOUT_PRESCRIBING;
      } else if (medicineRadio.planMasterId === 2) {
        path = END_DIAGNOSIS_SIXSUMMARYPLAN;
      } else {
        path = END_DIAGNOSIS;
      }
      const response = await post(path, param, msalToken) as any;
      if (response.data.errorCode) {
        //sentry log
        const sentryContextApi: sentryContext = {
          contextID: SENTRT_CONTEXT_ID,
          info: {
            api: path,
            errorCode: response.data.errorCode
          }
        };
        sendSentryLog('Api Error', 'apiError', 'error',
          { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextApi]
        );
        dispatch(updateEndDiagnosisErrCode(response.data.errorCode));
        if (response.data.errorCode === ERR_CODE_A003) {
          dispatch(updateEndDiagnosisErrMessage(ERR_MSG_006));
        } else if (response.data.errorCode === ERR_CODE_C002) {
          dispatch(updateEndDiagnosisErrMessage(ERR_MSG_007));
        } else if (response.data.errorCode === ERR_CODE_A017) {
          const errMsg = ERR_MSG_008 + response.data.errorDescription + "\n診療コード:" + selectedDiagnosisCode;
          dispatch(updateEndDiagnosisErrMessage(errMsg));
        } else if (response.data.errorCode === ERR_CODE_A018 || response.data.errorCode === ERR_CODE_A019) {
          const errMsg = ERR_MSG_009 + "\n診療コード:" + selectedDiagnosisCode;
          dispatch(updateEndDiagnosisErrMessage(errMsg));
        }
        else {
          dispatch(updateEndDiagnosisErrMessage(ERR_MSG_001));
        }
      }
      if (!response.data.errorCode || response.data.errorCode === ERR_CODE_A017 || response.data.errorCode === ERR_CODE_A018 || response.data.errorCode === ERR_CODE_A019) {
        //診療終了ダイアログをクロース
        if (showConfirmMedicineModal) {
          dispatch(updateConfirmMedcineModal(false));
        } else {
          dispatch(updateWithoutReasonModal(false));
        }
        //患者選択の状態を消去
        dispatch(setSelectedDiagnosisCode(''));
        //診療終了の患者を診療リストから削除
        dispatch(setDiagnosisPatientInfoListEndDiagnosis(selectedDiagnosisCode));
        //医師が担当する患者数
        dispatch(updateTakeOnPatientNumber(-1, false, ''));
      }
      dispatch(updateEndDiagnosisLoading(false));
    } catch (e: any) {
      //sentry log
      const sentryContextCatch: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          apiMethod: "endDiagnosis()",
          errorCode: e.errorCode ?? e.code
        }
      };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContextCatch]
      );
      dispatch(updateEndDiagnosisErrCode(e.errorCode ?? e.code));
      dispatch(updateEndDiagnosisErrMessage(ERR_MSG_001));
      dispatch(updateEndDiagnosisLoading(false));
    }
  }

  return (
    <>
      {endDiagnosisLoading ?
        <div className='login-loading-background'>
          <LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} />
        </div>
        : ''}
      {showConfirmMedicineModal ? <ConfirmMedicineModal medicine={medicineRadio} comment={comment} endDiagnosis={endDiagnosis} /> : ''}
      {showWithoutPrescribingReasonsModal ? <ConfirmWithoutPrescribingModal comment={comment} endDiagnosis={endDiagnosis} /> : ''}
      <div id="prescription_dialog" className="shoho_dialog" style={{ display: 'block', zIndex: '2' }}>
        <div id="prescription_toptit" className="toptit">
          <div id="prescription_dialog_close" className="close" onClick={closeEndDiagnosisModal}></div>
          <span>処方・診療終了</span>
        </div>
        <div id="prescription_medicine" className="sc">
          <div>
            {medicines.map((medicinePlan: any, index: number) => {
              return (
                <section className="has-margin-bottom-1" key={index}>
                  <div className={medicinePlan.planMasterId === 2 ? 'medicine-plan color-blue' : 'medicine-plan'}>
                    {medicinePlan.planName}
                  </div>
                  {medicinePlan.medicines.map((medicine: any) => {
                    let proposePricePlanStr = "";
                    if (medicine.planMasterId === 1) {
                      proposePricePlanStr = PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001;
                    } else {
                      proposePricePlanStr = PROPOSE_PRICE_PLAN_002 + " " + PROPOSE_SHEET_002;
                    }
                    return (
                      <div
                        className={proposePricePlanStr === proposePricePlan
                          && medicine.medicineProductName === proposeMedicineProductName
                          ? 'field' : 'field radio_disabled'}
                        key={medicinePlan.planMasterId + medicine.medicineProductName}
                      >
                        <div className='choice'>
                          <label className="b-radio radio">
                            <input
                              name="medicine"
                              type="radio"
                              value={JSON.stringify(medicine)}
                              onChange={(e) => { changeMedicineRadio(e) }}
                            />
                            <span className="check" ></span>
                            <span className="control-label">
                              <span className={medicinePlan.planMasterId === 2 ? 'medicine-name color-blue' : 'medicine-name'}>
                                {medicine.medicineProductName}
                              </span>
                            </span>
                          </label>
                          <div className={medicinePlan.planMasterId === 2 ? 'sheet color-blue' : 'sheet'}>
                            {medicine.sheetCount}シート×{medicine.times}回
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </section>
              )
            })}
          </div>
          <div className="input-comment-container" style={{ padding: '0' }}>
            <div className="control is-clearfix">
              <textarea
                id="prescription_comment_input"
                maxLength={500}
                placeholder="コメントを入力"
                className="textarea input-comment"
                value={comment}
                onChange={handleInputComment}
              >
              </textarea>
              <small className="help counter is-invisible"> 0 / 500 </small>
            </div>
          </div>
        </div>

        <div className="fbtm">
          <a
            id="end_without_prescribe_a"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              openWithoutPrescribingReasonsModal();
            }}>
            処方せずに診療終了
          </a>
          <button
            id="end_with_prescribe_btn"
            disabled={!medicineRadio || isManager}
            className="button confirm-button"
            onClick={openConfirmMedicineModal}
          >
            処方確定する
          </button>
        </div>
      </div>
    </>
  )
}

export default PrescriptionModal;