// データフォーマット:					
const defaultState = ({
  //クエリ結果
  tableProps: {
    data: [],
    isLoading: false,
    currentPage: 1,
    totalPages: 1,
    perPage: 50,
    sortField: 'noticeID',
    sortOrder: 'desc',
    defaultSortOrder: 'asc',
    totalRecords: 0,
    totalDisplayRecords: 0,
    pageFirstNum: 0,
    pageLastNum: 0,
  },
  noticeErrorCode: '',
  noticeErrorMsg: '',
  notice: {
    noticeNo: '',
    noticeType: '',
    noticeTitle: '',
    noticeContent: '',
    internalManageName: '',
    noticeDisplayOrder: null,
    bannerUrl: '',
    noticeDisplayStartTime: null,
    noticeDisplayEndTime: null,
    isDeleted: false,
    specificUserFileUrl: '',
  },
  //おしらせローディング
  noticeLoading: false,
  publishNotices: [],
  //おしらせ認証ローディング
  noticeAuthLoading: true,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'update_notice_error_code':
      return { ...state, noticeErrorCode: action.code }
    case 'update_notice_error_msg':
      return { ...state, noticeErrorMsg: action.message }
    case 'set_table_data':
      return { ...state, tableProps: { ...state.tableProps, data: action.data } }
    case 'update_table_loading':
      return { ...state, tableProps: { ...state.tableProps, isLoading: action.flag } }
    case 'set_table_current_page':
      return { ...state, tableProps: { ...state.tableProps, currentPage: action.page } }
    case 'set_table_total_pages':
      return { ...state, tableProps: { ...state.tableProps, totalPages: action.page } }
    case 'set_table_sort_field':
      return { ...state, tableProps: { ...state.tableProps, sortField: action.field } }
    case 'set_table_sort_order':
      return { ...state, tableProps: { ...state.tableProps, sortOrder: action.order } }
    case 'set_table_total_records':
      return { ...state, tableProps: { ...state.tableProps, totalRecords: action.records } }
    case 'set_table_total_display_records':
      return { ...state, tableProps: { ...state.tableProps, totalDisplayRecords: action.records } }
    case 'set_table_page_first_num':
      return { ...state, tableProps: { ...state.tableProps, pageFirstNum: action.num } }
    case 'set_table_page_last_num':
      return { ...state, tableProps: { ...state.tableProps, pageLastNum: action.num } }
    case 'set_notice':
      return { ...state, notice: action.notice }
    case 'set_internal_manage_name':
      return { ...state, notice: { ...state.notice, internalManageName: action.name } }
    case 'set_title':
      return { ...state, notice: { ...state.notice, noticeTitle: action.title } }
    case 'set_content':
      return { ...state, notice: { ...state.notice, noticeContent: action.content } }
    case 'set_type':
      return { ...state, notice: { ...state.notice, noticeType: action.value } }
    case 'set_display_order':
      return { ...state, notice: { ...state.notice, noticeDisplayOrder: action.order } }
    case 'set_banner_url':
      return { ...state, notice: { ...state.notice, bannerUrl: action.url } }
    case 'set_display_start_time':
      return { ...state, notice: { ...state.notice, noticeDisplayStartTime: action.time } }
    case 'set_display_end_time':
      return { ...state, notice: { ...state.notice, noticeDisplayEndTime: action.time } }
    case 'set_delete':
      return { ...state, notice: { ...state.notice, isDeleted: action.flag } }
    case 'set_specific_user_file_url':
      return { ...state, notice: { ...state.notice, specificUserFileUrl: action.url } }
    case 'update_notice_loading':
      return { ...state, noticeLoading: action.flag }
    case 'set_publish_notices':
      return { ...state, publishNotices: action.notices }
    case 'update_notice_auth_loading':
      return { ...state, noticeAuthLoading: action.flag }
    default:
      return state;
  }
}

export default variable;