import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInternalManageName, setTitle, setContent, setType, setDisplayOrder,
  setDisplayStartTime, setDisplayEndTime, setDelete, setSpecificUserFileUrl
} from "./store/action";

function InputNoticeForm(props: any) {
  const notice = useSelector<any, any>((state) => state.notice.notice);

  const dispatch = useDispatch() as any;

  //編集モードの場合はお知らせ番号
  const id = useMemo(() => {
    if (props.isNew) return ''
    return notice.noticeNo
  }, [props.isNew, notice.noticeNo]);

  //特定ユーザタイプが選択されていますか
  const isSpecificUserTypeSelected = useMemo(() => {
    return notice.noticeType === '1'
  }, [notice.noticeType]);

  //特定ユーザデータのCSVファイルのアップロードステータス
  const userCSVUploadingStatus = useMemo(() => {
    return notice.specificUserFileUrl
      ? 'アップロード済'
      : 'アップロードなし'
  }, [notice.specificUserFileUrl]);

  //特定ユーザデータがアップロードされているかどうか
  const isSpecificUserFileUrlUploaded = useMemo(() => {
    return !!notice.specificUserFileUrl
  }, [notice.specificUserFileUrl]);

  const isValidDisplayOrder = useMemo(() => {
    //0または正の数字のみ有効。-0や-1はエラーとなるため、バリデーションをかけています。
    return notice.noticeDisplayOrder !== '-0' && notice.noticeDisplayOrder >= 0
  }, [notice.noticeDisplayOrder]);

  return (
    <div style={{ position: 'relative' }}>
      {!props.isNew ?
        <div id="noticeDetailId" className="field is-horizontal field-bottom">
          <div className="field-label is-normal">
            <label className="label">お知らせ番号</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="is-aligned-with-label">{id}</p>
            </div>
          </div>
        </div>
        : ''}
      <div id="noticeDetailType" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">お知らせタイプ</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control is-aligned-with-label">
              <label className="new_radio">
                <input
                  checked={notice.noticeType === "0"}
                  type="radio"
                  name="type"
                  value="0"
                  onChange={(e: any) => { dispatch(setType(e.target.value)); dispatch(setSpecificUserFileUrl("")); }}
                />
                &nbsp;通常お知らせ&nbsp;
              </label>
              <label className="new_radio">
                <input
                  checked={notice.noticeType === "1"}
                  type="radio"
                  name="type"
                  value="1"
                  onChange={(e: any) => { dispatch(setType(e.target.value)) }}
                />
                &nbsp;特定ユーザお知らせ
              </label>
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailMngName" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">管理名称</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                value={notice.internalManageName}
                type="text"
                className="input"
                onChange={(e: any) => dispatch(setInternalManageName(e.target.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailTitle" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">タイトル</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                value={notice.noticeTitle}
                type="text"
                className="input"
                onChange={(e: any) => dispatch(setTitle(e.target.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailContent" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">本文</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <textarea
                value={notice.noticeContent}
                className="textarea"
                onChange={(e: any) => dispatch(setContent(e.target.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailUserData" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">特定ユーザデータ</label>
        </div>
        <div className="field-body">
          <label id="noticeUserDataUpload" className="file-label">
            <input
              className="file-input"
              type="file"
              name="user"
              accept=".csv"
              disabled={!isSpecificUserTypeSelected}
              onChange={(e: any) => { props.uploadUserCSV(e.target.files[0]); }}
            />
            <span className={!isSpecificUserTypeSelected ? "file-cta file-upload-button disabled" : "file-cta file-upload-button"}>
              <span className="file-label">アップロード</span>
            </span>
          </label>
          <button
            id="noticeUserDataDownload"
            type="button"
            className="button file-download-button"
            disabled={!isSpecificUserFileUrlUploaded}
            onClick={props.downloadUserCSV}
          >
            ダウンロード
          </button>
          <p id="noticeUserDataText" className="csv-uploading-status is-aligned-with-label">
            {userCSVUploadingStatus}
          </p>
        </div>
      </div>
      <div id="noticeDetailBanner" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">バナー画像</label>
        </div>
        <div className="field-body banner-field">
          <label id="noticeBannerUpload" className="file-label banner-file-label">
            <input
              className="file-input"
              type="file"
              name="user"
              accept=".gif,.png,.jpeg,.jpg"
              onChange={(e: any) => { props.uploadBanner(e.target.files[0]); e.target.value = '' }}
            />
            <span className="file-cta file-upload-button">
              <span className="file-label">アップロード</span>
            </span>
          </label>
          <div id="noticeBannerText" className="uploaded-image-name is-aligned-with-label">
            {notice.bannerUrl}
          </div>
        </div>
      </div>
      <div id="noticeDetailDisOrder" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">掲載順</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                className={isValidDisplayOrder ? 'input' : 'input is-invalid'}
                type="number"
                step="0.1"
                value={notice.noticeDisplayOrder ?? ''}
                onChange={(e: any) => { dispatch(setDisplayOrder(e.target.value)) }}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailDisStartTime" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">表示開始日時</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                className="input"
                type="datetime-local"
                step="300"
                value={notice.noticeDisplayStartTime || ''}
                onChange={(e: any) => dispatch(setDisplayStartTime(e.target.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailDisEndTime" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">表示終了日時</label>
        </div>
        <div className="field-body enddate-field-body">
          <p className="enddata-help-text">
            ※公開終了時間が12:00まで(11:59:59)の場合12:00と入力してください。
          </p>
          <div className="field">
            <p className="control">
              <input
                className="input"
                type="datetime-local"
                step="300"
                value={notice.noticeDisplayEndTime || ''}
                onChange={(e: any) => dispatch(setDisplayEndTime(e.target.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <div id="noticeDetailDeleted" className="field is-horizontal field-bottom">
        <div className="field-label is-normal">
          <label className="label">有効／無効</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control is-aligned-with-label">
              <label className="new_radio">
                <input
                  checked={notice.isDeleted === false}
                  type="radio"
                  name="isDeleted"
                  value="false"
                  onChange={() => dispatch(setDelete(false))}
                />
                &nbsp;有効 &nbsp;
              </label>
              <label className="new_radio">
                <input
                  checked={notice.isDeleted === true}
                  type="radio"
                  name="isDeleted"
                  value="true"
                  onChange={() => dispatch(setDelete(true))}
                />
                &nbsp;無効
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputNoticeForm;