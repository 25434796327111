export const maxPage = (assignedCount: any, unAssignedCount: any, baseListCount: number) => {
  return Math.ceil(
    (parseInt(assignedCount) +
      parseInt(unAssignedCount)) /
    baseListCount);
}

export const pages = (pageNumber: number, maxPage: number) => {
  const now = pageNumber
  const max = maxPage
  // 現状のページ数が最大超えてた場合に最大値に設定
  if (now > max) {
    return {}
  }

  return {
    now,
    max
  }
}