import { LoadingOutlined } from '@ant-design/icons';

function Loading() {
  return (
    <div className="center_posi loading_center_posi" style={{ position: 'relative' }}>
      <LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} /><br />
      <div>画面を読み込みしています。しばらくお待ちください。</div>
    </div>
  )
}

export default Loading;