// 以下、外部用reduxのデータ処理
export const setVideoGroupId = (groupId: string) => ({
  type: 'set_video_group_id',
  groupId
})

export const setVideoToken = (token: string) => ({
  type: 'set_video_token',
  token
})

export const setVideoExpiredOn = (expiredOn: string) => ({
  type: 'set_video_expired_on',
  expiredOn
})

export const setFirstStartVideoFlag = (flag: boolean) => ({
  type: 'set_first_start_video_flag',
  flag
})

export const setVideoErrCode = (errorCode: any) => ({
  type: 'set_video_err_code',
  errorCode
})

export const setIssueNewLink = (flag: boolean) => ({
  type: 'set_issue_new_link_flag',
  flag
})

export const setGetAcsVideoInfoTime = (time: Date) => ({
  type: 'set_get_acs_video_info_time',
  time
})