import { ChatMessageType } from "@azure/communication-chat";
import moment from "moment";
import { VIDEO_DOMAIN_URL } from "../config";
import { SENTRT_CONTEXT_ID, VIDEO_MESSAGE_001, VIDEO_MESSAGE_002, VIDEO_MESSAGE_003, VIDEO_MESSAGE_004, VIDEO_MESSAGE_005, VIDEO_MESSAGE_006, VIDEO_MESSAGE_007 } from "../config/constant";
import { sendSentryLog, sentryContext } from "./SentryFuc";
import { START_VIDEO } from "../api/Api";
import { post } from "../api/FetchAPI";
import { setFirstStartVideoFlag, setVideoGroupId, setVideoToken, setVideoExpiredOn, setVideoErrCode } from "../components/diagnosing/video/store/action";

//ビデオリンクを送信する
export const sendVideoUrl = async (displayName: string, videoToken: string, diagnosisCode: string, diagnosisScheduledStartDatetimeJst: string,
    personalUid: string, sentryContextVideo: any, chatThreadClient: any) => {
  const videoUrl = VIDEO_DOMAIN_URL + "?token=" + videoToken;
  const sendMessageRequest = {
    content: VIDEO_MESSAGE_001 + moment(new Date(diagnosisScheduledStartDatetimeJst)).format("YYYY年MM月DD日 HH:mm") + VIDEO_MESSAGE_002 + "\n\n" + videoUrl + "\n\n" + VIDEO_MESSAGE_003 + "\n\n" + VIDEO_MESSAGE_004 + "\n" + VIDEO_MESSAGE_005 + "\n" + VIDEO_MESSAGE_006 + "\n" + VIDEO_MESSAGE_007,
  };
  let msgType: ChatMessageType = "text";
  let sendMessageOptions = {
    senderDisplayName: displayName,
    type: msgType,
    metadata: {
      linkURL: videoUrl + ';' + VIDEO_MESSAGE_007
    }
  };
  //sentry log
  sendSentryLog('ビデオリンクのメッセージ送信処理が走る', 'execSendVideoLinkMsg', 'log',
    { 'event_name': 'start_video', 'patientUid': personalUid, 'diagnosisCode': diagnosisCode }
    , [sentryContextVideo]
  );
  await chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
}

//ビデオ診療開始
export const startVideo = (auth: string, diagnosisCode: string) => {
  return async (dispatch: any) => {
    const sentryContextApi: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        api: START_VIDEO
      }
    };
    try {
      const data = {
        diagnosisCode: diagnosisCode
      };
      const response = await post(START_VIDEO, data, auth) as any;
      if (response.data.errorCode === null) {
        dispatch(setFirstStartVideoFlag(false));
        dispatch(setVideoGroupId(response.data.videoId));
        dispatch(setVideoToken(response.data.temporaryToken));
        dispatch(setVideoExpiredOn(response.data.tempTokenExpiredDTJst));
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error',
          { 'event_name': 'api_error', 'diagnosisCode': diagnosisCode }
          , [sentryContextApiError]
        );
        dispatch(setVideoErrCode(response.data.errorCode));
      }
      return response;
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.code ?? 'START_VIDEO_ERR' } };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': diagnosisCode }
        , [sentryContextApiError]
      );
      dispatch(setVideoErrCode(e.code));
      return e.code ? e.code : 'START_VIDEO_ERR';
    }
  }
}