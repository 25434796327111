import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Icon } from '@iconify/react';
import "./style/history.scss";
import { getCompletedDiagnoses, searchCompletedDiagnoses, updateSearchHistoryLoading } from "./store/action";
import { updateSearchErrorModal } from "../beforeDiagnose/store/action";
import SearchErrorModal from "../SearchErrorModal";
import DiagnosisHistoryCardFrame from "./DiagnosisHistoryCardFrame";
import Pagination from "../Pagination";
import Loading from "../Loading";

function History(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const showSearchErrorModal = useSelector<any, any>((state) => state.before.showSearchErrorModal);
  const completedDiagnoses = useSelector<any, any>((state) => state.history.completedDiagnoses);
  const pages = useSelector<any, any>((state) => state.history.pages);
  const isSearchHistoryMode = useSelector<any, any>((state) => state.history.isSearchHistoryMode);
  const searchHistoryLoading = useSelector<any, any>((state) => state.history.searchHistoryLoading);

  //検索フィールド
  const [searchName, setSearchName] = useState("");
  //検索中かどうかフラグ
  const [isSearched, setIsSearched] = useState(false);
  //姓
  let surname = "";
  //名
  let givenName = "";

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  //検索フィールドstateの//
  function handleSearchName(event: any) {
    setSearchName(event.target.value);
  }

  //姓と名を生成する
  function generateLastAndFirstName(val: string) {
    const names = val.split(/\s/).filter((v: string) => v);
    return names;
  }

  //「検索」ボタンのクリック事件
  function searchDiagnosis() {
    const names = generateLastAndFirstName(searchName);
    if (names.length < 1 || names.length > 2) {
      dispatch(updateSearchErrorModal(true));
      return
    }
    surname = names[0];
    givenName = names[1];
    //URLにページNoの取得
    const params = new URLSearchParams(window.location.search);
    const pageNo = params.get("page");
    if (pageNo) {
      if (names.length === 1) {
        window.history.replaceState('', '', '?page=' + pageNo + '&surname=' + surname);
      } else {
        window.history.replaceState('', '', '?page=' + pageNo + '&surname=' + surname + '&givenName=' + givenName);
      }
    } else {
      if (names.length === 1) {
        window.history.replaceState('', '', '?surname=' + surname);
      } else {
        window.history.replaceState('', '', '?surname=' + surname + '&givenName=' + givenName);
      }
    }
    dispatch(updateSearchHistoryLoading(true));
    dispatch(searchCompletedDiagnoses(msalToken, surname, givenName));
    setIsSearched(true);
  }

  //キーボードイベント
  function inputKeyUp(e: any) {
    if (e.keyCode === 13 && searchName) {
      searchDiagnosis();
    }
  }

  //検索ワードエラーダウンロードのクロース
  function closeSearchErrorModal() {
    dispatch(updateSearchErrorModal(false));
  }

  function clearSearchContent() {
    dispatch(updateSearchHistoryLoading(true));
    setIsSearched(false);
    setSearchName("");
    navigate("/history");
    dispatch(getCompletedDiagnoses(msalToken, 1, navigate));
  }

  useEffect(() => {
    if (props.searchParams.surname) {
      setIsSearched(true);
      if (props.searchParams.givenName) {
        setSearchName(props.searchParams.surname + ' ' + props.searchParams.givenName);
      } else {
        setSearchName(props.searchParams.surname);
      }
    }
  }, [props.searchParams]);

  return (
    <>
      {showSearchErrorModal ? <SearchErrorModal close={closeSearchErrorModal} /> : ''}
      <section className="complete-diagnoses">
        <div className="complete-diagnoses-content">
          <div id="historyDiagContent" className="main-content">
            <div className="search-box">
              {isDoctor ?
                <div className="field is-grouped is-grouped-right">
                  <p className="control has-icons-right is-searched">
                    <input
                      id="searchHistoryDiagnosisInput"
                      className="input"
                      type="text"
                      placeholder="姓名で検索"
                      value={searchName}
                      onChange={handleSearchName}
                      onKeyUp={inputKeyUp}
                    />
                    {
                      isSearched ?
                        <span id="clearSearchIcon" className="icon is-right is-clickable">
                          <Icon icon="mdi:close" style={{ fontSize: '18px' }} onClick={clearSearchContent} />
                        </span>
                        : ''
                    }
                  </p>
                  <p className="control">
                    <button
                      id="searchHistoryDiagnosisBtn"
                      type="button"
                      className="button is-primary"
                      disabled={searchName ? false : true}
                      onClick={searchDiagnosis}
                    >
                      検索
                    </button>
                  </p>
                </div>
                : ''}
            </div>
            {searchHistoryLoading ? <Loading /> :
              <>
                {
                  completedDiagnoses.length > 0 ?
                    <div id="historyDiagnosisList" className="diagnosis-list" >
                      {completedDiagnoses.map((item: any) => {
                        return (
                          <DiagnosisHistoryCardFrame diagnosis={item} key={item.diagnosisCode} />
                        )
                      })}
                    </div>
                    : ''
                }
                {
                  completedDiagnoses.length <= 0 && isSearchHistoryMode ?
                    <div className="has-text-centered white">
                      <p id="searchNoResultTitle" className="has-margin-1 has-text-left has-padding-top-5">
                        検索結果はありません
                      </p>
                      <div id="searchNoResultContent" className="searchHelp has-margin-1">
                        <p>ヒント</p>
                        <ul>
                          <li>
                            姓名の間にスペースを入れて検索をするか、姓または名のみで検索してください
                          </li>
                          <li>姓名以外の情報（患者IDや記号）では検索できません</li>
                        </ul>
                      </div>
                    </div>
                    : ''
                }
                {
                  completedDiagnoses.length <= 0 && !isSearchHistoryMode ?
                    <div id="searchNoResultFooter" className="has-margin-1">
                      現在、一覧にデータがありません
                    </div>
                    : ''
                }
                {!isSearchHistoryMode && completedDiagnoses.length > 0 ? <Pagination pages={pages} pageTab={0} /> : ''}
              </>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default History;