import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { updateBeforePagesNow, getBeforeDiagnoses, updateBeforeLoading } from "./beforeDiagnose/store/action";
import { getCompletedDiagnoses, updateCompletedPagesNow, updateCompletedLoading } from "./history/store/action";
import "../style/pagination.scss";

function Pagination(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const searchBeforeCondition = useSelector<any, any>((state) => state.before.searchBeforeCondition);

  // 先頭ページ側の省略記号表示フラグ
  const [isFirstPageEllipsis, setIsFirstPageEllipsis] = useState(false);
  // 最終ページ側の省略記号表示フラグ
  const [isLastPageEllipsis, setIsLastPageEllipsis] = useState(false);
  // 開始ページ（ページ番号リスト）
  const [startPage, setStartPage] = useState(1);
  // 最終ページ（ページ番号リスト）
  const [endPage, setEndPage] = useState(props.pages.max);
  // 表示するページ番号の総件数
  const maxDisplayPageCount = 15;
  // 省略記号を表示するページ件数
  const ellipsisPageCount = 16;

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  function pageNumbers(start: number, end: number, step: number) {
    return Array.from(
      { length: (end - start) / step + 1 },
      (_, i) => start + i * step
    )
  }

  async function handlePageLeft() {
    window.history.replaceState('', '', '?page=' + (props.pages.now - 1));
    if (props.pageTab === 3) {
      dispatch(updateBeforeLoading(true));
      dispatch(updateBeforePagesNow(props.pages.now - 1));
      await dispatch(getBeforeDiagnoses(msalToken, props.pages.now - 1, searchBeforeCondition.baseListCount, navigate));
    } else {
      dispatch(updateCompletedLoading(true));
      dispatch(updateCompletedPagesNow(props.pages.now - 1));
      await dispatch(getCompletedDiagnoses(msalToken, props.pages.now - 1, navigate));
    }
  }

  async function handlePageRight() {
    window.history.replaceState('', '', '?page=' + (props.pages.now + 1));
    if (props.pageTab === 3) {
      dispatch(updateBeforeLoading(true));
      dispatch(updateBeforePagesNow(props.pages.now + 1));
      await dispatch(getBeforeDiagnoses(msalToken, props.pages.now + 1, searchBeforeCondition.baseListCount, navigate));
    } else {
      dispatch(updateCompletedLoading(true));
      dispatch(updateCompletedPagesNow(props.pages.now + 1));
      await dispatch(getCompletedDiagnoses(msalToken, props.pages.now + 1, navigate));
    }
  }

  async function handlePagination(i: number) {
    window.history.replaceState('', '', '?page=' + i);
    if (props.pageTab === 3) {
      dispatch(updateBeforeLoading(true));
      dispatch(updateBeforePagesNow(i));
      await dispatch(getBeforeDiagnoses(msalToken, i, searchBeforeCondition.baseListCount, navigate));
    } else {
      dispatch(updateCompletedLoading(true));
      dispatch(updateCompletedPagesNow(i));
      await dispatch(getCompletedDiagnoses(msalToken, i, navigate));
    }
  }

  useEffect(() => {
    const tempDisplayPageCount = Math.min(maxDisplayPageCount, props.pages.max);
    //省略記号表示カウント
    let tempEllipsisCount = 0;
    if (props.pages.max >= ellipsisPageCount) {
      //中心のページ番号の位置
      const basePos = Math.floor(tempDisplayPageCount / 2);
      //開始ページ（ページ番号リスト）
      const startPageTmp = Math.max(1, props.pages.now - (basePos - 1));
      setStartPage(startPageTmp);
      if (startPageTmp !== 1) {
        //開始ページ（ページ番号リスト）が 1ページ以外は省略記号有り
        setIsFirstPageEllipsis(true);
        ++tempEllipsisCount;
        if (startPageTmp === 2) {
          //開始ページ（ページ番号リスト）が 2ページの場合は特別処理（3ページを開始ページにする）
          setStartPage(startPageTmp + 1);
        }
      }
      //最終ページ（ページ番号リスト）
      const endPageTmp = Math.min(props.pages.max, startPageTmp + (tempDisplayPageCount - tempEllipsisCount - 1));
      setEndPage(endPageTmp);
      if (endPageTmp !== props.pages.max) {
        //最終ページがページ数以外の場合は省略記号を表示
        setIsLastPageEllipsis(true);
        ++tempEllipsisCount;
        setEndPage(endPageTmp - 1);
      }
      //最終ページが総ページ数と同値の場合は表示ページリストを調整
      if (endPageTmp === props.pages.max) {
        setEndPage(props.pages.max);
        setStartPage(endPageTmp - (tempDisplayPageCount - tempEllipsisCount - 1));
      }
    } else {
      //ページ総数が 15ページ以下の場合は 1ページ～ページ総数をそのまま表示
      setStartPage(1);
      setEndPage(Math.min(props.pages.max, tempDisplayPageCount));
    }
  }, [props.pages])

  return (
    <nav className="pagination is-centered" role="navigation" aria-label="pagination">
      <div className="pagination-grow"></div>
      <button
        disabled={props.pages.now === 1}
        className="pagination-previous"
        onClick={handlePageLeft}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <ul className="pagination-list">
        {isFirstPageEllipsis ?
          <>
            <li>
              <button
                className="pagination-link"
                aria-label="1ページ目へ"
                onClick={() => { handlePagination(1) }}
              >
                1
              </button>
            </li>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </>
          : ''}
        {pageNumbers(startPage, endPage, 1).map((i: number) => {
          return (
            <li key={i}>
              <button
                className={props.pages.now === i ? "pagination-link is-current " : "pagination-link"}
                aria-label={i + 'ページ目へ'}
                onClick={() => { handlePagination(i) }}
                disabled={props.pages.now === i}
              >
                {i}
              </button>
            </li>
          )
        })}
        {isLastPageEllipsis ?
          <>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            <li >
              <button
                className="pagination-link"
                aria-label={props.pages.max + 'ページ目へ'}
                onClick={() => { handlePagination(props.pages.max) }}
              >
                {props.pages.max}
              </button>
            </li>
          </>
          : ''}
      </ul>
      <button
        disabled={props.pages.max <= props.pages.now}
        className="pagination-next"
        onClick={handlePageRight}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <div className="pagination-grow-last"></div>
    </nav>
  )
}

export default Pagination;