import Footer from "./Footer";
import { ERR_MSG_003, ERR_MSG_004, TOP_MSG } from "../config/constant";

function ErrApiPage(props: any) {
  return (
    <>
      <section className="main-content columns">
        <div className="container column is-10" style={{ margin: '0px auto' }}>
          <div className="content" style={{ margin: '40px' }}>
            <h1 id="errMsg" className="has-margin-1" style={{ paddingTop: '0px' }}>
              {props.errMsg === null || props.errMsg === undefined ? ERR_MSG_003 : props.errMsg}
              {props.errCode ? <span><br />&#60;{ERR_MSG_004}{props.errCode}&#62;</span> : ''}
            </h1>
            <a id="toTop" href="/" className="has-margin-1 link-text-gray nuxt-link-active">
              {TOP_MSG}
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ErrApiPage;