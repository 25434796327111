import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./style/chatList.scss";
import ChatListItem from "./ChatListItem";
import { sortByMessageDate } from '../../../utils/SortList';
import {
  setDisplayPatientsLists,
  updateSearchFlag,
  updateSearchModal,
  setSelectedDiagnosisCode,
  updateSelectedDoctorAcsId,
  setDisplayPatientsListsOfManager,
  updateVideoStatus,
  setTakeOnPatientNumber,
  updateSearchRadioValue
} from './store/action';
import SearchModal from "./SearchModal";
import { sendSentryLog } from '../../../utils/SentryFuc';

function ChatList() {
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const diagnosisLists = useSelector<any, any>((state) => state.chat.diagnosisLists);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const showSearchModal = useSelector<any, any>((state) => state.chat.showSearchModal);
  const isSearching = useSelector<any, any>((state) => state.chat.isSearching);
  const searchRadioValue = useSelector<any, any>((state) => state.chat.searchRadioValue);
  const doctors = useSelector<any, any>((state) => state.chat.doctors);
  const selectedDoctorAcsId = useSelector<any, any>((state) => state.chat.selectedDoctorAcsId);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const takeOnPatientNumber = useSelector<any, any>((state) => state.chat.takeOnPatientNumber);
  const showVideoRelatedModalIndex = useSelector<any, any>((state) => state.clinic.showVideoRelatedModalIndex);

  //絞込結果
  const [noSearchResult, setNoSearchResult] = useState(false);
  //＜detail>エレメントが表示かどうか
  const [open, setOpen] = useState(false);
  //スクロールバーの位置
  const [scrollTop, setScrollTop] = useState(0);

  const firstUpdate = useRef(true);
  const leftListAreaRef = useRef<any>(null);
  const dispatch = useDispatch() as any;

  //管理監督者の場合は選択した医師のインデクス
  const idxOfDoctors = useMemo(() => {
    let idx = 0;
    doctors.forEach((item: any, index: number) => {
      if (item.doctorPersonalACSId === selectedDoctorAcsId) {
        idx = index;
      }
    });
    return idx;
  }, [doctors, selectedDoctorAcsId]);

  //「＞」クリックイベント
  function handleOpenSlideBtn() {
    leftListAreaRef.current.style.display = "block";
    const video_datetime_br = document.getElementById("video_datetime_br");
    const clinic_room = document.getElementById("clinic_room");
    if (video_datetime_br) {
      video_datetime_br.style.display = "block";
    }
    if (clinic_room) {
      clinic_room.style.width = 'calc(66.666% - 240px)';
    }
  }

  //「＜」クリックイベント
  function handleCloseSlideBtn() {
    leftListAreaRef.current.style.display = "none";
    const video_datetime_br = document.getElementById("video_datetime_br");
    const clinic_room = document.getElementById("clinic_room");
    if (video_datetime_br) {
      video_datetime_br.style.display = "none";
    }
    if (clinic_room) {
      clinic_room.style.width = 'calc(66.666% - 70px)';
    }
  }

  //チャットリスト一覧の高さを計算する
  function getSideListCSS() {
    if (isSearching && !isManager) {
      return { height: 'calc(100% - 127.5px)' };
    }
    if (!isSearching && !isManager) {
      return { height: 'calc(100% - 88.5px)' };
    }
    if (isSearching && isManager) {
      return { height: 'calc(100% - 184px)' };
    }
    if (!isSearching && isManager) {
      return { height: 'calc(100% - 145px)' };
    }
  }

  function handleSearchFlag() {
    sendSentryLog('絞り込みを解除する', 'cancelFilter', 'log', { 'event_name': 'filter_chat_list' });
    //「条件で絞込中」の非表示
    dispatch(updateSearchFlag(false));
    //フィルタ前のリストを表示
    if (isDoctor) {
      //医師の場合
      dispatch(setDisplayPatientsLists(diagnosisLists[0].patientInfoLists));
    }
    if (isManager) {
      //管理者の場合
      const patientInfoListsOfSelectedDoctor =
        diagnosisLists.filter((item: any) => {
          return item.doctorAcsId === selectedDoctorAcsId;
        })[0]?.patientInfoLists;
      dispatch(setDisplayPatientsListsOfManager('', patientInfoListsOfSelectedDoctor));
    }
    //患者選択の状態を消去
    dispatch(setSelectedDiagnosisCode(''));
    //ラジオ値を消去
    dispatch(updateSearchRadioValue(''));
  }

  function openSearchModal() {
    //sentry log
    sendSentryLog('絞り込みダイアログを開く', 'openFilterDialog', 'log', { 'event_name': 'filter_chat_list' });
    //絞込状態外絞込ダイアログを表示される
    if (!isSearching) {
      dispatch(updateSearchModal(true));
    }
  }

  //医師リストのクリックイベント
  function handleDoctor(doctorPersonalACSId: any) {
    //フィルタ状態の解除
    if (isSearching && doctorPersonalACSId !== selectedDoctorAcsId) {
      dispatch(updateSearchFlag(false));
    }
    if (doctorPersonalACSId !== selectedDoctorAcsId) {
      //選択した医師のACS IDの更新
      dispatch(updateSelectedDoctorAcsId(doctorPersonalACSId));
      if (diagnosisLists.length > 0) {
        //ディスプレー用患者リストの更新
        const patientInfoLists = diagnosisLists.filter((item: any) => {
          return item.doctorAcsId === doctorPersonalACSId;
        })[0]?.patientInfoLists;
        dispatch(setDisplayPatientsLists(patientInfoLists));
      }
      //患者選択の状態を消去
      dispatch(setSelectedDiagnosisCode(''));
      //選択した医師が担当する患者数はreducerに保存
      const patientNumberOfSelectedDoctorLen = diagnosisLists.filter((item: any) => {
        return item.doctorAcsId === doctorPersonalACSId
      })[0].patientInfoLists.length;
      dispatch(setTakeOnPatientNumber(patientNumberOfSelectedDoctorLen));
    }
  }

  //「﹀」ラベルのクリックイベント
  const onToggle = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    //未読また未返信絞込状態中に新着メッセージを受信したとき初期化患者リストによるディスプレー患者リストを更新する
    if (isSearching && searchRadioValue === "unread") {
      //医師の場合、未読の絞込状態で表示用患者リストの更新
      if (isDoctor) {
        let displayPatientsListsByUnread = [];
        displayPatientsListsByUnread = diagnosisLists[0].patientInfoLists.filter(
          (item: any) => { return item.chatInfo.hasUnread === true || selectedDiagnosisCode === item.diagnosisCode })
        dispatch(setDisplayPatientsLists(displayPatientsListsByUnread));
      }
    } else if (isSearching && searchRadioValue === "noReply") {
      //医師の場合、未返信の絞込状態で表示用患者リストの更新
      if (isDoctor) {
        let displayPatientsListsByNoReply = [];
        displayPatientsListsByNoReply = diagnosisLists[0].patientInfoLists.filter(
          (item: any) => { return item.chatInfo.hasNoReply === true || selectedDiagnosisCode === item.diagnosisCode })
        dispatch(setDisplayPatientsLists(displayPatientsListsByNoReply));
      }
    } else {
      if (diagnosisLists.length > 0 && displayPatientsLists.length > 0 && isDoctor) {
        let displayPatientsListsAfterSearch = JSON.parse(JSON.stringify(diagnosisLists[0].patientInfoLists));
        displayPatientsListsAfterSearch = displayPatientsListsAfterSearch.filter((item: any) => {
          return (displayPatientsLists.some((display: any) => display.diagnosisCode === item.diagnosisCode))
        })
        let aftSortList = sortByMessageDate(displayPatientsListsAfterSearch);
        dispatch(setDisplayPatientsLists(aftSortList));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisLists]);

  //ビデオ中かどうかを判断する
  useEffect(() => {
    if (showVideoRelatedModalIndex === 3) {
      dispatch(updateVideoStatus(true));
    } else {
      dispatch(updateVideoStatus(false));
    }
  }, [dispatch, showVideoRelatedModalIndex]);

  //sentry log
  useEffect(() => {
    sendSentryLog('診療中一覧画面を開く', 'openDiagnosisingPage', 'log', { 'event_name': 'open_diagnosising_page' });
  }, []);

  //sentry log
  useEffect(() => {
    if (isManager) {
      const selectedDoctorUid = doctors.filter((item: any) => {
        return item.doctorPersonalACSId === selectedDoctorAcsId
      })[0].doctorUid;
      sendSentryLog('医師選択', 'selectDoctor', 'log', { 'event_name': 'select_doctor', 'selectedDoctorUid': selectedDoctorUid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctorAcsId]);

  //スクロールバーの位置の設定
  function handleScroll() {
    let element = document.getElementById('dig_side_list');
    if (element !== undefined && element !== null) {
      let top = element.scrollTop;
      setScrollTop(top);
    }
  }

  //スクロールバーの位置をモニターする
  useEffect(() => {
    let element = document.getElementById('dig_side_list') as any;
    if (element !== undefined && element !== null) {
      element.addEventListener("scrollend", handleScroll);

      return () => {
        element.removeEventListener('scrollend', handleScroll);
      };
    }
  }, []);

  //指定した位置までスクロールする
  useLayoutEffect(() => {
    if (displayPatientsLists.length > 0) {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      }
      else {
        let element = document.getElementById('dig_side_list');
        if (element !== undefined && element !== null) {
          element.scrollTo(0, scrollTop);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop, JSON.stringify(displayPatientsLists)]);

  return (
    <>
      {showSearchModal ? <SearchModal setNoSearchResult={setNoSearchResult} /> : ''}
      <div
        id="openslide_btn"
        className="openslide_btn"
        onClick={handleOpenSlideBtn}
      ></div>
      <div
        id="left_list_area"
        className="left_list_area"
        ref={leftListAreaRef}>
        <div
          id="closeslide_btn"
          className="closeslide_btn"
          style={isManager ? { top: '68px' } : {}}
          onClick={handleCloseSlideBtn}
        >
        </div>
        <div className="fixed">
          {isManager ?
            <details id="select_doctor_detail" onClick={(e) => { onToggle(e) }} open={open}>
              <summary id="selected_doctor" className="user_open_btn">
                <div className="doctor_position">
                  選択中：{doctors[idxOfDoctors].doctorPersonalFamilyName} {doctors[idxOfDoctors].doctorPersonalFirstName}先生
                </div>
              </summary>
              <div className="user_list_wrap">
                <ul id="doctor_list" className="user_list">
                  {doctors.map((item: any) => {
                    return (
                      <li onClick={() => { handleDoctor(item.doctorPersonalACSId); setOpen(false); }} key={item.doctorPersonalACSId}>
                        {item.doctorPersonalFamilyName} {item.doctorPersonalFirstName}先生
                      </li>
                    )
                  })}
                </ul>
              </div>
            </details>
            : ''
          }
          {isSearching ?
            <div id="delete_sort" className="delete_sort">
              <p onClick={() => { handleSearchFlag() }}>条件で絞込中<span></span></p>
            </div>
            :
            ''}
          <div
            id="sort_open_btn"
            className="sort_open_btn"
            style={showVideoRelatedModalIndex === 3 ? { pointerEvents: 'none' } : {}}
            onClick={() => { openSearchModal() }}
          >
            条件で検索・絞込する
          </div>
        </div>
        <div id="patientListCount" className="number-area">
          <span className="dia-patient">診療中：
            <span className="patient-number">{takeOnPatientNumber}人</span>
          </span>
        </div>
        <div id="dig_side_list" className="name_boxes" style={getSideListCSS()}>
          {displayPatientsLists.length > 0 ?
            displayPatientsLists.map((item: any) => {
              return (
                <ChatListItem item={item} key={item.diagnosisCode} />
              )
            })
            : ''
          }
          {displayPatientsLists.length <= 0 && !noSearchResult ? '現在、診療中の患者はいません' : ''}
          {displayPatientsLists.length <= 0 && noSearchResult ? '検索結果はありません' : ''}
        </div>
      </div>
    </>
  );
}

export default ChatList;