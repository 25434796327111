import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { ENDPOINT } from '../config';
import { sortByMessageDate } from '../utils/SortList';
import HeaderNav from "./HeaderNav";
import ChatList from "./diagnosing/chatList/ChatList";
import Loading from "./Loading";
import DiagnosisModal from "./DiagnosisModal";
import HistoryClinicModal from "./history/HistoryClinicModal";
import History from "./history/History";
import ErrorModal from './ErrorModal';
import SitePolicy from "./SitePolicy";
import Login from "./login/Login";
import Notice from "./notice/Notice";
import BeforeDiagnoses from "./beforeDiagnose/BeforeDiagnoses";
import Deliveries from "./deliveries/Deliveries";
import { updateEndDiagnosisErrCode } from './diagnosing/prescription/store/action';
import {
  setDiagnosisList,
  setDiagnosisPatientInfoList,
  getDiagnosisByThreadIdOfDoctor,
  getDiagnosisByThreadIdOfManager,
  setDisplayPatientsListsOfManager,
  setDisplayPatientsListsOfManagerAcs,
  setApiErrorCode,
  playSound,
  getAcsAccessFailedDiagnosisCodeList,
  getAcsAccessFailedChatInfosOfDoctorTimer,
  getAcsFailedChatInfosOfManagerTimer,
  updateAcsRandomNumber,
  updateDiagnosesChatInfosOfDoctor,
  updateDiagnosesChatInfosOfManager,
  updateUnreadPatientNumber,
} from '../components/diagnosing/chatList/store/action';
import "./diagnosing/chatList/style/chatList.scss";

function CommonModal(props: any) {
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isLoginable = useSelector<any, any>((state) => state.login.isLoginable);
  const beforeLoading = useSelector<any, any>((state) => state.before.beforeLoading);
  const isDiagnosisLoading = useSelector<any, any>((state) => state.chat.isDiagnosisLoading);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const diagnosisLists = useSelector<any, any>((state) => state.chat.diagnosisLists);
  const doctors = useSelector<any, any>((state) => state.chat.doctors);
  const personalAcsId = useSelector<any, any>((state) => state.chat.personalAcsId);
  const personalAcsToken = useSelector<any, any>((state) => state.chat.personalAcsToken);
  const acsInfoRandomNumber = useSelector<any, any>((state) => state.chat.acsInfoRandomNumber);
  const patientNumberOfManager = useSelector<any, any>((state) => state.chat.patientNumberOfManager);
  const getdiagnosesSuccess = useSelector<any, any>((state) => state.chat.getdiagnosesSuccess);
  const clinicRandomNumber = useSelector<any, any>((state) => state.clinic.clinicRandomNumber);
  const endDiagnosisErrorCode = useSelector<any, any>((state) => state.prescription.endDiagnosisErrorCode);
  const endDiagnosisErrorMessage = useSelector<any, any>((state) => state.prescription.endDiagnosisErrorMessage);
  const noticeAuthLoading = useSelector<any, any>((state) => state.notice.noticeAuthLoading);
  const completedLoading = useSelector<any, any>((state) => state.history.completedLoading);
  const deliveryLoading = useSelector<any, any>((state) => state.deliveries.deliveryLoading);
  const deliveryAuthLoading = useSelector<any, any>((state) => state.deliveries.deliveryAuthLoading);

  const dispatch = useDispatch() as any;

  const diagnosisListsRef = JSON.parse(JSON.stringify(diagnosisLists));
  let listenRef = useRef(diagnosisListsRef);

  //エラーモードをクロース
  function closeErrorModal() {
    dispatch(updateEndDiagnosisErrCode(null));
  }

  //モニタリングイベントについてロジック　＜START＞

  //スレッドIDによって診療コードの取得
  function getDiagnosisCodeByThreadId(list: any, threadId: string) {
    let diagnosisCode = "";
    diagnosisCode = list.filter((item: any) => {
      return item.chatInfo.chatThreadId === threadId
    })[0].diagnosisCode;
    return diagnosisCode;
  }

  //スレッドIDが診療リストに存在するかどうかを判断する
  function determineThreadIdExist(threadId: string, diagnosisLists: any) {
    //医師の場合
    if (!isManager) {
      if (diagnosisLists.length > 0) {
        for (let i = 0; i < diagnosisLists[0].patientInfoLists.length; i++) {
          if (diagnosisLists[0].patientInfoLists[i].chatInfo.chatThreadId === threadId) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      //管理者の場合
      if (diagnosisLists.length > 0) {
        for (let dIndex = 0; dIndex < diagnosisLists.length; dIndex++) {
          for (let pIndex = 0; pIndex < diagnosisLists[dIndex].patientInfoLists.length; pIndex++) {
            if (diagnosisLists[dIndex].patientInfoLists[pIndex].chatInfo.chatThreadId === threadId) {
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    }
  }

  //診療リストにおける新しいメッセージのスレッドの位置と診療コードの取得
  function getDiagnosisInfoOfManager(diagnosisLists: any, threadId: string) {
    let diagnosisInfo = {} as any;
    breakName: for (var dIndex = 0; dIndex < diagnosisLists.length; dIndex++) {
      for (var pIndex = 0; pIndex < diagnosisLists[dIndex].patientInfoLists.length; pIndex++) {
        if (diagnosisLists[dIndex].patientInfoLists[pIndex].chatInfo.chatThreadId === threadId) {
          diagnosisInfo.dIndex = dIndex;
          diagnosisInfo.pIndex = pIndex;
          diagnosisInfo.diagnosisCode = diagnosisLists[dIndex].patientInfoLists[pIndex].diagnosisCode;
          break breakName;
        }
      }
    }
    return diagnosisInfo;
  }

  //送信者が医師かどうかを判断する
  function determineSenderIsDoctor(communicationUserId: string) {
    for (const doctor of doctors) {
      if (doctor.doctorPersonalACSId === communicationUserId) {
        return true;
      }
    }
    return false;
  }

  //医師のモニタリングイベント
  function listenEvent(chatClient: ChatClient, personalAcsId: string) {
    chatClient.on("chatMessageReceived", async (e) => {
      const isExist = determineThreadIdExist(e.threadId, listenRef.current);
      const lastMessageJst = e.createdOn;
      const lastMessage = e.message?.length > 12 ? e.message.slice(0, 12) + '…' : e.message;
      //新着メッセージを受信したとき初期化患者リストの未読マーク、最新メッセージと最新メッセージ時間の更新
      if (listenRef.current.length > 0 && isExist) {
        let initPatientLists = JSON.parse(JSON.stringify(listenRef.current[0].patientInfoLists));
        const diagnosisCodeInInitLists = getDiagnosisCodeByThreadId(listenRef.current[0].patientInfoLists, e.threadId);
        initPatientLists.forEach((item: any) => {
          if (item.diagnosisCode === diagnosisCodeInInitLists) {
            item.chatInfo.lastChatSendDatetimeJst = lastMessageJst;
            item.chatInfo.lastMessage = lastMessage;
            if (item.chatInfo.acsAccessFailed) {
              item.chatInfo.acsAccessFailed = false;
            }
            //患者からのメッセージだけは未読マークと患者の最後のチャットの送信日時を更新する
            if (e.sender.kind === "communicationUser") {
              if (e.sender.communicationUserId !== personalAcsId) {
                dispatch(updateUnreadPatientNumber(1));
                item.chatInfo.hasUnread = true;
                item.chatInfo.lastPatientChatSendDatetimeJst = lastMessageJst;
                //チャットリストに存在した患者来信　未返信状態に変更
                item.chatInfo.hasNoReply = true;
              } else {
                //チャットリストに存在した医師来信 未返信状態から返信したに変更
                item.chatInfo.hasNoReply = false;
              }
            }
          }
        })
        //ソート
        initPatientLists = sortByMessageDate(initPatientLists);
        //初期化患者リストの更新
        dispatch(setDiagnosisPatientInfoList(initPatientLists));
      }
      //医師に新着メッセージを受信すると音で気づける
      //管理者がメッセージ送信できないので、着信音がない
      if (isExist && e.sender.kind === "communicationUser") {
        if (e.sender.communicationUserId !== personalAcsId) {
          dispatch(playSound(e.sender.communicationUserId));
        }
      }
      // スレッドIDに基づいて診療情報取得
      if (!isExist && e.sender.kind === "communicationUser") {
        if (e.sender.communicationUserId !== personalAcsId) {
          const diagnosisByThreadIdFlag = await dispatch(getDiagnosisByThreadIdOfDoctor(msalToken, e.threadId, e.sender.communicationUserId, personalAcsId));
          if (diagnosisByThreadIdFlag) {
            const sound = require("../audio/ringtone.mp3");
            const music = new Audio(sound);
            music.volume = 0.4;
            music.play();
          }
        }
      }
    });
  }

  //管理者のモニタリングイベント
  function listenEventOfManager(chatClient: ChatClient) {
    chatClient.on("chatMessageReceived", async (e) => {
      const isExist = determineThreadIdExist(e.threadId, listenRef.current);
      const lastMessageJst = e.createdOn;
      const lastMessage = e.message?.length > 12 ? e.message.slice(0, 12) + '…' : e.message;
      //新着メッセージを受信したとき初期化患者リストの最新メッセージ時間の更新
      if (listenRef.current.length > 0 && isExist) {
        let initDiagnoses = JSON.parse(JSON.stringify(listenRef.current));
        const diagnosisInfo = getDiagnosisInfoOfManager(initDiagnoses, e.threadId);
        const doctorAcsId = initDiagnoses[diagnosisInfo.dIndex].doctorAcsId;
        let patientInfoLists = initDiagnoses[diagnosisInfo.dIndex].patientInfoLists;
        //最新メッセージと最新メッセージ時間の更新
        patientInfoLists[diagnosisInfo.pIndex].chatInfo.lastChatSendDatetimeJst = lastMessageJst;
        patientInfoLists[diagnosisInfo.pIndex].chatInfo.lastMessage = lastMessage;
        if (patientInfoLists[diagnosisInfo.pIndex].chatInfo.acsAccessFailed) {
          patientInfoLists[diagnosisInfo.pIndex].chatInfo.acsAccessFailed = false;
        }
        //患者からのメッセージだけは患者の最後のチャットの送信日時を更新する
        if (e.sender.kind === "communicationUser") {
          if (!determineSenderIsDoctor(e.sender.communicationUserId)) {
            patientInfoLists[diagnosisInfo.pIndex].chatInfo.lastPatientChatSendDatetimeJst = lastMessageJst;
          }
        }
        //ソート
        patientInfoLists = sortByMessageDate(patientInfoLists);
        //初期化患者リストの更新
        dispatch(setDiagnosisList(initDiagnoses));
        //ディスプレー患者リストの更新
        dispatch(setDisplayPatientsListsOfManager(doctorAcsId, patientInfoLists));
      }
      //患者が新しいメッセージを送ってきたときだけ、音で気づける
      if (isExist && e.sender.kind === "communicationUser") {
        const isSenderDoctor = determineSenderIsDoctor(e.sender.communicationUserId);
        if (!isSenderDoctor) {
          dispatch(playSound(e.sender.communicationUserId));
        }
      }
      // スレッドIDに基づいて診療情報取得
      if (!isExist && e.sender.kind === "communicationUser") {
        const isSenderDoctor = determineSenderIsDoctor(e.sender.communicationUserId);
        if (!isSenderDoctor) {
          dispatch(getDiagnosisByThreadIdOfManager(msalToken, e.threadId, e.sender.communicationUserId, listenRef.current, patientNumberOfManager));
          const sound = require("../audio/ringtone.mp3");
          const music = new Audio(sound);
          music.volume = 0.4;
          music.play();
        }
      }
    });
  }

  useEffect(() => {
    if (diagnosisLists.length > 0) {
      listenRef.current = JSON.parse(JSON.stringify(diagnosisLists));
    }
  }, [diagnosisLists]);

  //医師の监视事件
  useEffect(() => {
    try {
      if (isDoctor && personalAcsToken) {
        const tokenCredential = new AzureCommunicationTokenCredential(personalAcsToken);
        let chatClient = new ChatClient(ENDPOINT, tokenCredential);
        chatClient.startRealtimeNotifications();
        listenEvent(chatClient, personalAcsId);
        return () => {
          chatClient.stopRealtimeNotifications();
        }
      }
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsToken]);

  //管理者の监视事件
  useEffect(() => {
    try {
      if (isManager && personalAcsToken) {
        const tokenCredential = new AzureCommunicationTokenCredential(personalAcsToken);
        let chatClient = new ChatClient(ENDPOINT, tokenCredential);
        chatClient.startRealtimeNotifications();
        listenEventOfManager(chatClient);
        return () => {
          chatClient.stopRealtimeNotifications();
        }
      }
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsToken]);
  //モニタリングイベントについてロジック　＜END＞

  //タイマ：ACSへアクセスに失敗したACS診療情報を取得
  useEffect(() => {
    const timer = setTimeout(() => {
      let acsAccessFailedDiagnosisCodeList = [] as any;
      //医師
      if (isDoctor) {
        //ACS診療情報が未取得の診療コードリストの取得（上限値：50）
        let initDiagnoses = JSON.parse(JSON.stringify(diagnosisLists));
        if (initDiagnoses[0]?.patientInfoLists.length > 0) {
          acsAccessFailedDiagnosisCodeList = getAcsAccessFailedDiagnosisCodeList(initDiagnoses[0].patientInfoLists);
          const length = acsAccessFailedDiagnosisCodeList.length;
          if (length > 50) {
            acsAccessFailedDiagnosisCodeList = acsAccessFailedDiagnosisCodeList.slice(0, 50);
          }
          if (length > 0) {
            getAcsAccessFailedChatInfosOfDoctorTimer(msalToken, acsAccessFailedDiagnosisCodeList).then(async (chatInfos) => {
              //初期化診療リストのチャットインフォの更新
              await dispatch(updateDiagnosesChatInfosOfDoctor(chatInfos));
              //ACS情報によって変化された随机数の更新(タイマを再起動する)
              await dispatch(updateAcsRandomNumber(Math.floor(Math.random() * 100000)));
            });
          }
        }
      }
      //管理者
      if (isManager) {
        //ACS診療情報が未取得の診療コードリストの取得（上限値：50）
        let initDiagnoses = JSON.parse(JSON.stringify(diagnosisLists));
        outerLoop: for (const diagnosis of initDiagnoses) {
          if (Array.isArray(diagnosis.patientInfoLists)) {
            for (const patientInfo of diagnosis.patientInfoLists) {
              if (patientInfo.chatInfo.acsAccessFailed) {
                acsAccessFailedDiagnosisCodeList.push(patientInfo.diagnosisCode);
                if (acsAccessFailedDiagnosisCodeList.length >= 50) {
                  break outerLoop;
                }
              }
            }
          }
        }
        const length = acsAccessFailedDiagnosisCodeList.length;
        if (length > 0) {
          getAcsFailedChatInfosOfManagerTimer(msalToken, acsAccessFailedDiagnosisCodeList).then(async (chatInfos) => {
            //初期化診療リストのチャットインフォの更新
            await dispatch(updateDiagnosesChatInfosOfManager(chatInfos));
            //ディスプレー患者リストの更新
            await dispatch(setDisplayPatientsListsOfManagerAcs());
            //ACS情報によって変化された随机数の更新(タイマを再起動する)
            await dispatch(updateAcsRandomNumber(Math.floor(Math.random() * 100000)));
          })
        }
      }
      //5s
    }, 5 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getdiagnosesSuccess, acsInfoRandomNumber, clinicRandomNumber]);

  return (
    <>
      <HeaderNav />
      {endDiagnosisErrorCode ? <ErrorModal errCode={endDiagnosisErrorCode} errMessage={endDiagnosisErrorMessage} closeErrorModal={closeErrorModal} /> : ''}
      {!isLoginable && !isDiagnosisLoading ? <Login /> : ''}
      {props.diagnosisTab === 0 && completedLoading ? <Loading /> : ''}
      {props.diagnosisTab === 0 && props.diagnosisCode && !completedLoading ? <HistoryClinicModal diagnosisCode={props.diagnosisCode} /> : ''}
      {props.diagnosisTab === 0 && !props.diagnosisCode && !completedLoading ? <History searchParams={props.searchParams} /> : ''}
      {props.diagnosisTab === 1 && !isDiagnosisLoading ?
        <section id="chatList">
          <div id="chatList-content" className="flex">
            <ChatList />
            {selectedDiagnosisCode === '' ?
              <div id="patient_select" className="column is-two-thirds padding diagnosis">
                <div className="center_posi" style={{ position: 'relative' }}>患者を選択してください</div>
              </div>
              :
              <DiagnosisModal />
            }
          </div>
        </section> : ''}
      {props.diagnosisTab === 1 && isDiagnosisLoading ? <Loading /> : ''}
      {props.diagnosisTab === 2 ? <SitePolicy /> : ''}
      {props.diagnosisTab === 3 && beforeLoading ? <Loading /> : ''}
      {props.diagnosisTab === 3 && !beforeLoading ? <BeforeDiagnoses searchParams={props.searchParams} /> : ''}
      {props.diagnosisTab === 4 && (deliveryAuthLoading || deliveryLoading) ? <Loading /> : ''}
      {props.diagnosisTab === 4 && !deliveryAuthLoading && !deliveryLoading ? <Deliveries /> : ''}
      {props.diagnosisTab === 5 && noticeAuthLoading ? <Loading /> : ''}
      {props.diagnosisTab === 5 && !noticeAuthLoading ? <Notice /> : ''}
    </>
  )
}

export default CommonModal;