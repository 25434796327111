import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { VIDEO_DIAGNOSIS } from '../../../api/Api';
import { post } from '../../../api/FetchAPI';
import './style/clinic.scss';
import { dateFullFormat, timeFormat } from "../../../utils/TimeFormat";
import { updateProposeMedicineModal, updateVideoScheduleModal, setVideoDiagnosisErrorCode, setShowVideoRelatedModalIndex, updateVideoStatusId, getNewFirstStartVideoFlag } from "./store/action";
import { setVideoErrCode } from "../video/store/action";
import { PROPOSE_PRICE_PLAN_001, PROPOSE_PRICE_PLAN_002, PROPOSE_SHEET_001, PROPOSE_SHEET_002, DESIRED_VIDEO_TIME_ITEM, } from '../../../config/constant';
import MedicalInterviewTab from "./MedicalInterviewTab";
import PersonalInfoTab from "./PersonalInfoTab";
import DiagnosisHistoryTab from "./DiagnosisHistoryTab";
import VideoLinkModal from './VideoLinkModal';
import VideoSelectModal from './VideoSelectModal';
import VideoScheduleModal from "./VideoScheduleModal";
import CommentModal from "./CommentModal";
import ErrorModal from '../../ErrorModal';
import Loading from "../../Loading";
import VideoWindow from "../video/VideoWindow";
import VideoTimeErrorModal from "./VideoTimeErrorModal";
import ClinicRoomErrorPage from "./ClinicRoomErrorPage";
import ProposeMedicineModal from "./ProposeMedicineModal";
import { sendSentryLog, setSentryBreadcrumb } from '../../../utils/SentryFuc';

function ClinicRoom(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const isClinicLoading = useSelector<any, any>((state) => state.chat.isClinicLoading);
  const clinicErrCode = useSelector<any, any>((state) => state.clinic.clinicErrCode);
  const showPrescriptionProposalModal = useSelector<any, any>((state) => state.clinic.showPrescriptionProposalModal);
  const showCommentModal = useSelector<any, any>((state) => state.clinic.showCommentModal);
  const videoDiagnosisErrCode = useSelector<any, any>((state) => state.clinic.videoDiagnosisErrCode);
  const showVideoScheduleModal = useSelector<any, any>((state) => state.clinic.showVideoScheduleModal);
  const showVideoRelatedModalIndex = useSelector<any, any>((state) => state.clinic.showVideoRelatedModalIndex);
  const videoDiagnosisStatusId = useSelector<any, any>((state) => state.clinic.videoDiagnosisStatusId);
  const diagnosisScheduledStartDatetimeJst = useSelector<any, any>((state) => state.clinic.diagnosisScheduledStartDatetimeJst);
  const diagnosisScheduledEndDatetimeJst = useSelector<any, any>((state) => state.clinic.diagnosisScheduledEndDatetimeJst);
  const proposeMedicineProductName = useSelector<any, any>((state) => state.clinic.proposeMedicineProductName);
  const proposePricePlan = useSelector<any, any>((state) => state.clinic.proposePricePlan);
  const firstStartVideo = useSelector<any, any>((state) => state.video.firstStartVideo);
  const videoErrCode = useSelector<any, any>((state) => state.video.videoErrCode);
  const desiredVideoTime = useSelector<any, any>((state) => state.clinic.desiredVideoTime);
  const getAcsVideoInfoTime = useSelector<any, any>((state) => state.video.getAcsVideoInfoTime);

  //タブ：問診、患者情報、診療履歴
  const [tab, setTab] = useState('medicalInterview');
  const [isClickable, setIsClickable] = useState<boolean>(true);
  const HighAge = 34;

  const dispatch = useDispatch() as any;

  //患者の希望プランを取得
  const desiredPricePlan = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.desiredPricePlan
  }, [selectedDiagnosisCode, displayPatientsLists]);

  const videoStart = diagnosisScheduledStartDatetimeJst;
  const videoEnd = diagnosisScheduledEndDatetimeJst;

  //ビデオ診療日時の書式設定
  const videoTime = useMemo(() => {
    if (!videoStart || !videoEnd) {
      return undefined;
    }
    return (
      dateFullFormat(videoStart) +
      ' ' +
      timeFormat(videoStart) +
      ' 〜 ' +
      timeFormat(videoEnd)
    )
  }, [videoStart, videoEnd]);

  //タブラベルの切り替え
  function changeTabName(tabName: string) {
    setTab(tabName);
  }

  //「処方薬提案」ダイアログを開く
  function openProposeMedicineModal() {
    //sentry log
    sendSentryLog('薬剤提案ダイアログを開く', 'openProposeMedicineDialog', 'log',
      { 'event_name': 'propose_medicine', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateProposeMedicineModal(true));
  }

  //「ビデオ日時設定」ダイアログを開く
  function openVideoScheduleModal() {
    //sentry log
    sendSentryLog('ビデオ日時ダイアログを開く', 'openVideoTimeDialog', 'log',
      { 'event_name': 'set_video_time', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateVideoScheduleModal(true));
  }

  //ビデオ診療ステータスをDBに設定する
  const setVideoStatusId = async (statusId: number) => {
    try {
      const data = {
        diagnosisCode: selectedDiagnosisCode,
        statusId: statusId,
        startDatetimeJst: diagnosisScheduledStartDatetimeJst,
        endDatetimeJst: diagnosisScheduledEndDatetimeJst,
      }
      const response = await post(VIDEO_DIAGNOSIS, data, msalToken) as any;
      if (response.data.errorCode) {
        dispatch(setVideoDiagnosisErrorCode(response.data.errorCode));
      }
    } catch (e: any) {
      dispatch(setVideoDiagnosisErrorCode(e.errorCode ?? e.code));
    }
  }

  //エラーモードをクロース
  function closeVideoDiagnosisErrorModal() {
    dispatch(setVideoDiagnosisErrorCode(null));
  }

  //エラーモードをクロース
  function closeVideoErrorModal() {
    dispatch(setVideoErrCode(null));
  }

  //ビデオ診療ステータスをreducerとDBに設定する
  function changeVideoDiagnosisStatusId(e: any) {
    dispatch(updateVideoStatusId(parseInt(e.target.value)));
    setVideoStatusId(parseInt(e.target.value));
  }

  //「ビデオ入室」ボタンの連続クリック防止対応
  const handleClick = () => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      startVideo();
      if (firstStartVideo && new Date(Date.now()).getTime() - getAcsVideoInfoTime.getTime() > 15 * 60 * 1000) {
        setTimeout(() => {
          setIsClickable(true);
        }, 3000)
      } else {
        setIsClickable(true);
      }
    }
  }

  //「ビデオ入室」ボタンのクリック事件
  async function startVideo() {
    let newFirstStartVideo = firstStartVideo;
    // ビデオ診療用のURL未発行 かつ 診療室を開く/前回のACSビデオ情報取得から15分以上経過してからビデオ入室ダイアログを開こうとしたときは、再度種類を取得する
    if (firstStartVideo) {
      const nowJst = new Date(Date.now());
      if (nowJst.getTime() - getAcsVideoInfoTime.getTime() > 15 * 60 * 1000) {
        newFirstStartVideo = await dispatch(getNewFirstStartVideoFlag(msalToken, selectedDiagnosisCode));
      }
    }
    if (newFirstStartVideo) {
      //sentry breadcrumbs
      setSentryBreadcrumb({
        type: 'info',
        category: 'startVideo',
        message: 'ビデオ入室ダイアログを開く(初回) diagnosisCode:' + selectedDiagnosisCode + ' & patientUid:' + personalInfoDetail.personalUid,
        level: 'info',
      });
      dispatch(setShowVideoRelatedModalIndex(1));
    } else if (newFirstStartVideo === false) {
      //sentry breadcrumbs
      setSentryBreadcrumb({
        type: 'info',
        category: 'startVideo',
        message: 'ビデオ入室ダイアログを開く(初回以後) diagnosisCode:' + selectedDiagnosisCode + ' & patientUid:' + personalInfoDetail.personalUid,
        level: 'info',
      });
      dispatch(setShowVideoRelatedModalIndex(2));
    }
  }

  //デフォルトタブの設定
  useEffect(() => {
    changeTabName('medicalInterview');
  }, [selectedDiagnosisCode]);

  return (
    <>
      {showVideoScheduleModal ? <VideoScheduleModal statusId={videoDiagnosisStatusId} chatThreadClient={props.chatThreadClient} /> : ''}
      {showPrescriptionProposalModal ? <ProposeMedicineModal chatThreadClient={props.chatThreadClient} /> : ''}
      {showVideoRelatedModalIndex === 1 ? <VideoLinkModal /> : ''}
      {showVideoRelatedModalIndex === 2 ? <VideoSelectModal /> : ''}
      {showVideoRelatedModalIndex === 3 ? <VideoWindow chatThreadClient={props.chatThreadClient} /> : ''}
      {showVideoRelatedModalIndex === 4 ? <VideoTimeErrorModal /> : ''}
      {showCommentModal ? <CommentModal /> : ''}
      {videoDiagnosisErrCode ? <ErrorModal errCode={videoDiagnosisErrCode} closeErrorModal={closeVideoDiagnosisErrorModal} /> : ''}
      {videoErrCode ? <ErrorModal errCode={videoErrCode} closeErrorModal={closeVideoErrorModal} /> : ''}
      {!isClinicLoading && !clinicErrCode ?
        <div id="clinic_room" className="column is-two-thirds padding diagnosis" style={{ width: 'calc(66.6666% - 240px)' }}>
          <div className="has-padding-1 has-margin-1 space-between-block" style={{ position: 'relative' }}>
            <div>
              <span id="clinicName" className="vertical-align-middle is-size-4 has-text-weight-bold">
                {personalInfoDetail.personalFamilyName + ' '}
                {personalInfoDetail.personalFirstName}
              </span>
              <span id="clinicNameFuri" className="vertical-align-middle">
                ({personalInfoDetail.personalFamilyNameFurigana + ' '}
                {personalInfoDetail.personalFirstNameFurigana})
              </span>
              <span id="clinicAge" className={personalInfoDetail.personalAge >= HighAge ? "vertical-align-middle has-margin-left-right-1 has-high-age" : "vertical-align-middle has-margin-left-right-1"}>
                年齢：<span id="clinicAgeNumber" className={personalInfoDetail.personalAge >= HighAge ? "has-font-size-16" : ""}>{personalInfoDetail.personalAge}</span>歳
              </span>
              <div className="clinic-id">
                <span id="clinicUid" className="clinic-uid">
                  患者ID：<span>{personalInfoDetail.personalUid}</span>
                </span>
                <span id="clinicDiagnosisCode" className="clinic-diagnosis-code">
                  診療ID：<span>{selectedDiagnosisCode}</span>
                </span>
              </div>
            </div>
          </div>
          <div id="clinic_drug" className="graybgarea whitebgarea space-between-block" style={{ position: 'relative' }}>
            <div className="field is-horizontal is-align-items-center w100">
              <div>
                <label id="clinicDrugTitle" className="label">
                  処方薬
                </label>
              </div>
              <div className="field-body is-align-items-center is-justify-content-space-between">
                <div className="list">
                  <table>
                    <tbody>
                      <tr>
                        <th id="clinicDesiredPlanTitle">希望プラン</th>
                        {
                          desiredPricePlan === '定期'
                            ? <td id="clinicDesiredPlan">{PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001}</td>
                            : <td id="clinicDesiredPlan" className="summaryplan-blue">{PROPOSE_PRICE_PLAN_002 + " " + PROPOSE_SHEET_002}</td>
                        }
                      </tr>
                      <tr>
                        <th id="clinicProposeMedicineTitle">提案中薬剤</th>
                        {
                          proposeMedicineProductName
                            ?
                            <>
                              {proposePricePlan === PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001
                                ? <td id="clinicProposeMedicine">{PROPOSE_PRICE_PLAN_001} {proposeMedicineProductName}</td>
                                : <td id="clinicProposeMedicine" className="summaryplan-blue">{PROPOSE_PRICE_PLAN_002} {proposeMedicineProductName}</td>}
                            </>
                            : <td id="clinicProposeMedicine">未提案</td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="btn_style1">
                  <button
                    id="clinicProposeMedicineBtn"
                    disabled={isManager}
                    className={isManager ? "disabled" : ''}
                    onClick={openProposeMedicineModal}
                  >
                    処方薬提案
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="clinic_video" className="graybgarea whitebgarea clinic-video-block" style={{ position: 'relative' }}>
            <div className="field is-horizontal is-align-items-center w100">
              <label id="clinicVideoTitle" className="label">
                ビデオ<br id="video_datetime_br" />診療
              </label>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      id="diagnosisScheduledDatetimeInput"
                      type="text"
                      placeholder="クリックして日時を入力"
                      className={isManager || showVideoRelatedModalIndex === 3 ? 'input disable-input' : 'input'}
                      value={videoTime || ''}
                      disabled={isManager || showVideoRelatedModalIndex === 3}
                      readOnly
                      onClick={openVideoScheduleModal}
                    />
                    <span className="icon is-small is-right" style={{ height: '-webkit-fill-available' }}>
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded select is-normal">
                    <select
                      id="clinicVideoStatusSelect"
                      disabled={isManager || showVideoRelatedModalIndex === 3}
                      onChange={(e) => { changeVideoDiagnosisStatusId(e) }}
                      value={videoDiagnosisStatusId}
                    >
                      <option value="0">未実施</option>
                      <option value="1">実施済</option>
                    </select>
                  </p>
                </div>
                <div className="border"></div>
                <div className="btn_style1">
                  <button
                    id="clinicVideoStartBtn"
                    disabled={isManager || !videoStart || !videoEnd || showVideoRelatedModalIndex === 3}
                    className={isManager || !videoStart || !videoEnd || showVideoRelatedModalIndex === 3 ? "disabled" : ''}
                    onClick={() => { handleClick() }}
                  >ビデオ入室
                  </button>
                </div>
              </div>
            </div>
            <div className="field is-horizontal is-align-items-center w100 clinic-video-field">
              <label id="clinicDesiredVideoTitle" className="label">
                希望する曜日・時間帯
              </label>
              <div className="video-desired-body">
               <div id="clinicDesiredVideoWeekday" className="video-desired-field">
                <div className={desiredVideoTime !== null && (
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_MORNING) || 
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_AFTERNOON) || 
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_NIGHT)
                  ) ? 'video-desired-title blue-text' : 'video-desired-title'}>
                  平日
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_MORNING) ? 
                  'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                  午前
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_AFTERNOON) ? 
                  'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                  午後
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_NIGHT) ? 
                  'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                  夜
                </div>
               </div>
               <div id="clinicDesiredVideoWeekend" className="video-desired-field">
                <div className={desiredVideoTime !== null && (
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_MORNING) || 
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_AFTERNOON) || 
                  desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_NIGHT)) ? 
                  'video-desired-title red-text' : 'video-desired-title'}>
                 土日祝
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_MORNING) ? 
                  'video-desired-item red-text red-item' : 'video-desired-item'}>
                  午前
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_AFTERNOON) ? 
                  'video-desired-item red-text red-item' : 'video-desired-item'}>
                  午後
                </div>
                <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_NIGHT) ? 
                  'video-desired-item red-text red-item' : 'video-desired-item'}>
                  夜
                </div>
               </div>
              </div>
            </div>
            <div className="field is-horizontal is-align-items-center w100 clinic-video-text-field">
              <div id="disiredVideoTimeText" className="video-desired-description-text">
                ※患者が希望する診療時間は以下の通り表示されています。<br />　午前：～12:00　午後：12:00～18:00　夜：18:00～
              </div>
            </div>
          </div>

          <div className="tab-parent" style={{ height: 'calc(100% - 370.5px)', position: 'relative' }}>
            <div id="clinicTabs" className="tabs tabs-clinic is-boxed">
              <ul>
                <li className={tab === 'medicalInterview' ? 'is-active' : ''}>
                  <a id="clinicMedicalInterViewTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('medicalInterview') }}>
                    <span className={'tabFont'}>問診</span>
                  </a>
                </li>
                <li className={tab === 'personalInfo' ? 'is-active' : ''}>
                  <a id="clinicPersonalInfoTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('personalInfo') }}>
                    <span className={'tabFont'}>患者情報</span>
                  </a>
                </li>
                <li className={tab === 'diagnosisHistory' ? 'is-active' : ''}>
                  <a id="clinicDiagnosisHistoryTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('diagnosisHistory') }}>
                    <span className={'tabFont'}>診療履歴</span>
                  </a>
                </li>
              </ul>
            </div>
            {tab === 'medicalInterview' ? <MedicalInterviewTab /> : ''}
            {tab === 'personalInfo' ? <PersonalInfoTab /> : ''}
            {tab === 'diagnosisHistory' ? <DiagnosisHistoryTab /> : ''}
          </div>

        </div>
        : ''
      }
      {
        isClinicLoading && !clinicErrCode ?
          <div
            id="clinic_room"
            style={{ width: 'calc(66.6666% - 240px)' }}
          >
            <Loading />
          </div> : ''
      }
      {clinicErrCode ? <ClinicRoomErrorPage /> : ''}
    </>
  )
}

export default ClinicRoom;