import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowVideoRelatedModalIndex } from './store/action';
import { setSentryBreadcrumb, sendSentryLog } from '../../../utils/SentryFuc';
import { startVideo } from "../../../utils/VideoFuc";

function VideoLinkModal() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const [isClickable, setIsClickable] = useState<boolean>(true);

  const dispatch = useDispatch() as any;

  //ビデオ入室ダイアログを閉じる
  function closeVideoLinkModal() {
    //sentry log
    sendSentryLog('ビデオ入室ダイアログを閉じる(初回)', 'selectVideoTypeDialogFirst', 'log',
      { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(setShowVideoRelatedModalIndex(0));
  }

  async function handleVideoLink() {
    //sentry breadcrumbs
    setSentryBreadcrumb({
      type: 'info',
      category: 'startVideo',
      message: '初回で「新しいビデオリンクを発行して入室する」ボタンを押す diagnosisCode:' + selectedDiagnosisCode + ' & patientUid:' + personalInfoDetail.personalUid,
      level: 'info',
    });
    //ビデオ診療開始時、一時トークン、一時トークンの有効期限などを作成
    const response = await dispatch(startVideo(msalToken, selectedDiagnosisCode));
    //「VideoWindow」を開く
    if (response.data.errorCode === null) {
      dispatch(setShowVideoRelatedModalIndex(3));
    }
  }

  //「ビデオリンクを発行し入室する」ボタンのクリック事件
  const handleClick = () => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      handleVideoLink();
      setTimeout(() => {
        setIsClickable(true);
      }, 3000)
    }
  }

  return (
    <>
      <div id="videoLinkDialog" className="dialog">
        <div className="wrap">
          <div id="closeVideoLinkDialog" className="close" onClick={() => { closeVideoLinkModal() }}></div>
          <div className="inner">
            <p id="videoLinkDialogText" className="read">
              ボタンを押すと、<br />患者とのビデオ通話を開始します。
            </p>
            <button id="videoLinkBtn" onClick={handleClick}>
              ビデオリンクを発行し入室する
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default VideoLinkModal;