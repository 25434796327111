import { sortByMessageDate } from '../../../../utils/SortList';

const defaultState = ({
	//一覧データ取得flag、APIでデータを取得完了後trueになる
	isDiagnosisLoading: true,
	//自身（医者/管理者）のACS ID
	personalAcsId: "",
	//自身（医者/管理者）のACS Token
	personalAcsToken: "",
	//自身（医者/管理者）のACS Tokenの有効期限
	personalAcsTokenExpiredOnJst: "",
	//管理者の場合は診療リストの長さ
	patientNumberOfManager: 0,
	//診療情報一覧
	diagnosisLists: [],
	//表示用データ
	displayPatientsLists: [],
	//選択中の診療コード
	selectedDiagnosisCode: '',
	//フォルダダイアログ表示flag
	showSearchModal: false,
	//条件で絞込する表示flag
	isSearching: false,
	//API エラーコード
	apiErrorCode: null,
	//API エラーメッセージ
	apiErrorMessage: null,
	//選択中の医師のACS ID（管理者用）
	selectedDoctorAcsId: '',
	//管理者用の医師一覧データ
	doctors: [],
	//ビデオ状態
	videoStatus: false,
	//ACS情報によって変化された随机数
	acsInfoRandomNumber: -1,
	//診療中一覧データを取得中(診療前/診療終了⇒診療中)
	fetchingDiagnosesFlagA: false,
	//診療中一覧データを取得中(診療中⇒診療前/診療終了)
	fetchingDiagnosesFlagB: false,
	//未読患者数
	unreadPatientNumber: 0,
	//診療中一覧データの取得に成功flag
	getdiagnosesSuccess: false,
	//医師が担当する患者数
	takeOnPatientNumber: 0,
	// 診療室関連データ取得flag
	isClinicLoading: true,
	//絞込ラジオ値
	searchRadioValue: '',
})

const variable = (state = defaultState, action: any) => {
	switch (action.type) {
		case 'set_diagnosis_loading':
			return { ...state, isDiagnosisLoading: action.flag }
		case 'set_personal_acsid':
			return { ...state, personalAcsId: action.id }
		case 'set_personal_acstoken':
			return { ...state, personalAcsToken: action.token }
		case 'set_personal_acstoken_expiredon':
			return { ...state, personalAcsTokenExpiredOnJst: action.time }
		case 'set_patient_number_of_manager':
			return { ...state, patientNumberOfManager: action.length }
		case 'set_diagnosis_list':
			return { ...state, diagnosisLists: action.list }
		case 'set_diagnosis_patient_info_list':
			const newDiagnosisState = JSON.parse(JSON.stringify(state));
			newDiagnosisState.diagnosisLists[0].patientInfoLists = action.list;
			//選択した患者の未読マークの消去
			if (newDiagnosisState.diagnosisLists[0].patientInfoLists.length > 0 && state.selectedDiagnosisCode !== '') {
				for (let i = 0; i < newDiagnosisState.diagnosisLists[0].patientInfoLists.length; i++) {
					if (newDiagnosisState.diagnosisLists[0].patientInfoLists[i].diagnosisCode === state.selectedDiagnosisCode
						&& newDiagnosisState.diagnosisLists[0].patientInfoLists[i].chatInfo.hasUnread) {
						newDiagnosisState.diagnosisLists[0].patientInfoLists[i].chatInfo.hasUnread = false;
						newDiagnosisState.unreadPatientNumber = state.unreadPatientNumber - 1;
						break;
					}
				}
			}
			return newDiagnosisState;
		case 'set_diagnosis_patient_info_list_end_diagnosis':
			const newDiagnosisStateAfterEnd = JSON.parse(JSON.stringify(state));
			// 診療終了の患者を診療リストから削除
			const newpatientInfoLists = newDiagnosisStateAfterEnd.diagnosisLists[0].patientInfoLists.filter((item: any) => { return item.diagnosisCode !== action.selectedDiagnosisCode });
			newDiagnosisStateAfterEnd.diagnosisLists[0].patientInfoLists = newpatientInfoLists;
			return newDiagnosisStateAfterEnd;
		case 'set_display_patients_list':
			let patientList = action.list;
			//選択した患者の未読マークの消去 医師の場合だけ処理する
			if (patientList.length > 0 && state.selectedDiagnosisCode !== '' && state.doctors.length === 0) {
				for (let i = 0; i < patientList.length; i++) {
					if (patientList[i].diagnosisCode === state.selectedDiagnosisCode) {
						patientList[i].chatInfo.hasUnread = false;
						break;
					}
				}
			}
			return { ...state, displayPatientsLists: patientList }
		case 'set_display_patients_lists_by_no_reply':
			const data = JSON.parse(JSON.stringify(state));
			let displayPatientsListsByNoReply = [];
          	displayPatientsListsByNoReply = data.diagnosisLists[0].patientInfoLists.filter(
            	(item: any) => { return item.chatInfo.hasNoReply === true })
			return { ...state, displayPatientsLists: displayPatientsListsByNoReply }
		case 'set_display_patients_list_of_manager':
			const displayPatientsOfManagerState = JSON.parse(JSON.stringify(state));
			if (action.doctorAcsId === displayPatientsOfManagerState.selectedDoctorAcsId) {
				//絞り込み状態の場合はディスプレー患者リストの更新
				if (displayPatientsOfManagerState.isSearching) {
					let displayPatientsListsAfterSearch = action.list;
					displayPatientsListsAfterSearch = displayPatientsListsAfterSearch.filter((item: any) => {
						return (displayPatientsOfManagerState.displayPatientsLists.some((display: any) => display.diagnosisCode === item.diagnosisCode))
					})
					displayPatientsOfManagerState.displayPatientsLists = sortByMessageDate(displayPatientsListsAfterSearch);
				} else {
					//絞り込み状態ではない場合はディスプレー患者リストの更新
					displayPatientsOfManagerState.displayPatientsLists = action.list;
				}
			}
			if (action.doctorAcsId === '') {
				displayPatientsOfManagerState.displayPatientsLists = action.list;
			}
			return displayPatientsOfManagerState;
		case 'set_display_patient_list_of_manager_acs':
			const displayPatientsOfManagerAcsState = JSON.parse(JSON.stringify(state));
			//選択された医師の患者
			let patientInfoListsOfSelectedDoctor = displayPatientsOfManagerAcsState.diagnosisLists.filter((item: any) => {
				return item.doctorAcsId === state.selectedDoctorAcsId;
			})[0]?.patientInfoLists;
			//絞込状況で
			if (state.isSearching) {
				//未読で
				if (state.searchRadioValue === "unread") {
					patientInfoListsOfSelectedDoctor = patientInfoListsOfSelectedDoctor.filter((item: any) => {
						return item.chatInfo.hasUnread === true
					})
				} else if (state.searchRadioValue === "noReply") {
					// 未返信で
					patientInfoListsOfSelectedDoctor = patientInfoListsOfSelectedDoctor.filter((item: any) => {
						return item.chatInfo.hasNoReply === true
					})
				} else {
					//その他
					patientInfoListsOfSelectedDoctor = patientInfoListsOfSelectedDoctor.filter((item: any) => {
						return (displayPatientsOfManagerAcsState.displayPatientsLists.some((display: any) => display.diagnosisCode === item.diagnosisCode))
					})
				}
				displayPatientsOfManagerAcsState.displayPatientsLists = sortByMessageDate(patientInfoListsOfSelectedDoctor);
			} else {
				//絞込ない状態で
				displayPatientsOfManagerAcsState.displayPatientsLists = patientInfoListsOfSelectedDoctor;
			}
			return displayPatientsOfManagerAcsState;
		case 'set_diagnosis_code':
			return { ...state, selectedDiagnosisCode: action.diagnosisCode }
		case 'set_init_unread_mark':
			const initUnreadState = JSON.parse(JSON.stringify(state));
			initUnreadState.diagnosisLists[0].patientInfoLists.forEach((item: any) => {
				if (item.diagnosisCode === action.diagnosisCode) {
					item.chatInfo.hasUnread = action.flag;
				}
			})
			return initUnreadState;
		case 'set_display_unread_mark':
			const displayUnreadState = JSON.parse(JSON.stringify(state));
			displayUnreadState.displayPatientsLists.forEach((item: any) => {
				if (item.diagnosisCode === action.diagnosisCode) {
					item.chatInfo.hasUnread = action.flag;
				}
			})
			return displayUnreadState;
		case 'update_search_modal':
			return { ...state, showSearchModal: action.flag }
		case 'update_search_flag':
			return { ...state, isSearching: action.flag }
		case 'update_diagnoses':
			const newAddState = JSON.parse(JSON.stringify(state));
			//絞込状態ではない
			if (!newAddState.isSearching) {
				if (newAddState.diagnosisLists.length > 0) {
					newAddState.diagnosisLists[0].patientInfoLists.push(action.list);
					newAddState.displayPatientsLists.push(action.list);
					sortByMessageDate(newAddState.diagnosisLists[0].patientInfoLists);
					sortByMessageDate(newAddState.displayPatientsLists);
				} else {
					newAddState.diagnosisLists.push({ 'doctorAcsId': newAddState.personalAcsId, 'patientInfoLists': [] });
					newAddState.diagnosisLists[0].patientInfoLists.push(action.list);
					newAddState.displayPatientsLists.push(action.list);
				}
			} else {
				//絞込状態で
				if (newAddState.diagnosisLists.length > 0) {
					newAddState.diagnosisLists[0].patientInfoLists.push(action.list);
					sortByMessageDate(newAddState.diagnosisLists[0].patientInfoLists);
				} else {
					newAddState.diagnosisLists.push({ 'doctorAcsId': newAddState.personalAcsId, 'patientInfoLists': [] });
					newAddState.diagnosisLists[0].patientInfoLists.push(action.list);
				}
				if (newAddState.searchRadioValue === "unread" || newAddState.searchRadioValue === "noReply") {
					newAddState.displayPatientsLists.push(action.list);
					sortByMessageDate(newAddState.displayPatientsLists);
				}
			}
			return newAddState;
		case 'update_diagnoses_of_manager':
			const newAddStateOfManager = JSON.parse(JSON.stringify(state));
			//絞込状態ではない
			if (!newAddStateOfManager.isSearching) {
				newAddStateOfManager.diagnosisLists[action.index].patientInfoLists.push(action.list);
				if (action.doctorAcsId === newAddStateOfManager.selectedDoctorAcsId) {
					newAddStateOfManager.displayPatientsLists.push(action.list);
				}
				sortByMessageDate(newAddStateOfManager.diagnosisLists[action.index].patientInfoLists);
				sortByMessageDate(newAddStateOfManager.displayPatientsLists);
			} else {
				//絞込状態で
				newAddStateOfManager.diagnosisLists[action.index].patientInfoLists.push(action.list);
				sortByMessageDate(newAddStateOfManager.diagnosisLists[action.index].patientInfoLists);
				if ((newAddStateOfManager.searchRadioValue === "unread" || newAddStateOfManager.searchRadioValue === "noReply")
						&& action.doctorAcsId === newAddStateOfManager.selectedDoctorAcsId) {
					newAddStateOfManager.displayPatientsLists.push(action.list);
					sortByMessageDate(newAddStateOfManager.displayPatientsLists);
				}
			}
			return newAddStateOfManager;
		case 'set_api_err_code':
			return { ...state, apiErrorCode: action.errCode }
		case 'set_api_err_msg':
			return { ...state, apiErrorMessage: action.errMsg }
		case 'update_video_date':
			const newVideoDateState = JSON.parse(JSON.stringify(state));
			newVideoDateState.diagnosisLists[0].patientInfoLists.forEach((item: any) => {
				if (item.diagnosisCode === newVideoDateState.selectedDiagnosisCode) {
					item.diagnosisScheduledStartDatetimeJst = action.startVideoTime;
					item.diagnosisScheduledEndDatetimeJst = action.endVideoTime;
				}
			})
			return newVideoDateState;
		case 'set_doctors':
			return { ...state, doctors: action.doctors }
		case 'set_selected_doctor_acsid':
			return { ...state, selectedDoctorAcsId: action.doctorAcsId }
		case 'update_video_status':
			return { ...state, videoStatus: action.flag }
		case 'play_sound':
			let selectedPersonalAcsId = '';
			state.displayPatientsLists.forEach((item: any) => {
				if (item.diagnosisCode === state.selectedDiagnosisCode) {
					selectedPersonalAcsId = item.personalACSId;
				}
			})
			if (selectedPersonalAcsId !== action.senderUserId && state.videoStatus === false) {
				const sound = require("../../../../audio/ringtone.mp3");
				const music = new Audio(sound);
				music.volume = 0.4;
				music.play();
			}
			return { ...state }
		case 'update_acs_random_number':
			return { ...state, acsInfoRandomNumber: action.number }
		case 'update_diagnoses_chat_infos_of_doctor':
			const newChatInfosState = JSON.parse(JSON.stringify(state));
			let unreadPatientNumberOfDoctor = state.unreadPatientNumber;
			if (Array.isArray(newChatInfosState.diagnosisLists[0].patientInfoLists)) {
				for (let chatInfo of action.chatInfos) {
					for (let patientInfo of newChatInfosState.diagnosisLists[0].patientInfoLists) {
						if (chatInfo.diagnosisCode === patientInfo.diagnosisCode) {
							//ACS情報の取得前にACS情報フラグがfalseで、ACS情報の取得が成功した場合最新メッセージ、最新メッセージ時間とフラグの更新
							if (patientInfo.chatInfo.acsAccessFailed && !chatInfo.acsAccessFailed) {
								if (chatInfo.hasUnread) {
									//未読患者数を計算する
									unreadPatientNumberOfDoctor++;
								}
								patientInfo.chatInfo.hasUnread = chatInfo.hasUnread;
								patientInfo.chatInfo.lastChatSendDatetimeJst = chatInfo.lastChatSendDatetimeJst;
								patientInfo.chatInfo.lastPatientChatSendDatetimeJst = chatInfo.lastPatientChatSendDatetimeJst;
								patientInfo.chatInfo.lastMessage = chatInfo.lastMessage;
								patientInfo.chatInfo.acsAccessFailed = chatInfo.acsAccessFailed;
								patientInfo.chatInfo.hasNoReply = chatInfo.hasNoReply;
							}
						}
					}
				}
			}
			newChatInfosState.unreadPatientNumber = unreadPatientNumberOfDoctor;
			sortByMessageDate(newChatInfosState.diagnosisLists[0].patientInfoLists);
			return newChatInfosState;
		case 'update_diagnoses_chat_infos_of_manager':
			const newChatInfosOfManagerState = JSON.parse(JSON.stringify(state));
			let unreadPatientNumberOfManager = state.unreadPatientNumber;
			for (let chatInfo of action.chatInfos) {
				secondLoop: for (let diagnosis of newChatInfosOfManagerState.diagnosisLists) {
					if (Array.isArray(diagnosis.patientInfoLists)) {
						for (let patientInfo of diagnosis.patientInfoLists) {
							if (chatInfo.diagnosisCode === patientInfo.diagnosisCode) {
								//ACS情報の取得に成功した未読マークを更新
								if (!chatInfo.acsAccessFailed) {
									patientInfo.chatInfo.hasUnread = chatInfo.hasUnread;
								}
								//ACS情報の取得前にACS情報フラグがfalseで、ACS情報の取得が成功した場合最新メッセージ、最新メッセージ時間とフラグの更新
								if (!chatInfo.acsAccessFailed && patientInfo.chatInfo.acsAccessFailed) {
									if (chatInfo.hasUnread) {
										//未読患者数を計算する
										unreadPatientNumberOfManager++;
									}
									patientInfo.chatInfo.lastChatSendDatetimeJst = chatInfo.lastChatSendDatetimeJst;
									patientInfo.chatInfo.lastPatientChatSendDatetimeJst = chatInfo.lastPatientChatSendDatetimeJst;
									patientInfo.chatInfo.lastMessage = chatInfo.lastMessage;
									patientInfo.chatInfo.acsAccessFailed = chatInfo.acsAccessFailed;
									patientInfo.chatInfo.hasNoReply = chatInfo.hasNoReply;
								}
								sortByMessageDate(diagnosis.patientInfoLists);
								break secondLoop;
							}
						}
					}
				}
			}
			newChatInfosOfManagerState.unreadPatientNumber = unreadPatientNumberOfManager;
			return newChatInfosOfManagerState;
		case 'update_fetch_diagnoses_flag_a':
			return { ...state, fetchingDiagnosesFlagA: action.flag }
		case 'update_fetch_diagnoses_flag_b':
			return { ...state, fetchingDiagnosesFlagB: action.flag }
		case 'update_unread_patient_number':
			return { ...state, unreadPatientNumber: state.unreadPatientNumber + action.number }
		case 'update_get_diagnoses_success':
			return { ...state, getdiagnosesSuccess: action.flag }
		case 'set_take_on_patient_number':
			return { ...state, takeOnPatientNumber: action.number }
		case 'update_take_on_patient_number':
			if (!action.isManager) {
				return { ...state, takeOnPatientNumber: state.takeOnPatientNumber + action.number }
			} else {
				if (state.selectedDoctorAcsId === action.doctorAcsId) {
					return { ...state, takeOnPatientNumber: state.takeOnPatientNumber + action.number }
				} else {
					return state
				}
			}
		case 'set_clinic_loading':
			if (!action.flag && action.diagnosisCode === state.selectedDiagnosisCode) {
				return { ...state, isClinicLoading: false }
			} else {
				return { ...state, isClinicLoading: true }
			}
		case 'update_search_radio_value':
			return { ...state, searchRadioValue: action.radio }
		default:
			return state;
	}
}

export default variable;