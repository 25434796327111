import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import '../../style/bulmaConfig.scss';
import './style/login.scss';
import { getGroupList, setRedirect } from './store/action';
import MsalAuth from '../../utils/MsalAuth';
import Footer from '../Footer';

function Login() {
  const isLoginable = useSelector<any, any>((state) => state.login.isLoginable);
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDeliver = useSelector<any, any>((state) => state.login.isDeliver);
  const redirect = useSelector<any, any>((state) => state.login.redirect);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const patientNumberOfManager = useSelector<any, any>((state) => state.chat.patientNumberOfManager);
  const doctors = useSelector<any, any>((state) => state.chat.doctors);
  const fetchingDiagnosesFlagA = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagA);
  const fetchingDiagnosesFlagB = useSelector<any, any>((state) => state.chat.fetchingDiagnosesFlagB);

  const [notAuthorized, setNotAuthorized] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();
  const msal = MsalAuth();

  // loginボタンクリックする時の処理
  async function toolLogin() {
    try {
      setIsLoginLoading(true);
      const auth = await msal.login();
      //リダイレクトによって移行先のページを確認
      //デフォルトはログイン
      let pageTab = 9;
      //診療終了診療コード
      let historyDiagnosisCode = "";
      //診療前一覧検索条件
      let searchParams = {
        pageNo: null,
        surname: null,
        givenName: null,
      };
      const loginAble = await dispatch(getGroupList(
        auth.accessToken, isLoginable, isDoctor, isManager, isDeliver, historyDiagnosisCode, displayPatientsLists?.length,
        patientNumberOfManager, pageTab, searchParams, navigate, doctors, fetchingDiagnosesFlagA, fetchingDiagnosesFlagB
      ));
      //ログイン権限があるグループのユーザーの操作
      if (loginAble) {
        //リダイレクト先がある場合はそちらへ遷移する
        if (redirect !== null && redirect !== '') {
          const newRedirect = redirect.replace(window.location.origin, "");
          navigate(newRedirect, { replace: true });
          dispatch(setRedirect(null));
        } else {
          navigate("/beforeDiagnoses");
        }
      } else {
        // ログイン権限の無いグループのユーザーはログインさせない
        navigate("/login");
        msal.logout();
        setNotAuthorized(true);
        setIsLoginLoading(false);
      }
    } catch (e: any) {
      // ユーザー自身がキャンセルした時もここに入る
      if (e.isAxiosError) {
        msal.logout();
      }
      setNotAuthorized(true);
      setIsLoginLoading(false);
    }
  }

  return (
    <>
      <section>
        <h1 className="has-text-centered">ルナルナ おくすり便</h1>
        {notAuthorized ?
          <div className="login-error">ログインできませんでした</div>
          :
          ''}
        <div className="top-title has-text-centered">
          <button type="submit" className="button is-primary" onClick={() => { toolLogin() }}>
            ログイン
          </button>
        </div>
        {isLoginLoading ?
          <div className='login-loading-background'><LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} /></div>
          : ''}
      </section>
      <Footer />
    </>
  )
}

export default Login;