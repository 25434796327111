import { useDispatch, useSelector } from "react-redux";
import * as bulmaToast from "bulma-toast";
import "../../style/design.scss";
import {
  updateTakeOnModal, updateAlreadyTakeOnModal, setBeforeDiagnoses, setAssignedCount, setUnAssignedCount,
  updateTakeOnLoading, updateTakeOnErrorCode, updateTakeOnErrorMsg
} from "./store/action";
import { UNASSIGNED_DIAGNOSIS } from "../../api/Api";
import { post } from "../../api/FetchAPI";
import { ERR_CODE_A002, ERR_MSG_001 } from "../../config/constant";

function TakeOnModal() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const takeOnDiagnosisCode = useSelector<any, any>((state) => state.before.takeOnDiagnosisCode);
  const beforeDiagnoses = useSelector<any, any>((state) => state.before.beforeDiagnoses);
  const assignedCount = useSelector<any, any>((state) => state.before.assignedCount);
  const unAssignedCount = useSelector<any, any>((state) => state.before.unAssignedCount);
  const takeOnErrorCode = useSelector<any, any>((state) => state.before.takeOnErrorCode);
  const takeOnErrorMessage = useSelector<any, any>((state) => state.before.takeOnErrorMessage);

  const dispatch = useDispatch() as any;

  //担当モードをクロース
  function closeTakeOnModal() {
    dispatch(updateTakeOnModal(false));
    dispatch(updateTakeOnErrorCode(null));
    dispatch(updateTakeOnErrorMsg(null));
  }

  //担当する
  async function takeOn() {
    try {
      // dispatch(updateTakeOnEnd(false));
      dispatch(updateTakeOnLoading(true));
      const data = {
        diagnosisCode: takeOnDiagnosisCode
      };
      const response = await post(UNASSIGNED_DIAGNOSIS, data, msalToken) as any;
      //現状APIのバグにより200でエラーの場合があるのでcatchの外でエラーコードを入れる
      if (response.data.errorCode) {
        if (response.data.errorCode === ERR_CODE_A002) {
          dispatch(updateTakeOnModal(false));
          dispatch(updateAlreadyTakeOnModal(true));
        } else {
          dispatch(updateTakeOnErrorCode(response.data.errorCode));
          dispatch(updateTakeOnErrorMsg(ERR_MSG_001));
        }
      } else {
        //正常な場合
        let diagnoses = JSON.parse(JSON.stringify(beforeDiagnoses));
        const index = diagnoses.findIndex(
          (i: any) => i.diagnosisCode === takeOnDiagnosisCode
        )
        diagnoses[index].doctorPersonalFamilyName = msalName;
        dispatch(updateTakeOnModal(false));
        dispatch(setAssignedCount(assignedCount + 1));
        dispatch(setUnAssignedCount(unAssignedCount - 1));
        dispatch(setBeforeDiagnoses(diagnoses));
        bulmaToast.toast({
          message: `担当医に設定しました`,
          position: 'center',
          type: 'is-dark',
          single: true,
        })
      }
    } catch (e: any) {
      dispatch(updateTakeOnErrorCode(e.errorCode ?? e.code));
      dispatch(updateTakeOnErrorMsg(ERR_MSG_001));
    } finally {
      dispatch(updateTakeOnLoading(false));
    }
  }

  return (
    <div id="takeOnDialog" className="dialog">
      <div className="wrap">
        <div id="takeOnDialogClose" className="close" onClick={closeTakeOnModal}></div>
        <div className="inner">
          <p id="takeOnDialogTitle" className="read">こちらの診療を担当しますか？</p>
          {takeOnErrorCode || takeOnErrorMessage ?
            <div
              id="takeOnErrMsg"
              className="has-text-danger has-padding-1 has-text-weight-bold error-message"
            >
              {takeOnErrorMessage}
              {takeOnErrorCode ? <span><br />&lt;エラーコード：{takeOnErrorCode}&gt;</span> : ''}
            </div>
            : ''}
          <div className="button-wrap">
            <button id="takeOnBtn" onClick={takeOn}>
              担当する
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TakeOnModal;