import { useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createStatefulChatClient } from "@azure/communication-react";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import HistoryClinicRoom from "./HistoryClinicRoom";
import ChatWindow from '../diagnosing/chatRoom/ChatWindow';
import { ENDPOINT } from '../../config';
import { setApiErrorCode } from '../diagnosing/chatList/store/action';
import '../diagnosing/clinicRoom/style/clinic.scss';
import './style/history.scss';

function HistoryClinicModal(props: any) {
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const personalAcsId = useSelector<any, any>((state) => state.chat.personalAcsId);
  const personalAcsToken = useSelector<any, any>((state) => state.chat.personalAcsToken);
  const completedDiagnosisInfo = useSelector<any, any>((state) => state.history.completedDiagnosisInfo);

  const dispatch = useDispatch() as any;

  const statefulChatClient = useMemo(() => {
    try {
      if (completedDiagnosisInfo.chatThreadId && personalAcsToken)
        return createStatefulChatClient({
          userId: { communicationUserId: personalAcsId },
          displayName: msalName,
          endpoint: ENDPOINT,
          credential: new AzureCommunicationTokenCredential(personalAcsToken),
        })
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsId, msalName, personalAcsToken]);

  const chatThreadClient = useMemo(() => {
    try {
      if (statefulChatClient)
        return statefulChatClient.getChatThreadClient(
          completedDiagnosisInfo.chatThreadId
        )
    } catch (e: any) {
      const errcode = e.code ?? e.errorCode;
      dispatch(setApiErrorCode("ACS_" + errcode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statefulChatClient]);
  
  return (
    <section id="history_chatList">
      <div id="history_content" className="flex">
        <HistoryClinicRoom diagnosisCode={props.diagnosisCode} />
        <div className="column medicine">
          <div id="chat_area" className="chat_area">
            {completedDiagnosisInfo.chatThreadId ?
              <ChatWindow statefulChatClient={statefulChatClient} chatThreadClient={chatThreadClient} />
              :
              <div className="center_posi">チャット情報がありません</div>
            }
          </div>
          <div className="btn_end">
            <a id="prescription_end_btn" href="#!" className='disabled'>
              処方<br />
              ・<br />
              診療<br />
              終了
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HistoryClinicModal;