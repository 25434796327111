import { get, post } from "../../../api/FetchAPI";
import { NOTICE_DETAIL, SEARCH_NOTICE } from "../../../api/Api";
import { ERR_MSG_014, ERR_CODE_A001 } from '../../../config/constant';

// 以下、内部用reduxのデータ処理
const setTableData = (data: any) => ({
  type: 'set_table_data',
  data
})

const updateTableLoading = (flag: boolean) => ({
  type: 'update_table_loading',
  flag
})

const setTableTotalPage = (page: number) => ({
  type: 'set_table_total_pages',
  page
})

const setTableTotalRecords = (records: number) => ({
  type: 'set_table_total_records',
  records
})

const setTableTotalDisplayRecords = (records: number) => ({
  type: 'set_table_total_display_records',
  records
})

const setTablePageFirstNum = (num: number) => ({
  type: 'set_table_page_first_num',
  num
})

const setTablePageLastNum = (num: number) => ({
  type: 'set_table_page_last_num',
  num
})

// 以下、外部用reduxのデータ処理
export const updateNoticeErrorCode = (code: string) => ({
  type: 'update_notice_error_code',
  code
})

export const updateNoticeErrorMsg = (message: string) => ({
  type: 'update_notice_error_msg',
  message
})

export const setTableCurrentPage = (page: number) => ({
  type: 'set_table_current_page',
  page
})

export const setTableSortField = (field: string) => ({
  type: 'set_table_sort_field',
  field
})

export const setTableSortOrder = (order: string) => ({
  type: 'set_table_sort_order',
  order
})

export const setNotice = (notice: any) => ({
  type: 'set_notice',
  notice
})

export const setInternalManageName = (name: string) => ({
  type: 'set_internal_manage_name',
  name
})

export const setTitle = (title: string) => ({
  type: 'set_title',
  title
})

export const setContent = (content: string) => ({
  type: 'set_content',
  content
})

export const setType = (value: string) => ({
  type: 'set_type',
  value
})

export const setDisplayOrder = (order: any) => ({
  type: 'set_display_order',
  order
})

export const setBannerUrl = (url: string) => ({
  type: 'set_banner_url',
  url
})

export const setDisplayStartTime = (time: any) => ({
  type: 'set_display_start_time',
  time
})

export const setDisplayEndTime = (time: any) => ({
  type: 'set_display_end_time',
  time
})

export const setDelete = (flag: boolean) => ({
  type: 'set_delete',
  flag
})

export const setSpecificUserFileUrl = (url: string) => ({
  type: 'set_specific_user_file_url',
  url
})

export const updateNoticeLoading = (flag: boolean) => ({
  type: 'update_notice_loading',
  flag
})

export const setPublishNotices = (notices: any) => ({
  type: 'set_publish_notices',
  notices
})

export const updateNoticeAuthLoading = (flag: boolean) => ({
  type: 'update_notice_auth_loading',
  flag
})

//お知らせテーブルデータの取得
export const getTableData = (auth: string, query: any, perPage: number, currentPage: number, sortField: string, sortOrder: string) => {
  return async (dispatch: any) => {
    try {
      const params = {
        noticeID: query.noticeID,
        freeWord: query.freeWord,
        noticeType: query.noticeType,
        noticeDisplayStatus: query.noticeDisplayStatus,
        isPublished: query.isPublished,
        isDeleted: query.isDeleted,
        displayStart: currentPage,
        displayLength: perPage,
        sortingColumns: [
          {
            column: sortField,
            sortDir: sortOrder,
          },
        ],
      };
      dispatch(updateTableLoading(true));
      let response = await post(SEARCH_NOTICE, params, auth) as any;
      const result = response.data;
      if (result.errorCode) {
        dispatch(updateNoticeErrorCode(result.errorCode));
      } else {
        dispatch(setTableData(result.searchResult));
        dispatch(setTableTotalRecords(result.totalRecords));
        dispatch(setTableTotalDisplayRecords(result.totalDisplayRecords));
        dispatch(setTableTotalPage(result.totalPages));
        dispatch(updateTableLoading(false));
        //テーブルのフッター用のデータ
        let fistNum = 0;
        if (result.totalDisplayRecords !== 0) {
          fistNum = (currentPage - 1) * perPage + 1;
          dispatch(setTablePageFirstNum(fistNum));
        } else {
          dispatch(setTablePageFirstNum(0));
        }
        if (currentPage !== result.totalPages) {
          const lastNum = fistNum + perPage - 1;
          dispatch(setTablePageLastNum(lastNum));
        } else {
          dispatch(setTablePageLastNum(result.totalDisplayRecords));
        }
        if (result.totalPages === 0) {
          dispatch(setTablePageLastNum(0));
        }
      }
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.code ?? e.errorCode));
      dispatch(updateTableLoading(false));
    }
  }
}

//noticeNoによってお知らせ詳細情報を取得
export const getNoticeDetailByNo = (auth: string, noticeNo: string) => {
  return async (dispatch: any) => {
    try {
      await dispatch(updateNoticeLoading(true));
      const params = {
        noticeNo: noticeNo
      };
      let response = await get(NOTICE_DETAIL, params, auth) as any;
      if (!response.data.errorCode) {
        await dispatch(setNotice(response.data));
      } else if (response.data.errorCode === ERR_CODE_A001) {
        dispatch(updateNoticeErrorCode(response.data.errorCode));
        dispatch(updateNoticeErrorMsg(ERR_MSG_014));
      } else {
        dispatch(updateNoticeErrorCode(response.data.errorCode));
      }
      await dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.code ?? e.errorCode));
      await dispatch(updateNoticeLoading(false));
    }
  }
}

//未本番反映リストの取得
export const getPublishNotices = (auth: string) => {
  return async (dispatch: any) => {
    try {
      const params = {
        isPublished: [false],
        isDeleted: [false],
        sortingColumns: [
          {
            column: 'noticeID',
            sortDir: 'desc',
          },
        ],
      };
      dispatch(updateNoticeLoading(true));
      let response = await post(SEARCH_NOTICE, params, auth) as any;
      const result = response.data;
      if (result.errorCode) {
        dispatch(updateNoticeErrorCode(result.errorCode));
      } else {
        dispatch(setPublishNotices(result.searchResult));
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
      await dispatch(updateNoticeLoading(false));
    }
  }
}