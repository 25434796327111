import { useSelector } from "react-redux";
import { useMemo } from "react";
import moment from "moment";
import * as bulmaToast from "bulma-toast";

function MedicalInterview() {
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const medicalInterviews = useSelector<any, any>((state) => state.clinic.medicalInterviews);
  const completedDiagnosisInfo = useSelector<any, any>((state) => state.history.completedDiagnosisInfo);

   //問診回答日時の取得  
  const medicalInterviewEndDatetimeJst = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.medicalInterviewEndDatetimeJst
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //問診回答日時をフォマードする　  
  const answerDateTime = useMemo(() => {
    if (selectedDiagnosisCode === "" && completedDiagnosisInfo) {
      return moment(completedDiagnosisInfo.medicalInterviewEndDatetimeJst).format("YYYY/MM/DD HH:mm");
    } else {
      return moment(medicalInterviewEndDatetimeJst).format("YYYY/MM/DD HH:mm");
    }
  }, [selectedDiagnosisCode, completedDiagnosisInfo, medicalInterviewEndDatetimeJst]);

  //禁忌・慎重投与の回答がありますか
  const isHasAnyInformation = useMemo(() => {
    return medicalInterviews.some((q: any) =>
      q.results.some((r: any) => r.hasInformation)
    )
  }, [medicalInterviews]);

  //問診の並べ替え
  const orderedQuestions = useMemo(() => {
    return medicalInterviews
      .slice()
      .sort((a: any, b: any) => a.questionDisplayOrder - b.questionDisplayOrder)
  }, [medicalInterviews]);

  //問診回答の並べ替え
  function orderedAnswers(answers: any) {
    return answers
      .slice()
      .sort((a: any, b: any) => a.answerDisplayOrder - b.answerDisplayOrder)
  }

  //改行
  function convertNewLine(content: string) {
    return content.replace(/\n+/g, '<br />')
  }

  //新しい行をスペースに変換
  function convertNewLineToSpace(content: string) {
    return content?.replaceAll('\n', ' ') ?? ''
  }

  //回答のコピー
  async function copyMedicalInterviewAnswer(questionNumber: any, results: any) {
    try {
      let copyText = ''
      const answers = orderedAnswers(results);
      for (const answer of answers) {
        if (answer.answerType === 0 || answer.answerType === 3) {
          const array = []
          if (answer.answerPrefixText)
            array.push(convertNewLineToSpace(answer.answerPrefixText))
          if (answer.answerContents)
            array.push(convertNewLineToSpace(answer.answerContents))
          if (answer.answerSuffixText)
            array.push(convertNewLineToSpace(answer.answerSuffixText))
          copyText += '　' + array.join(' ')
        } else {
          copyText +=
            '　' + convertNewLineToSpace(answer.answerSuffixText)
          if (answer.answerContents && answer.answerContents !== '1') {
            copyText +=
              '　' + convertNewLineToSpace(answer.answerContents)
          }
        }
      }
      copyText = copyText.substring(1);
      await navigator.clipboard.writeText(copyText);
      bulmaToast.toast({
        message: `問診の${questionNumber}をコピーしました`,
        position: 'center',
        type: 'is-dark',
        single: true,
      })
    } catch { }
  }

  return (
    <div id="tab_medicine" className="tab-medical-interview-container">
      <div id="medicine_content" className="tab-medical-interview-content">
        {isHasAnyInformation ? <div id="alert_summary" className="has-alert-summary">＜！＞禁忌・慎重投与の回答があります</div> : ''}
        <div id="alert_summary_date" className="has-alert-summary_date">問診回答日時：{answerDateTime}</div>
        {orderedQuestions.map((question: any, index: number) => {
          return (
            <div key={index}>
              <div id={"medicine_item_" + question.questionNumber} className="medical-interview-content-item">
                <div>{question.questionNumber}.&nbsp;</div>
                <div dangerouslySetInnerHTML={{ __html: convertNewLine(question.questionContents) }}></div>
              </div>
              <div id={"answer_" + question.questionNumber} className={question.results.some((r: any) => r.hasInformation) ? 'has-information-answer' : 'answer'}>
                {orderedAnswers(question.results).map((answer: any, index: number) => {
                  return (
                    <div key={index}>
                      {answer.answerType === 0 || answer.answerType === 3 ?
                        <div>
                          {answer.answerPrefixText}&nbsp;
                          {answer.answerContents}&nbsp;
                          {answer.answerSuffixText}
                        </div> :
                        <div>
                          {answer.answerSuffixText}
                          {answer.answerContents && answer.answerContents !== '1' ? <br /> : ''}
                          {answer.answerContents && answer.answerContents !== '1' ? <span>{answer.answerContents}</span> : ''}
                        </div>
                      }
                    </div>
                  )
                })}

                <div className="has-text-right has-margin-top-1">
                  <a
                    id={"copy_text_" + question.questionNumber}
                    className="copy-text"
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      copyMedicalInterviewAnswer(question.questionNumber, question.results)
                    }}
                  >
                    コピーする
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MedicalInterview