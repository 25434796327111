import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ChatMessageType } from "@azure/communication-chat";
import { updateProposeMedicineModal } from "./store/action";
import { updateProposeMedicineProductName, updateProposePricePlan } from '../clinicRoom/store/action';
import { PROPOSE_PRICE_PLAN_001, PROPOSE_PRICE_PLAN_002, PROPOSE_SHEET_001, PROPOSE_SHEET_002, ERR_MSG_010, SENTRT_CONTEXT_ID, PROPOSE_MESSAGE_001, PROPOSE_MESSAGE_002, PROPOSE_MESSAGE_003, PROPOSE_MESSAGE_004, PROPOSE_MESSAGE_005, PROPOSE_MESSAGE_006, PROPOSE_MESSAGE_007, PROPOSE_MESSAGE_008, PROPOSE_MESSAGE_009, PROPOSE_MESSAGE_010, PROPOSE_MESSAGE_011 } from "../../../config/constant";
import { post } from '../../../api/FetchAPI';
import { PROPOSE_MEDICINE } from '../../../api/Api';
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';

function ProposeMedicineModal(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const medicines = useSelector<any, any>((state) => state.clinic.medicines);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const [medicine, setMedicine] = useState(null);
  const [proposeMedicineErrorMsg, setProposeMedicineErrorMsg] = useState<any>(null);
  const [proposeMedicineErrorCode, setProposeMedicineErrorCode] = useState<any>(null);
  const [isClickable, setIsClickable] = useState<boolean>(true);

  const dispatch = useDispatch() as any;

  //「処方薬提案」ダイアログをクロース
  function closeProposeMedicineModal() {
    //sentry log
    sendSentryLog('薬剤提案ダイアログを閉じる', 'closeProposeMedicineDialog', 'log',
      { 'event_name': 'propose_medicine', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateProposeMedicineModal(false));
  }

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //処方薬提案についてメッセージを送信
  async function sendProposeMedicineMessages(medicine: any, planMasterName: string) {
    let msgTypeText: ChatMessageType = "text";
    let msgTypeHtml: ChatMessageType = "html";

    //お薬に関する説明
    const sendMessageRequest1 = {
      content: PROPOSE_MESSAGE_001 + "\n" + PROPOSE_MESSAGE_002 + "\n\n" + PROPOSE_MESSAGE_003 + "\n" + medicine.medicineProductName + " " + planMasterName + "\n" + PROPOSE_MESSAGE_004 + "\n" + medicine.medicineInfo + "\n" + PROPOSE_MESSAGE_005 + "\n" + PROPOSE_MESSAGE_006 + "\n\n" + PROPOSE_MESSAGE_007 + "\n" + PROPOSE_MESSAGE_008 + "\n" + PROPOSE_MESSAGE_009 + "\n" + PROPOSE_MESSAGE_010 + "\n\n" + PROPOSE_MESSAGE_011
    };
    let sendMessageOptions1 = {
      senderDisplayName: msalName,
      type: msgTypeText,
      metadata: {
        linkURL: medicine.medicineInfo + ';' + PROPOSE_MESSAGE_006
      }
    };

    //薬剤のカード
    let messageContent = "";
    const imageUrlArr = medicine.medicineImageUrl.split(";");
    const startA = '<a target="_blank" href="';
    imageUrlArr.forEach((item: any, index: any) => {
      messageContent = messageContent + startA + item + '">' + medicine.medicineProductName + '_' + planMasterName + '_' + (index + 1) + '</a>';
      if (index < (imageUrlArr.length - 1)) {
        messageContent = messageContent + '<br />';
      };
    })
    const sendMessageRequest2 = {
      content: messageContent,
    };
    let sendMessageOptions2 = {
      senderDisplayName: msalName,
      type: msgTypeHtml,
      metadata: {
        picURL: medicine.medicineImageUrl
      }
    };

    //内容を確認
    const sendMessageRequest3 = {
      content: '<a target="_blank" href="' + medicine.confirmedImageUrl + '">共通_提案内容確認</a>',
    };
    let sendMessageOptions3 = {
      senderDisplayName: msalName,
      type: msgTypeHtml,
      metadata: {
        picURL: medicine.confirmedImageUrl
      }
    };

    //sentry log
    const sentryContext: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        threadId: props.chatThreadClient.threadId
      }
    };
    sendSentryLog('薬剤提案のメッセージ送信処理が走る', 'execProposeMedicineSendMsg', 'log',
      { 'event_name': 'propose_medicine', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode },
      [sentryContext]
    );
    //listenイベントがメッセージをリッスンできなくなるのを防ぐために、500ms待ち
    await delay(500);
    //メッセージを送信
    await props.chatThreadClient.sendMessage(sendMessageRequest1, sendMessageOptions1);
    await delay(500);
    await props.chatThreadClient.sendMessage(sendMessageRequest2, sendMessageOptions2);
    await delay(500);
    await props.chatThreadClient.sendMessage(sendMessageRequest3, sendMessageOptions3);
  }

  //提案処方薬登録更新
  const saveProposeMedicine = async (medicine: any) => {
    let result = false;
    const param = {
      pricePlanId: medicine.planMasterId,
      medicineId: medicine.medicineMasterId,
      diagnosisCode: selectedDiagnosisCode,
    } as any;
    const sentryContextApi: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        api: PROPOSE_MEDICINE
      }
    };
    try {
      const response = await post(PROPOSE_MEDICINE, param, msalToken) as any;
      if (response.data.errorCode === null) {
        result = true;
        //Reducerに保存
        if (medicine.planMasterId === 1) {
          await dispatch(updateProposeMedicineProductName(medicine.medicineProductName));
          await dispatch(updateProposePricePlan(PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001));
        } else {
          await dispatch(updateProposeMedicineProductName(medicine.medicineProductName));
          await dispatch(updateProposePricePlan(PROPOSE_PRICE_PLAN_002 + " " + PROPOSE_SHEET_002));
        }
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error',
          { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextApiError]
        );
        setProposeMedicineErrorMsg(ERR_MSG_010);
        setProposeMedicineErrorCode(response.data.errorCode);
      }
      return result;
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContextApiError]
      );
      setProposeMedicineErrorMsg(ERR_MSG_010);
      setProposeMedicineErrorCode(e.errorCode ?? e.code);
      return result;
    }
  }

  //処方薬提案する」ボタンのクリック事件
  async function handleProposeMedicine(medicine: any) {
    let planMasterName = "";
    let proposeMedicine = ""
    if (medicine.planMasterId === 2) {
      planMasterName = PROPOSE_PRICE_PLAN_002;
      proposeMedicine = PROPOSE_PRICE_PLAN_002 + " " + PROPOSE_SHEET_002
    } else {
      planMasterName = PROPOSE_PRICE_PLAN_001;
      proposeMedicine = PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001
    }
    //sentry log
    const sentryContext: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        proposeMedicine: proposeMedicine + " " + medicine.medicineProductName
      }
    };
    sendSentryLog('薬剤提案を実施する', 'execProposeMedicine', 'log',
      { 'event_name': 'propose_medicine', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
      , [sentryContext]);
    //提案処方薬登録更新
    const result = await saveProposeMedicine(medicine);
    if (result) {
      //「処方薬提案」ダイアログをクロース
      dispatch(updateProposeMedicineModal(false));
      //メッセージを送信
      await sendProposeMedicineMessages(medicine, planMasterName);
    }
  }

  //「処方薬提案する」ボタンのクリック事件
  const handleClick = (medicine: any) => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      handleProposeMedicine(medicine);
      setTimeout(() => {
        setIsClickable(true);
      }, 3000)
    }
  }

  return (
    <div id="proposeMedicineDialog" className="dialog">
      <div className="wrap width">
        <div id="closeProposeMedicine" className="close" onClick={closeProposeMedicineModal}></div>
        <div className="inner">
          <p id="proposeMedicineTitle" className="read">処方薬提案</p>
          {proposeMedicineErrorMsg || proposeMedicineErrorCode ?
            <div id="proposeMedicineErrMsg" className="has-text-danger has-padding-1 has-text-weight-bold error-message">
              {proposeMedicineErrorMsg}
              {proposeMedicineErrorCode ? <span><br />&lt;エラーコード：{proposeMedicineErrorCode}&gt;</span> : ''}
            </div> : ''}
          <div id="proposeMedicineList" className="col_layout">
            {medicines.map((medicinePlan: any, index: number) => {
              return (
                <div className={medicinePlan.planMasterId === 2 ? 'radios blue' : 'radios'} key={index}>
                  <div>
                    <section className="has-margin-bottom-1">
                      <div className={medicinePlan.planMasterId === 2 ? 'medicine-plan color-blue' : 'medicine-plan'}>
                        {medicinePlan.planName}
                      </div>
                      {medicinePlan.medicines.map((medicine: any) => {
                        return (
                          <div className='field' key={medicinePlan.planMasterId + medicine.medicineProductName}>
                            <div className={medicinePlan.planMasterId === 2 ? 'choice color-blue' : 'choice'}>
                              <label className="b-radio radio">
                                <input
                                  name="medicine"
                                  type="radio"
                                  value={JSON.stringify(medicine)}
                                  onChange={(e: any) => { setMedicine(JSON.parse(e.target.value)); }}
                                />
                                <span className="check"></span>
                                <span className="control-label">
                                  <span className={medicinePlan.planMasterId === 2 ? 'medicine-name color-blue' : 'medicine-name'}>
                                    {medicine.medicineProductName}
                                  </span>
                                </span>
                              </label>
                              <div className={medicinePlan.planMasterId === 2 ? 'sheet color-blue' : 'sheet'}>
                                {medicine.sheetCount}シート×{medicine.times}回
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </section>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="button-wrap">
            <button
              id="proposeMedicineBtn"
              disabled={medicine === null ? true : false}
              onClick={() => { handleClick(medicine) }}
            >
              処方薬提案する
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProposeMedicineModal;