import { useSelector, useDispatch } from "react-redux";
import { dateFullFormat } from "../../../utils/TimeFormat";
import { updateCommentModal, updateSelectedComment } from "./store/action";

function DiagnosisHistory() {
  const diagnosisHistories = useSelector<any, any>((state) => state.clinic.diagnosisHistories);

  const dispatch = useDispatch() as any;

  //コメントダイアログをオープンする
  function showComment(comment: string) {
    dispatch(updateCommentModal(true));
    dispatch(updateSelectedComment(comment));
  }

  //コメントをフォマードする
  function formatComment(comment: string) {
    if (comment && comment.length > 28) {
      return comment.slice(0, 28) + '...';
    } else {
      return comment;
    }
  }

  return (
    <div id="tab_history" className="tab-content" style={{ display: 'block' }}>
      {diagnosisHistories.length === 0 ?
        <span>診療履歴はありません</span>
        :
        <>
          {diagnosisHistories.map((history: any, index: number) => {
            return (
              <div className="box py-5" key={index}>
                <div className="columns px-2">
                  <div id={"c_history_date_" + index} className="column my-1 has-text-left is-one-fifth" style={{ wordBreak: 'break-all' }}>
                    {dateFullFormat(history.diagnosisEndDatetimeJst)}
                  </div>
                  <div className="column has-text-left">
                    <div id={"c_history_doctor_name_" + index} className="column my-1">担当医：{history.doctorName}</div>
                    <div id={"c_history_diagnosis_code_" + index} className="column my-1">診療コード：{history.diagnosisCode}</div>
                    {
                      history.medicineProductName !== null ?
                        <div id={"c_history_medicine_product_" + index} className="column my-1 product-name">
                          {history.medicineProductName}&nbsp;&nbsp;{history.sheetCount}シート×{history.times}回
                        </div>
                        :
                        <div id={"c_history_no_medicine_" + index} className="column my-1">
                          処方なし：{history.reasonContent}
                        </div>
                    }
                    {
                      history.comments ?
                        <div id={"c_history_comment_content_" + index} className="column my-1">
                          診療コメント：<span
                            id={"c_history_comment_" + index}
                            className="history-comment"
                          >
                            {formatComment(history.comments)}
                          </span>
                          {history.comments && history.comments.length > 28 ?
                            <span
                              id={"c_history_clickable_" + index}
                              className="is-clickable is-underlined"
                              onClick={() => { showComment(history.comments) }}
                            >
                              続きを見る
                            </span>
                            : ''}
                        </div>
                        : ''
                    }
                  </div>
                </div>
              </div>
            )
          })
          }
        </>
      }
    </div>
  )
}

export default DiagnosisHistory;