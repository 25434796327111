import { useMemo } from "react";
import { dateFullFormat, timeFormat } from "../../utils/TimeFormat";

function DiagnosisCard(props: any) {
  //患者が初診がどうかを判断する
  const isFirstVisit = useMemo(() => {
    return props.diagnosis.numberOfDiagnosis === '初診'
  }, [props.diagnosis.numberOfDiagnosis]);

  return (
    <div className="card columns">
      <div className="patient-name column column-before is-2">
        <div>
          {props.diagnosis.personalFamilyName}&nbsp;{
            props.diagnosis.personalFirstName
          }
        </div>
        <div className="kana">
          {props.diagnosis.personalFamilyNameFurigana}&nbsp;{
            props.diagnosis.personalFirstNameFurigana
          }
        </div>
      </div>
      <div className="number_of_diag display-table column column-before is-1">
        <div className="display-table-inner">
          <div className={isFirstVisit ? 'first-visit' : 'secondtime-onwards'}>
            {props.diagnosis.numberOfDiagnosis}
          </div>
        </div>
      </div>
      <div className="desired_price_plan display-table column column-before is-1">
        <div className="display-table-inner">
          <div className={props.diagnosis.desiredPricePlan === 'おまとめ' ? 'desired-price-plan color-blue' : 'desired-price-plan'}>
            {props.diagnosis.desiredPricePlan}
          </div>
        </div>
      </div>
      <div className="display-table column column-before is-1"></div>
      <div className="column column-before columns is-7">
        <div className="display-table column column-before">
          <div className="date_video_info display-table-inner">
            <span>
              問診完了日時：
              {dateFullFormat(props.diagnosis.medicalInterviewEndDatetimeJst)}
              &nbsp;{timeFormat(props.diagnosis.medicalInterviewEndDatetimeJst)}
              <br />
              ビデオ診療：&nbsp;
              {props.diagnosis.diagnosisScheduledStartDatetimeJst ?
                <span>
                  {dateFullFormat(props.diagnosis.diagnosisScheduledStartDatetimeJst)}
                  &nbsp;
                  {timeFormat(props.diagnosis.diagnosisScheduledStartDatetimeJst)}
                  〜
                  {timeFormat(props.diagnosis.diagnosisScheduledEndDatetimeJst)}
                </span>
                : ''}
              <span className={props.diagnosis.videoDiagnosisStatusId === 0 ? 'has-red-text' : 'has-black-text'}>
                {props.diagnosis.videoDiagnosisStatusName}
              </span>
            </span>
          </div>
        </div>
        <div className="doctor display-table column column-before">
          {props.diagnosis.doctorPersonalFamilyName ?
            <div className="display-table-inner">
              担当医： {props.diagnosis.doctorPersonalFamilyName}&nbsp;{
                props.diagnosis.doctorPersonalFirstName
              }
            </div>
            :
            <div className="display-table-inner non-doctor">
              担当医&nbsp;未決定
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default DiagnosisCard;