import { ERR_MSG_001, ERR_MSG_004 } from "../config/constant";

function ErrorModal(props: any) {
  return (
    <div id="clinic_err_dialog" style={{ zIndex: 60 }} className="dialog">
      <div className="wrap">
        <div id="clinic_err_dialog_close" className="close" onClick={props.closeErrorModal}></div>
        <div id="clinic_err_dialog_content" className="has-text-danger has-padding-1 has-text-weight-bold error-message" style={{ paddingBottom: '30px' }}>
          <br />
          {props.errMessage ? props.errMessage : ERR_MSG_001}
          <br />
          {props.errCode ? <span><br />&lt;{ERR_MSG_004}{props.errCode}&gt;</span> : ''}
        </div>
      </div>
    </div>
  )
}

export default ErrorModal;