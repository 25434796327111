import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as bulmaToast from "bulma-toast";
import { PUBLISH_NOTICE } from "../../api/Api";
import { post } from "../../api/FetchAPI";
import PublishNoticeFrame from "./PublishNoticeFrame";
import { updateNoticeErrorCode, updateNoticeLoading, getPublishNotices } from './store/action';

function Publish() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const publishNotices = useSelector<any, any>((state) => state.notice.publishNotices);
  const noticeLoading = useSelector<any, any>((state) => state.notice.noticeLoading);

  const [checkedRowsNoticeNo, setCheckedRowsNoticeNo] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch() as any;

  //本番反映
  async function publish() {
    try {
      const param = {
        noticeNoList: checkedRowsNoticeNo
      };
      dispatch(updateNoticeLoading(true));
      const response = await post(PUBLISH_NOTICE, param, msalToken) as any;
      if (response.data.errorCode) {
        dispatch(updateNoticeErrorCode(response.data.errorCode));
      } else {
        bulmaToast.toast({
          message: `お知らせ本番反映しました`,
          position: 'center',
          type: 'is-dark',
          single: true,
        })
        setCheckedRowsNoticeNo([]);
        setSelectedRowKeys([]);
        await dispatch(getPublishNotices(msalToken));
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
    }
  }

  useEffect(() => {
    if (isManager) {
      dispatch(getPublishNotices(msalToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="content-header">
      <h1 id="noticePublishTitle">本番反映</h1>
      <div className="content">
        <form id="form">
          <div className="row form-horizontal">
            <div className="box box-info">
              <div id="noticePublishHeader" className="box-header with-border">
                <label>お知らせ</label>
              </div>
            </div>
          </div>
          <div id="noticePublishTable" className="row form-horizontal">
            <PublishNoticeFrame
              publishNotices={publishNotices}
              setCheckedRowsNoticeNo={setCheckedRowsNoticeNo}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              loading={noticeLoading}
            />
          </div>
          <div className="row form-horizontal">
            <div className="box-footer clearfix">
              <button
                id="noticePublishBtn"
                type="button"
                className="button pull-right btn-danger submit"
                disabled={checkedRowsNoticeNo.length === 0 ? true : false}
                onClick={publish}
              >
                <span>本番反映</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Publish;