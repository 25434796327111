import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePropsFor, MessageThread, SendBox } from '@azure/communication-react';
import { Persona, PersonaSize } from '@fluentui/react';
import './style/chatRoom.scss';

function ChatComponentsStateful() {
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const completedDiagnosisInfo = useSelector<any, any>((state) => state.history.completedDiagnosisInfo);
  const isHistoryClinicRoom = useSelector<any, any>((state) => state.history.isHistoryClinicRoom);

  const inputRef = useRef<HTMLDivElement>(null);
  //チャットボックスの高さ
  const [msgThreadHeight, setMsgThreadHeight] = useState(104);

  const messageThreadProps = usePropsFor(MessageThread);
  const sendBoxProps = usePropsFor(SendBox);

  //患者のACSID
  const personalACSId = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.personalACSId
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //医師のACSID
  const doctorPersonalACSId = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.doctorPersonalACSId
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //患者の画像アイコンを設定する
  const GetAvatarUrlByUserId = (userId: string) => {
    if (selectedDiagnosisCode) {
      switch (userId) {
        case personalACSId:
          return 'images/patient_avatar.png';
        case doctorPersonalACSId:
          return 'images/doctor_avatar.png';
        default:
          return '';
      }
    } else {
      switch (userId) {
        case completedDiagnosisInfo.personalACSId:
          return 'images/patient_avatar.png';
        case completedDiagnosisInfo.doctorACSId:
          return 'images/doctor_avatar.png';
        default:
          return '';
      }
    }
  };

  useEffect(() => {
    //チャットボックスの高さを計算する
    const elem = inputRef.current;
    if (elem) {
      const observer = new ResizeObserver(() => {
        setMsgThreadHeight(elem.offsetHeight + 68);
      })
      observer.observe(elem);
      return () => {
        observer.unobserve(elem)
      }
    }
  }, []);

  return (
    <>
      <div id="chat_area_msg_thread" style={isHistoryClinicRoom ? { height: 'calc(100vh - 60px)' } : { height: 'calc(100vh - ' + msgThreadHeight + 'px)' }}>
        {messageThreadProps && <MessageThread  {...messageThreadProps}
          showMessageDate={true}
          disableEditing={true}
          styles={{ chatContainer: { 'whiteSpace': 'pre-wrap' } }}
          onRenderAvatar={(userId?: string) => {
            return (
              <Persona
                size={PersonaSize.size32}
                hidePersonaDetails
                imageUrl={GetAvatarUrlByUserId(userId ?? '')}
                showOverflowTooltip={false}
              />
            );
          }}
        />}
      </div>
      {!isHistoryClinicRoom ?
        <div id="chat_area_send_box" ref={inputRef}>
          {sendBoxProps && <SendBox {...sendBoxProps}
            disabled={isDoctor ? false : true}
            supportNewline={true}
          />}
        </div>
        : ''}
    </>
  )
}

export default ChatComponentsStateful;