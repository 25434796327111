import { useSelector } from "react-redux";
import { ERR_MSG_003 } from "../../../config/constant";

function ClinicRoomErrorPage() {
  const clinicErrCode = useSelector<any, any>((state) => state.clinic.clinicErrCode);
  return (
    <div id="wait-loading" className="column is-two-thirds padding diagnosis">
      <div className="content" style={{ margin: '40px', position: 'relative' }}>
        <h1 id="errMsg" className="has-margin-1" style={{ paddingTop: '0px' }}>
          {ERR_MSG_003}
          <span><br />&#60;エラーコード：{clinicErrCode}&#62;</span>
        </h1>
      </div>
    </div>
  )
}

export default ClinicRoomErrorPage;