import { useDispatch, useSelector } from 'react-redux';
import { setShowVideoRelatedModalIndex } from './store/action';
import { sendSentryLog } from '../../../utils/SentryFuc';

function VideoTimeErrorModal() {
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const dispatch = useDispatch() as any;

  function closeVideoExpiredDialog() {
    //sentry log
    sendSentryLog('ビデオのリンクが切れるダイアログを閉じる', 'closeVideoExpiredDialog', 'log',
      { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(setShowVideoRelatedModalIndex(0));
  }

  return (
    <div id='videoExpiredDialog' className="dialog">
      <div className="wrap">
        <div id='closeVideoExpiredDialog' className="close" onClick={() => { closeVideoExpiredDialog() }}></div>
        <div className="inner">
          <p id='videoExpiredDialogTitle' className="read">ビデオのリンクが切れています。</p>
          <p id='videoExpiredDialogText' className="sentence">「ビデオ入室」ボタンを押下のうえ、「新たにビデオリンクを発行し入室する」ボタンより新規のビデオリンクを発行し、ビデオに入室してください。</p>
          <p id='videoExpiredDialogComment' className="sentence color-gray m-t-10">※患者には新たに発行したビデオリンクに入室するようお伝えください</p>
        </div>
      </div>
    </div>
  )
}

export default VideoTimeErrorModal;