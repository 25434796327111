//データフォーマット:
const defaultState = ({
	// 氏名
	msalName: "",
	// メールアドレス
	msalMail: "",
	// 認証ステータス
	msalAuthenticated: false,
	// msal token
	msalToken: "",
	// 認証有効期限（UTC）
	msalExpiresOn: "",
	// 管理ツールログインステータス
	isLoginable: false,
	// ログイン後の遷移URL
	redirect: "",
	// 管理者flag
	isManager: false,
	// 医師flag
	isDoctor: false,
	// 配送者flag
	isDeliver: false,
	// ヘッダの操作が許可されているかどうか
	isAllowClick: false,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
	switch (action.type) {
		case 'set_manager':
			return { ...state, isManager: action.flag }
		case 'set_doctor':
			return { ...state, isDoctor: action.flag }
		case 'set_deliver':
			return { ...state, isDeliver: action.flag }
		case 'set_loginable':
			return { ...state, isLoginable: action.flag }
		case 'set_redirect':
			return { ...state, redirect: action.redirect }
		case 'set_msal_token':
			return { ...state, msalToken: action.token }
		case 'set_msal_expires_on':
			return { ...state, msalExpiresOn: action.expiresOn }
		case 'set_msal_name':
			return { ...state, msalName: action.name }
		case 'set_msal_mail':
			return { ...state, msalMail: action.mail }
		case 'set_msal_authenicated':
			return { ...state, msalAuthenticated: action.isAuthenticated }
		case 'set_allow_click':
			return { ...state, isAllowClick: action.flag }
		default:
			return state;
	}
}

export default variable;
