// データフォーマット:					
const defaultState = ({
  //輸送方法オプション
  shippingMethodOptions: [
    { text: 'ネコポス', value: 1 },
    { text: 'クリックポスト', value: 2 },
  ],
  //輸送方法
  shippingMethod: 1,
  //ダウンロード可能性
  isDownloadable: {},
  //ローディング
  isDownloadLoading: false,
  //エラーコード
  downloadErrorCode: null,
  //エラーメッセージ
  downloadErrorMessage: '',
  //CSVダウンロード制御ローディング
  deliveryLoading: true,
  //配送管理認証ローディング
  deliveryAuthLoading: true,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'update_shipping_method':
      return { ...state, shippingMethod: action.value }
    case 'set_download_possibility':
      return { ...state, isDownloadable: action.value }
    case 'update_download_loading':
      return { ...state, isDownloadLoading: action.flag }
    case 'update_download_error_code':
      return { ...state, downloadErrorCode: action.code }
    case 'update_download_error_msg':
      return { ...state, downloadErrorMessage: action.msg }
    case 'update_delivery_loading':
      return { ...state, deliveryLoading: action.flag }
    case 'update_delivery_auth_loading':
      return { ...state, deliveryAuthLoading: action.flag }
    default:
      return state;
  }
}

export default variable;
