// データフォーマット:
const defaultState = ({
  // 処方せず診療終了理由マスタ データ
  withoutPrescribingReason: [],
  // 処方・診療終了ダイアログ表示flag
  showEndDiagnosisModal: false,
  // 処方せず終了理由選択ダイアログ表示flag
  showWithoutPrescribingReasonsModal: false,
  // 処方あり診療終了ダイアログ表示flag
  showConfirmMedicineModal: false,
  //診療終了エラーコード
  endDiagnosisErrorCode: null,
  //診療終了エラーメッセージ
  endDiagnosisErrorMessage: '',
  //APIでデータを取得完了後trueになる
  endDiagnosisLoading: false,
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'update_end_diagnosis_modal':
      return { ...state, showEndDiagnosisModal: action.flag }
    case 'update_without_reasons_modal':
      return { ...state, showWithoutPrescribingReasonsModal: action.flag }
    case 'update_confirm_medicine_modal':
      return { ...state, showConfirmMedicineModal: action.flag }
    case 'set_reasons':
      return { ...state, withoutPrescribingReason: action.reasons }
    case 'update_end_diagnosis_err_code':
      return { ...state, endDiagnosisErrorCode: action.errCode }
    case 'update_end_diagnosis_err_message':
      return { ...state, endDiagnosisErrorMessage: action.errMessage }
    case 'update_end_diagnosis_loading':
      return { ...state, endDiagnosisLoading: action.flag }
    default:
      return state;
  }
}

export default variable;