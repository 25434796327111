import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { CommunicationUserIdentifier, AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  CallComposite,
  fromFlatCommunicationIdentifier,
  CallAdapter,
  createStatefulCallClient,
  createAzureCommunicationCallAdapterFromClient,
} from "@azure/communication-react";
import { setShowVideoRelatedModalIndex, updateCallAgent } from '../clinicRoom/store/action';
import './style/video.scss';
import { SENTRT_CONTEXT_ID } from '../../../config/constant';
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';
import { sendVideoUrl } from "../../../utils/VideoFuc";

function VideoWindow(props: any) {
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const personalAcsId = useSelector<any, any>((state) => state.chat.personalAcsId);
  const personalAcsToken = useSelector<any, any>((state) => state.chat.personalAcsToken);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const videoGroupId = useSelector<any, any>((state) => state.video.videoGroupId);
  const videoToken = useSelector<any, any>((state) => state.video.videoToken);
  const issueNewLink = useSelector<any, any>((state) => state.video.issueNewLink);
  const callAgent = useSelector<any, any>((state) => state.clinic.callAgent);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const diagnosisScheduledStartDatetimeJst = useSelector<any, any>((state) => state.clinic.diagnosisScheduledStartDatetimeJst);

  const [callAdapter, setCallAdapter] = useState<CallAdapter>();

  const dispatch = useDispatch() as any;
  const callAgentRef = useRef(callAgent);
  const videoDialogNodeRef = useRef(null);

  const groupId = videoGroupId;

  const sentryContextVideo: sentryContext = {
    contextID: SENTRT_CONTEXT_ID,
    info: {
      groupId: groupId,
      threadId: props.chatThreadClient.threadId,
    }
  };

  const CallControlOptions = {
    legacyControlBarExperience: true,
    participantsButton: false,
    screenShareButton: false,
    raiseHandButton: false,
    moreButton: false,
  };

  //コールコンポジットオプション
  const CallCompositeOptions = {
    callControls: CallControlOptions
  };

  //ビデオダイアログを閉じる
  function closeVideoDialog() {
    // ビデオダイアログ右上の「X」を押して閉じてもブラウザタブの音声アイコンが消えるようにする
    if (callAdapter){
      callAdapter.leaveCall();
    }
    //sentry log
    sendSentryLog('ビデオダイアログを閉じる', 'closeVideoDialog', 'log',
      { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
      , [sentryContextVideo]
    );
    dispatch(setShowVideoRelatedModalIndex(0));
  }

  //ビデオリンクを発送
  useEffect(() => {
    if (issueNewLink && videoToken !== null) {
      sendVideoUrl(msalName, videoToken, selectedDiagnosisCode, diagnosisScheduledStartDatetimeJst,
        personalInfoDetail.personalUid, sentryContextVideo, props.chatThreadClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoToken]);

  //適切な形式のトークンがなければ、アダプターを初期化することさえできません。
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(personalAcsToken)
    } catch {
      console.error("Failed to construct token credential");
      return undefined;
    }
  }, [personalAcsToken]);

  //adapterを作成する
  useEffect(() => {
    const createAdapter = async (): Promise<void> => {
      const callClient = createStatefulCallClient({
        userId: fromFlatCommunicationIdentifier(personalAcsId) as CommunicationUserIdentifier,
      });
      //callAgentが存在する場合は廃棄します
      if (callAgent !== undefined) {
        await callAgent.dispose();
        await dispatch(updateCallAgent(undefined));
      }
      const newCallAgent = await callClient.createCallAgent(new AzureCommunicationTokenCredential(personalAcsToken), { displayName: msalName });
      dispatch(updateCallAgent(newCallAgent));
      const callAdapter = await createAzureCommunicationCallAdapterFromClient(callClient, newCallAgent, { groupId });
      //ビデオ終了のアクション
      callAdapter.on('callEnded', () => {
        //sentry log
        sendSentryLog('ビデオダイアログを閉じる(callEnded)', 'closeVideoDialog', 'log',
          { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextVideo]
        );
        dispatch(setShowVideoRelatedModalIndex(0));
      });
      setCallAdapter(callAdapter);
    };
    createAdapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAcsId, personalAcsToken, msalName, groupId]);

  useEffect(() => {
    //sentry log
    sendSentryLog('ビデオダイアログが開く', 'openVideoDialog', 'log',
      { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
      , [sentryContextVideo]
    );
    //一分以上待ってもビデオ通話ができない
    const timer = setTimeout(() => {
      if (callAgentRef.current === undefined) {
        sendSentryLog('30S以上待ってもビデオ通話が繋がらい', 'waitVideoOver30Sec', 'log',
          { 'event_name': 'start_video', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextVideo]
        );
      }
    }, 30 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callAgentRef.current = callAgent;
  }, [callAgent]);

  return (
    <Draggable nodeRef={videoDialogNodeRef} bounds={"body"}>
      <div id="videoDialog" className="video_dialog" ref={videoDialogNodeRef}>
        <div id="closeVideoDialog" className="close" onClick={() => { closeVideoDialog() }}></div>
        <p id="videoDialogTitle" className="read width">
          ビデオ
        </p>
        <div id="videoContent" style={{ height: '80vh' }}>
          {callAdapter ? <CallComposite adapter={callAdapter} formFactor={"desktop"} options={CallCompositeOptions} /> : ''}
        </div>
        {credential === undefined ? <>Failed to construct credential. Provided token is malformed.</> : ''}
        {!callAdapter ? <>
          <div className="loading">
            <b>Loading...</b>
            <br /><br />
            1分以上ビデオ画面が表示しない場合、通信失敗の可能性があるので、時間をおいてから再度お試しください。
          </div>
        </> : ''}
      </div>
    </Draggable>
  )
}

export default VideoWindow;