// データフォーマット:
const defaultState = ({
  // 患者情報
  personalInfoDetail: {},
  // 問診結果情報
  medicalInterviews: [],
  // 診療履歴
  diagnosisHistories: [],
  // ビデオ診療ステータスID
  videoDiagnosisStatusId: 0,
  // 診療予定開始日時
  diagnosisScheduledStartDatetimeJst: null,
  //診療予定終了日時
  diagnosisScheduledEndDatetimeJst: null,
  // 禁忌・慎重投与情報
  isHasAnyInformation: false,
  // 処方薬マスタ
  medicines: [],
  // ビデオ日時設定ダイアログ表示flag
  showVideoScheduleModal: false,
  // 処方薬提案ダイアログ表示flag
  showPrescriptionProposalModal: false,
  //診療履歴コメント表示flag
  showCommentModal: false,
  //選択したコメント
  selectedComment: '',
  //診療室エラーコード
  clinicErrCode: null,
  //ビデオ日時をDBに設定エラーコード
  videoDiagnosisErrCode: null,
  //ビデオ関連ダイアログflag
  showVideoRelatedModalIndex: 0,
  //ビデオ時間と処方薬提案によって変化された随机数
  clinicRandomNumber: 1,
  //提案中薬剤製品名
  proposeMedicineProductName: null,
  //提案中プラン
  proposePricePlan: null,
  // 希望する曜日・時間帯
  desiredVideoTime: null,
  //コールエージェント
  callAgent: undefined
})

// stateのデータ処理
const variable = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'set_diagnosis_personal_info':
      return { ...state, personalInfoDetail: action.info }
    case 'set_medical_interview':
      return { ...state, medicalInterviews: action.info }
    case 'set_diagnosis_history':
      return { ...state, diagnosisHistories: action.info }
    case 'update_comment_modal':
      return { ...state, showCommentModal: action.flag }
    case 'update_selected_comment':
      return { ...state, selectedComment: action.comment }
    case 'set_clinic_err_code':
      return { ...state, clinicErrCode: action.errCode }
    case 'update_propose_midicine_modal':
      return { ...state, showPrescriptionProposalModal: action.flag }
    case 'set_medicines':
      return { ...state, medicines: action.list }
    case 'update_video_schedule_modal':
      return { ...state, showVideoScheduleModal: action.flag }
    case 'update_video_diagnosis_err_code':
      return { ...state, videoDiagnosisErrCode: action.errCode }
    case 'update_video_related_modal_index':
      return { ...state, showVideoRelatedModalIndex: action.index }
    case 'update_video_status_id':
      return { ...state, videoDiagnosisStatusId: action.videoStatusId }
    case 'set_diagnosis_start_time':
      return { ...state, diagnosisScheduledStartDatetimeJst: action.time }
    case 'set_diagnosis_end_time':
      return { ...state, diagnosisScheduledEndDatetimeJst: action.time }
    case 'update_clinic_random_number':
      return { ...state, clinicRandomNumber: action.number }
    case 'update_propose_medicine_product_name':
      return { ...state, proposeMedicineProductName: action.name }
    case 'update_propose_price_plan':
      return { ...state, proposePricePlan: action.plan }
    case 'update_call_agent':
      return { ...state, callAgent: action.agent }
    case 'update_desired_video_time':
      return { ...state, desiredVideoTime: action.desiredVideoTime }
    default:
      return state;
  }
}

export default variable;