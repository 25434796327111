import axios from 'axios';
import qs from 'qs';

// API get 関数
export function get(url: any, data: any, token: any) {
  const configs = {
    headers: { 'Content-Type': 'application/json' },
    params: data,
  } as any;
  configs.paramsSerializer = (params: any) =>
    qs.stringify(params, { arrayFormat: 'repeat' })

  // Set token
  configs.headers.Authorization = 'Bearer ' + token;

  return new Promise((resolve, reject) => {
    axios.get(url, configs).then(res => {
      resolve(res);
    })
      .catch(err => {
        const response = err.response || err;
        const data = response.data || response;
        const error = data.error || data;
        reject(error);
      });
  });
}

// API post 関数
export function post(url: any, data: any, token: any) {
  const configs = {
    headers: { 'Content-Type': 'application/json' }
  } as any;

  // Set token
  configs.headers.Authorization = 'Bearer ' + token;

  return new Promise((resolve, reject) => {
    axios.post(url, data, configs).then(res => {
      resolve(res);
    })
      .catch(err => {
        const response = err.response || err;
        const data = response.data || response;
        const error = data.error || data;
        reject(error);
      });
  });
}