export const sortByMessageDate = (patientList: any) => {
  patientList.sort(function (a: any, b: any) {
    if (a.chatInfo.lastChatSendDatetimeJst === "" || a.chatInfo.lastChatSendDatetimeJst === null) {
      return 1;
    }
    if (b.chatInfo.lastChatSendDatetimeJst === "" || b.chatInfo.lastChatSendDatetimeJst === null) {
      return -1;
    }
    return Date.parse(b.chatInfo.lastChatSendDatetimeJst) - Date.parse(a.chatInfo.lastChatSendDatetimeJst);
  })
  return patientList;
}
