import { BASE_URL } from '../config';

// グループ情報一覧取得API
export const GROUP_LIST = BASE_URL + 'v1/get-group-list';
//診療情報検索API
export const DIAGNOSIS_INFO = BASE_URL + 'v2/search-diagnosis-list';
//ACSトーケン更新API
export const REFRESH_ACS_TOKEN = BASE_URL + 'v1/refresh-acs-token';
//ACSチャット情報取得 API
export const ACS_CHAT_INFO = BASE_URL + 'v1/get-acs-chat-info';
//スレッドIDに基づいて診療情報取得API
export const DIAGNOSIS_INFO_BY_THEAD_ID = BASE_URL + 'v1/get-acs-diagnosis-info';
//患者情報取得API
export const DIAGNOSIS_PERSONAL_INFO_DETAIL = BASE_URL + 'v1/get-diagnosis-personal-info-detail';
//問診結果情報取得API
export const MEDICAL_INTERVIEW_DETAIL = BASE_URL + 'v1/get-medical-interview-result-detail';
//診療履歴取得API
export const DIAGNOSIS_HISTORY = BASE_URL + 'v1/get-diagnosis-history-list';
//処方せず診療終了理由マスタ取得API
export const WITHOUT_PRESCRIBING_REASONS = BASE_URL + 'v1/get-without-prescribing-reason-master';
//処方せず診療終了更新API
export const END_DIAGNOSIS_WITHOUT_PRESCRIBING = BASE_URL + 'v2/put-end-diagnosis-without-prescribe';
//おまとめプラン 6シート診療終了更新API
export const END_DIAGNOSIS_SIXSUMMARYPLAN = BASE_URL + 'v2/put-end-diagnosis-sixsummaryplan';
//定期プラン診療終了更新API
export const END_DIAGNOSIS = BASE_URL + 'v3/put-end-diagnosis';
//ビデオ診療情報登録更新API
export const VIDEO_DIAGNOSIS = BASE_URL + 'v1/put-video-diagnosis';
//ビデオ診療開始API
export const START_VIDEO = BASE_URL + 'v1/start-video';
//ACSビデオ情報取得API
export const ACS_VIDEO_INFO = BASE_URL + 'v1/get-acs-video-info';
//処方薬取得API
export const MEDICINE_LIST = BASE_URL + 'v3/get-medicine-list';
//終了状態診療情報取得API
export const COMPLETED_DIAGNOSIS_INFO = BASE_URL + 'v1/get-completed-diagnosis-info';
//ビデオ診療情報詳細取得API
export const VIDEO_DIAGNOSIS_INFO = BASE_URL + 'v1/get-video-diagnosis-detail';
//提案処方薬登録更新API
export const PROPOSE_MEDICINE = BASE_URL + "v1/put-propose-medicine";
//医師マスタデータ取得API
export const DOCTOR_MASTER = BASE_URL + "v1/get-doctor-master";
//患者情報閲覧履歴保存API
export const SAVE_BROWSE_HISTORY = BASE_URL + "v1/save-patient-info-browse-history";
//提案薬情報取得API
export const PROPOSE_MEDICINE_INFO = BASE_URL + "v1/get-propose-medicine-info";
//診療未担当更新API
export const UNASSIGNED_DIAGNOSIS = BASE_URL + "v2/put-unassigned-diagnosis";

//配送管理
//配送CSVダウンロード制御API
export const DOWNLOAD_POSSIBILITY = BASE_URL + "v1/get-download-possibility";
//定期プラン配送CSV取得API
export const DELIVERY_CSV_REGULAR = BASE_URL + "v1/get-delivery-csv";
//おまとめプラン 6シート配送CSV取得API
export const DELIVERY_CSV_SUMMARY = BASE_URL + "v1/get-delivery-csv-sixsummaryplan";

//お知らせ
//お知らせ検索API
export const SEARCH_NOTICE = BASE_URL + "v1/search-notice";
//CSVファイルアップロードAPI
export const CSV_UPLOAD = BASE_URL + "v1/upload-notice-specificuser-csv";
//CSVファイルダウンロードAPI
export const CSV_DOWNLOAD = BASE_URL + "v1/download-notice-specificuser-csv";
//バナ画像アップロードAPI
export const BANNER_UPLOAD = BASE_URL + "v1/upload-notice-banner-image";
//お知らせ詳細取得API
export const NOTICE_DETAIL = BASE_URL + "v1/get-notice-detail";
//お知らせ登録API
export const PUT_NOTICE = BASE_URL + "v1/put-notice";
//お知らせ更新API
export const UPDATE_NOTICE = BASE_URL + "v1/update-notice";
//お知らせ本番反映API
export const PUBLISH_NOTICE = BASE_URL + "v1/publish-notice";