import { isAfter, parseISO } from "date-fns";
import { get } from '../../../api/FetchAPI';
import { ERR_CODE_A001 } from '../../../config/constant';
import { setApiErrorCode } from '../../diagnosing/chatList/store/action';
import { setPersonalInfoDetail, setMedicalInterview, setDiagnosisHistory, updateDesiredVideoTime } from '../../diagnosing/clinicRoom/store/action';
import { setPersonalAcsId, setPersonalAcsToken, setPersonalAcsTokenExpiredOn, refreshAcsToken } from '../../diagnosing/chatList/store/action';
import { DIAGNOSIS_PERSONAL_INFO_DETAIL, MEDICAL_INTERVIEW_DETAIL, DIAGNOSIS_HISTORY, VIDEO_DIAGNOSIS_INFO, COMPLETED_DIAGNOSIS_INFO, DIAGNOSIS_INFO } from '../../../api/Api';
import { pages } from "../../../utils/ComputeDiagnosisSearchResult";

// 以下、内部用reduxのデータ処理
//ビデオ診療情報をReducersに保存
const setCompletedVideoDiagnosisInfo = (info: any) => ({
  type: 'set_completed_video_diagnosis_info',
  info
})

//終了状態診療情報をReducersに保存
const setCompletedDiagnosisInfo = (info: any) => ({
  type: 'set_completed_diagnosis_info',
  info
})

// 以下、外部用reduxのデータ処理
export const updateCompletedLoading = (flag: boolean) => ({
  type: 'update_completed_loading',
  flag
})

export const updateSearchHistoryLoading = (loading: boolean) => ({
  type: 'update_search_history_loading',
  loading
})

export const setHistoryClinicRoomFlag = (flag: boolean) => ({
  type: 'set_history_clinic_room_flag',
  flag
})

export const setCompletedDiagnoses = ((list: any) => ({
  type: 'set_completed_diagnoses',
  list
}))

export const updateCompletedPages = (page: object) => ({
  type: 'update_completed_pages',
  page
})

export const updateCompletedPagesNow = (now: number) => ({
  type: 'update_completed_pages_now',
  now
})

export const updateSearchHistoryMode = (flag: boolean) => ({
  type: 'update_search_history_mode',
  flag
})

//診療終了診療室の情報を取得
export const getCompletedAllPersonalDetail = (auth: string, diagnosisCode: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(updateCompletedLoading(true));
      let response1 = [] as any;
      let response2 = [] as any;
      let response3 = [] as any;
      let response4 = [] as any;
      let response5 = [] as any;
      const params = {
        diagnosisCode: diagnosisCode
      };
      const requests = [
        get(DIAGNOSIS_PERSONAL_INFO_DETAIL, params, auth),
        get(MEDICAL_INTERVIEW_DETAIL, params, auth),
        get(DIAGNOSIS_HISTORY, params, auth) as any,
        get(VIDEO_DIAGNOSIS_INFO, params, auth),
        get(COMPLETED_DIAGNOSIS_INFO, params, auth)
      ];
      [response1, response2, response3, response4, response5] = await Promise.all(requests);
      if (response1.data.errorCode || response2.data.errorCode || (response3.data.errorCode && response3.data.errorCode !== ERR_CODE_A001) || response4.data.errorCode || response5.data.errorCode) {
        await dispatch(setApiErrorCode(response1.data.errorCode ?? response2.data.errorCode ?? response3.data.errorCode ?? response4.data.errorCode ?? response5.data.errorCode))
      } else {
        if (response1.data.errorCode === null) {
          await dispatch(setPersonalInfoDetail(response1.data));
        }
        if (response2.data.errorCode === null) {
          await dispatch(setMedicalInterview(response2.data.medicalInterviewQuestions));
          // 希望する曜日・時間帯の設定
          const desiredVideoTime = response2.data.desiredDiagnosisTime;
          if (desiredVideoTime !== null && desiredVideoTime !== undefined && desiredVideoTime.length > 0) {
            await dispatch(updateDesiredVideoTime(desiredVideoTime.split(',')));
          } else {
            await dispatch(updateDesiredVideoTime(null));
          }
        }
        if (response3.data.errorCode === ERR_CODE_A001) {
          await dispatch(setDiagnosisHistory([]));
        }
        if (response3.data.errorCode === null) {
          await dispatch(setDiagnosisHistory(response3.data.diagnosisHistories));
        }
        if (response4.data.errorCode === null) {
          await dispatch(setCompletedVideoDiagnosisInfo(response4.data));
        }
        if (response5.data.errorCode === null) {
          await dispatch(setCompletedDiagnosisInfo(response5.data.diagnosis))
          await dispatch(setPersonalAcsId(response5.data.personalAcsId));
          const expired = isAfter(new Date(), parseISO(response5.data.personalAcsTokenExpiredDTJst));
          //トーケン期限切いない
          if (!expired) {
            await dispatch(setPersonalAcsToken(response5.data.personalAcsToken));
            await dispatch(setPersonalAcsTokenExpiredOn(response5.data.personalAcsTokenExpiredDTJst));
          } else {
            //ACSトーケンと有効期限の更新
            const acsToken = await refreshAcsToken(auth, dispatch);
            if (acsToken !== null) {
              dispatch(setPersonalAcsToken(acsToken));
            }
          }
        }
      }
      dispatch(setHistoryClinicRoomFlag(true));
      dispatch(updateCompletedLoading(false));
    } catch (e: any) {
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//診療終了ページに一覧情報を取得
export const getCompletedDiagnoses = (auth: string, pageNumber: number, navigate: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        mode: 2,
        sortItem: 2,
        sortType: 1,
        pageNo: pageNumber,
        count: 20,
      };
      const response = await get(DIAGNOSIS_INFO, params, auth) as any;
      if (response.data.errorCode === ERR_CODE_A001 && pageNumber === 1) {
        const page = pages(0, 1);
        dispatch(updateCompletedPages(page));
      } else if (response.data.errorCode === ERR_CODE_A001 && pageNumber !== 1) {
        navigate("/404");
      } else if (response.data.errorCode) {
        dispatch(setApiErrorCode(response.data.errorCode));
      } else {
        dispatch(setCompletedDiagnoses(response.data.diagnoses));
        let max = Math.ceil(response.data.totalCount / 20);
        if (max === 0) max = 1;
        const page = pages(pageNumber, max);
        if ((pageNumber ?? 1) > max) {
          navigate("/404");
        }
        dispatch(updateCompletedPages(page));
      }
      dispatch(updateSearchHistoryMode(false));
      dispatch(updateCompletedLoading(false));
      dispatch(updateSearchHistoryLoading(false));
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//診療終了ページに検索情報を取得
export const searchCompletedDiagnoses = (auth: string, surname: string, givenName: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        mode: 3,
        surname: surname,
        givenName: givenName ?? surname,
        diagnosisState: 2,
        assignStatus: 0,
        sortItem: 2,
        sortType: 1,
      };
      const response = await get(DIAGNOSIS_INFO, params, auth) as any;
      if (response.data.errorCode === ERR_CODE_A001) {
        dispatch(setCompletedDiagnoses([]));
      } else if (response.data.errorCode) {
        dispatch(setApiErrorCode(response.data.errorCode));
      } else {
        dispatch(setCompletedDiagnoses(response.data.diagnoses));
      }
      dispatch(updateSearchHistoryMode(true));
      dispatch(updateCompletedLoading(false));
      dispatch(updateSearchHistoryLoading(false));
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}