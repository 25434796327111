import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

export const videoTimeFormat = (startDate: string, endDate: string) => {
  return (
    format(parseISO(startDate), 'yyyy/MM/dd HH:mm', { locale: ja }) +
    '-' +
    format(parseISO(endDate), 'HH:mm', { locale: ja })
  )
}

export const dateFormat = (time: any) => {
  if (!time) {
    return
  }
  return format(parseISO(time), 'yyyy/MM/dd', { locale: ja })
}

export const timeFormat = (time: string) => {
  if (!time) {
    return ''
  }
  return format(parseISO(time), 'HH:mm', { locale: ja })
}

export const jstTimeFormat = (time: any) => {
  return (
    format(time, 'yyyy-MM-dd', { locale: ja }) +
    'T' +
    format(time, 'HH:mm:ss', { locale: ja })
  )
}

export const setAfterHalfHour = () => {
  const now = new Date();
  if (now.getMinutes() < 30) {
    now.setMinutes(30, 0, 0);
  } else {
    now.setHours(now.getHours() + 1);
    now.setMinutes(0, 0, 0);
  }
  return now;
}

export const setBeforeHalfHour = () => {
  const now = new Date();
  if (now.getMinutes() < 30) {
    now.setMinutes(0, 0, 0);
  } else {
    now.setMinutes(30, 0, 0);
  }
  return now;
}

// 汎用性は後から考える
export const dateFullFormat = (time: any) => {
  if (!time) {
    return null
  }
  return format(parseISO(time), 'yyyy/MM/dd(E)', { locale: ja })
}

//UTC時間は日本時間を変換
export const convertStringUtcToJst = (utcDate: string) => {
  const dateJst = new Date(new Date(utcDate).getTime() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
  const dateFormatJst = jstTimeFormat(dateJst);
  return dateFormatJst;
}