import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import '../diagnosing/clinicRoom/style/clinic.scss';
import { PROPOSE_PRICE_PLAN_001, PROPOSE_PRICE_PLAN_002, PROPOSE_SHEET_001, PROPOSE_SHEET_002, DESIRED_VIDEO_TIME_ITEM } from '../../config/constant';
import MedicalInterviewTab from "../diagnosing/clinicRoom/MedicalInterviewTab";
import PersonalInfoTab from "../diagnosing/clinicRoom/PersonalInfoTab";
import DiagnosisHistoryTab from "../diagnosing/clinicRoom/DiagnosisHistoryTab";
import CommentModal from "../diagnosing/clinicRoom/CommentModal";
import { dateFullFormat, timeFormat } from "../../utils/TimeFormat";

function HistoryClinicRoom(props: any) {
  const showCommentModal = useSelector<any, any>((state) => state.clinic.showCommentModal);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const videoDiagnosisInfo = useSelector<any, any>((state) => state.history.videoDiagnosisInfo);
  const completedDiagnosisInfo = useSelector<any, any>((state) => state.history.completedDiagnosisInfo);
  const desiredVideoTime = useSelector<any, any>((state) => state.clinic.desiredVideoTime);

  //タブ：問診、患者情報、診療履歴
  const [tab, setTab] = useState('medicalInterview');
  const HighAge = 34;

  //ビデオ開始日時
  const videoStart = videoDiagnosisInfo.diagnosisScheduledStartDatetimeJst;
  //ビデオ終了日時
  const videoEnd = videoDiagnosisInfo.diagnosisScheduledEndDatetimeJst;

  //ビデオ診療日時の書式設定
  const videoTime = useMemo(() => {
    if (!videoStart || !videoEnd) {
      return undefined;
    }
    return (
      dateFullFormat(videoStart) +
      ' ' +
      timeFormat(videoStart) +
      ' 〜 ' +
      timeFormat(videoEnd)
    )
  }, [videoStart, videoEnd]);

  //タブラベルの切り替え
  function changeTabName(tabName: string) {
    setTab(tabName);
  }

  return (
    <>
      {showCommentModal ? <CommentModal /> : ''}
      <div id="histoyr_clinic_room" className="column is-two-thirds padding diagnosis" style={{ width: 'calc(66.6666% - 70px)' }}>
        <div className="has-padding-1 has-margin-1 space-between-block" style={{ position: 'relative' }}>
          <div>
            <span id="history_clinicName" className="vertical-align-middle is-size-4 has-text-weight-bold">
              {personalInfoDetail.personalFamilyName + ' '}
              {personalInfoDetail.personalFirstName}
            </span>
            <span id="history_clinicNameFuri" className="vertical-align-middle">
              ({personalInfoDetail.personalFamilyNameFurigana + ' '}
              {personalInfoDetail.personalFirstNameFurigana})
            </span>
            <span id="history_clinicAge" className={personalInfoDetail.personalAge >= HighAge ? "vertical-align-middle has-margin-left-right-1 has-high-age" : "vertical-align-middle has-margin-left-right-1"}>
              年齢：<span id="history_clinicAgeNumber" className={personalInfoDetail.personalAge >= HighAge ? "has-font-size-16" : ""}>{personalInfoDetail.personalAge}</span>歳
            </span>
            <div>
              <span className="clinic-uid">
                患者ID：<span>{personalInfoDetail.personalUid}</span>
              </span>
              <span className="clinic-diagnosis-code">
                診療ID：<span>{props.diagnosisCode}</span>
              </span>
            </div>
          </div>
        </div>
        <div id="history_clinic_drug" className="graybgarea whitebgarea space-between-block" style={{ position: 'relative' }}>
          <div className="field is-horizontal is-align-items-center w100">
            <div>
              <label id="history_clinicDrugTitle" className="label">
                処方薬
              </label>
            </div>
            <div className="field-body is-align-items-center is-justify-content-space-between">
              <div className="list">
                <table>
                  <tbody>
                    <tr>
                      <th id="history_clinicDesiredPlanTitle">希望プラン</th>
                      {
                        completedDiagnosisInfo.desiredPricePlan === '定期'
                          ? <td id="history_clinicDesiredPlan">{PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001}</td>
                          : <td id="history_clinicDesiredPlan" className="summaryplan-blue">{PROPOSE_PRICE_PLAN_002 + " " + PROPOSE_SHEET_002}</td>
                      }
                    </tr>
                    <tr>
                      <th id="history_clinicProposeMedicineTitle">提案中薬剤</th>
                      {
                        completedDiagnosisInfo.proposeMedicineProductName
                          ?
                          <>
                            {completedDiagnosisInfo.proposePricePlan === PROPOSE_PRICE_PLAN_001 + " " + PROPOSE_SHEET_001
                              ? <td id="history_clinicProposeMedicine">{PROPOSE_PRICE_PLAN_001} {completedDiagnosisInfo.proposeMedicineProductName}</td>
                              : <td id="history_clinicProposeMedicine" className="summaryplan-blue">{PROPOSE_PRICE_PLAN_002} {completedDiagnosisInfo.proposeMedicineProductName}</td>}
                          </>
                          : <td id="history_clinicProposeMedicine">未提案</td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btn_style1">
                <button
                  id="history_clinicProposeMedicineBtn"
                  disabled={true}
                  className={"disabled"}
                >
                  処方薬提案
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="history_clinic_video" className="graybgarea whitebgarea clinic-video-block" style={{ position: 'relative' }}>
          <div className="field is-horizontal is-align-items-center w100">
            <label id="history_clinicVideoTitle" className="label">
              ビデオ診療
            </label>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-right">
                  <input
                    id="history_diagnosisScheduledDatetimeInput"
                    type="text"
                    placeholder="クリックして日時を入力"
                    className={'input disable-input'}
                    value={videoTime || ''}
                    disabled={true}
                    readOnly
                  />
                  <span className="icon is-small is-right" style={{ height: '-webkit-fill-available' }}>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control is-expanded select is-normal">
                  <select
                    id="history_clinicVideoStatusSelect"
                    disabled={true}
                    value={videoDiagnosisInfo.videoDiagnosisStatusId}
                  >
                    <option value="0">未実施</option>
                    <option value="1">実施済</option>
                  </select>
                </p>
              </div>
              <div className="border"></div>
              <div className="btn_style1">
                <button
                  id="history_clinicVideoStartBtn"
                  disabled={true}
                  className="disabled"
                >ビデオ入室
                </button>
              </div>
            </div>
          </div>
          <div className="field is-horizontal is-align-items-center w100 clinic-video-field">
            <label id="clinicDesiredVideoTitle" className="label">
              希望する曜日・時間帯
            </label>
            <div className="video-desired-body">
              <div id="clinicDesiredVideoWeekday" className="video-desired-field">
              <div className={desiredVideoTime !== null && (
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_MORNING) || 
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_AFTERNOON) || 
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_NIGHT)
                ) ? 'video-desired-title blue-text' : 'video-desired-title'}>
                平日
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_MORNING) ? 
                'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                午前
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_AFTERNOON) ? 
                'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                午後
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.WEEKDAY_NIGHT) ? 
                'video-desired-item blue-text blue-item' : 'video-desired-item'}>
                夜
              </div>
              </div>
              <div id="clinicDesiredVideoWeekend" className="video-desired-field">
              <div className={desiredVideoTime !== null && (
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_MORNING) || 
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_AFTERNOON) || 
                desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_NIGHT)) ? 
                'video-desired-title red-text' : 'video-desired-title'}>
                土日祝
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_MORNING) ? 
                'video-desired-item red-text red-item' : 'video-desired-item'}>
                午前
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_AFTERNOON) ? 
                'video-desired-item red-text red-item' : 'video-desired-item'}>
                午後
              </div>
              <div className={desiredVideoTime !== null && desiredVideoTime.includes(DESIRED_VIDEO_TIME_ITEM.HOLIDAY_NIGHT) ? 
                'video-desired-item red-text red-item' : 'video-desired-item'}>
                夜
              </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal is-align-items-center w100 clinic-video-text-field">
            <div id="disiredVideoTimeText" className="video-desired-description-text">
              ※患者が希望する診療時間は以下の通り表示されています。<br />　午前：～12:00　午後：12:00～18:00　夜：18:00～
            </div>
          </div>
        </div>

        <div className="tab-parent" style={{ height: 'calc(100% - 370.5px)', position: 'relative' }}>
          <div id="history_clinicTabs" className="tabs tabs-clinic is-boxed">
            <ul>
              <li className={tab === 'medicalInterview' ? 'is-active' : ''}>
                <a id="history_clinicMedicalInterViewTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('medicalInterview') }} >
                  <span className={'tabFont'}>問診</span>
                </a>
              </li>
              <li className={tab === 'personalInfo' ? 'is-active' : ''}>
                <a id="history_clinicPersonalInfoTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('personalInfo') }} >
                  <span className={'tabFont'}>患者情報</span>
                </a>
              </li>
              <li className={tab === 'diagnosisHistory' ? 'is-active' : ''}>
                <a id="history_clinicDiagnosisHistoryTab" href="#!" onClick={(e) => { e.preventDefault(); changeTabName('diagnosisHistory') }} >
                  <span className={'tabFont'}>診療履歴</span>
                </a>
              </li>
            </ul>
          </div>
          {tab === 'medicalInterview' ? <MedicalInterviewTab /> : ''}
          {tab === 'personalInfo' ? <PersonalInfoTab /> : ''}
          {tab === 'diagnosisHistory' ? <DiagnosisHistoryTab /> : ''}
        </div>
      </div>
    </>
  )
}

export default HistoryClinicRoom;