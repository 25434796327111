function SearchErrorModal(props: any) {
  return (
    <div id="searchErrDialog" className="dialog">
      <div className="wrap">
        <div id="searchErrDialogClose" className="close" onClick={props.close}></div>
        <div className="inner searchError">
          <p id="searchErrDialogTitle" className="read">検索ワードを見直してください</p>
          <div id="searchErrDialogContent" className="has-padding-1 has-text-left has-margin-left-right-1">
            <p>ヒント</p>
            <ul>
              <li>
                姓名の間にスペースを入れて検索をするか、姓または名のみで検索してください
              </li>
              <li>姓名以外の情報（患者IDや記号）では検索できません</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchErrorModal;