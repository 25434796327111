import { DIAGNOSIS_INFO } from '../../../api/Api';
import { get } from '../../../api/FetchAPI';
import { ERR_CODE_A001 } from '../../../config/constant';
import { setApiErrorCode } from '../../diagnosing/chatList/store/action';
import { maxPage, pages } from "../../../utils/ComputeDiagnosisSearchResult";

// 以下、外部用reduxのデータ処理
export const setBeforeDiagnoses = (list: any) => ({
  type: 'set_before_diagnoses',
  list
})

export const setAssignedCount = (count: number) => ({
  type: 'set_assigned_count',
  count
})

export const setUnAssignedCount = (count: number) => ({
  type: 'set_un_assigned_count',
  count
})

export const updateSearchErrorModal = (flag: boolean) => ({
  type: 'update_search_error_modal',
  flag
})

export const updateTakeOnModal = (flag: boolean) => ({
  type: 'update_take_on_modal',
  flag
})

export const updateTakeOnDiagnosisCode = (diagnosisCode: string) => ({
  type: 'update_take_on_diagnosis_code',
  diagnosisCode
})

export const updateAlreadyTakeOnModal = (flag: boolean) => ({
  type: 'update_already_take_on_modal',
  flag
})

export const updateBeforePages = (page: object) => ({
  type: 'update_before_pages',
  page
})

export const updateBeforePagesNow = (now: number) => ({
  type: 'update_before_pages_now',
  now
})

export const updateSearchBeforeMode = (flag: boolean) => ({
  type: 'update_search_before_mode',
  flag
})

export const updateBeforeLoading = (loading: boolean) => ({
  type: 'update_before_loading',
  loading
})

export const updateSearchBeforeLoading = (loading: boolean) => ({
  type: 'update_search_before_loading',
  loading
})

export const updateTakeOnLoading = (loading: boolean) => ({
  type: 'update_take_on_loading',
  loading
})

export const updateTakeOnErrorCode = (errorCode: any) => ({
  type: 'update_take_on_error_code',
  errorCode
})

export const updateTakeOnErrorMsg = (errorMsg: any) => ({
  type: 'update_take_on_error_msg',
  errorMsg
})

export const updateBeforeRandomNumber = (number: number) => ({
  type: 'update_before_random_number',
  number
})

//診療前一覧リストの取得
export const getBeforeDiagnoses = (auth: string, pageNumber: number, baseListCount: number, navigate: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        mode: '0',
        sortItem: '0',
        sortType: '1',
        pageNo: pageNumber,
        count: baseListCount
      };
      const response = await get(DIAGNOSIS_INFO, params, auth) as any;
      if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        dispatch(updateSearchBeforeMode(false));
        const maxpage = maxPage(response.data.assignedCount, response.data.unAssignedCount, baseListCount);
        const page = pages(pageNumber, maxpage);
        if ((pageNumber ?? 1) > maxpage && response.data.errorCode !== ERR_CODE_A001) {
          navigate("/404");
        }
        dispatch(updateBeforePages(page));
        dispatch(setAssignedCount(response.data.assignedCount));
        dispatch(setUnAssignedCount(response.data.unAssignedCount));
        if (response.data.diagnoses !== null) {
          dispatch(setBeforeDiagnoses(response.data.diagnoses));
        }
      } else {
        dispatch(setApiErrorCode(response.data.errorCode));
      }
      dispatch(updateBeforeLoading(false));
      dispatch(updateSearchBeforeLoading(false));
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//診療前一覧リストの検索
export const searchBeforeDiagnosis = (auth: string, surname: string, givenName: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        mode: '3',
        surname: surname,
        givenName: givenName ?? surname,
        diagnosisState: '0',
        assignStatus: '0',
        sortItem: '0',
        sortType: '1',
      };
      const response = await get(DIAGNOSIS_INFO, params, auth) as any;
      if (response.data.errorCode === null || response.data.errorCode === ERR_CODE_A001) {
        dispatch(updateSearchBeforeMode(true));
        dispatch(setAssignedCount(response.data.assignedCount));
        dispatch(setUnAssignedCount(response.data.unAssignedCount));
        if (response.data.diagnoses !== null) {
          dispatch(setBeforeDiagnoses(response.data.diagnoses));
        } else {
          dispatch(setBeforeDiagnoses([]));
        }
      } else {
        dispatch(setApiErrorCode(response.data.errorCode));
      }
      dispatch(updateBeforeLoading(false));
      dispatch(updateSearchBeforeLoading(false));
    } catch (e: any) {
      dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}