import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import Search from './Search';
import New from './New';
import Publish from './Publish';
import ErrorModal from '../ErrorModal';
import { setNotice, updateNoticeErrorCode, updateNoticeErrorMsg } from './store/action';
import "./style/notice.scss";

function Notice() {
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const noticeErrorCode = useSelector<any, any>((state) => state.notice.noticeErrorCode);
  const noticeErrorMsg = useSelector<any, any>((state) => state.notice.noticeErrorMsg);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const defaultNotice = {
    noticeNo: '',
    noticeType: '',
    noticeTitle: '',
    noticeContent: '',
    internalManageName: '',
    noticeDisplayOrder: null,
    bannerUrl: '',
    noticeDisplayStartTime: null,
    noticeDisplayEndTime: null,
    isDeleted: false,
    specificUserFileUrl: '',
  };

  const params = useMemo(() => {
    return new URLSearchParams(window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const noticeNo = useMemo(() => {
    return params.get("noticeNo") as string;
  }, [params]);

  const isSearch = useMemo(() => {
    if (window.location.href.indexOf('/notice/search') !== -1) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const isNew = useMemo(() => {
    if (window.location.href.indexOf('/notice/new') !== -1) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const isPublish = useMemo(() => {
    if (window.location.href.indexOf('/notice/publish') !== -1) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  //「検索」ボタンのクリック事件
  function handleSearchButton() {
    navigate("/notice/search");
  }

  //「新規投稿」ボタンのクリック事件
  function handleNewButton() {
    dispatch(setNotice(defaultNotice));
    navigate("/notice/new");
  }

  //「本番反映」ボタンのクリック事件
  function handlePublishButton() {
    navigate("/notice/publish");
  }

  //エラーモードをクロース
  function closeErrorModal() {
    dispatch(updateNoticeErrorMsg(''));
    dispatch(updateNoticeErrorCode(''));
  }

  //お知らせページには管理者のみがアクセスできる
  useEffect(() => {
    if (!isManager && window.location.href.indexOf("/notice")) {
      navigate("/404");
    }
  }, [isManager, navigate]);

  return (
    <>
      {noticeErrorCode ? <ErrorModal errCode={noticeErrorCode} errMessage={noticeErrorMsg} closeErrorModal={closeErrorModal} /> : ''}
      <div className="notice-menu">
        <div id='notice_header' className="notice-buttons">
          <button
            id='notice_search_btn'
            className={isSearch ? 'button is-active' : 'button'}
            onClick={handleSearchButton}
          >
            <span className="icon is-small">
              <Icon icon="mdi:magnify" style={isSearch ? { color: "#5bb4e8" } : {}} />
            </span>
            <span>検索</span>
          </button>
          <button
            id='notice_new_btn'
            className={isNew ? 'button is-active new-icon' : 'button new-icon'}
            onClick={handleNewButton}
          >
            <span className="icon is-small">
              <Icon icon="mdi:pencil-plus" style={isNew ? { color: "#5bb4e8" } : {}} />
            </span>
            <span>新規投稿</span>
          </button>
          <button
            id='notice_publish_btn'
            className={isPublish ? 'button is-active' : 'button'}
            onClick={handlePublishButton}
          >
            <span className="icon is-small">
              <Icon icon="mdi:publish" style={isPublish ? { color: "#5bb4e8" } : {}} />
            </span>
            <span>本番反映</span>
          </button>
        </div>
        {isSearch ? <Search /> : ''}
        {isNew ? <New isNew={true} /> : ''}
        {noticeNo !== null ? <New isNew={false} /> : ''}
        {isPublish ? <Publish /> : ''}
      </div>
    </>
  )
}

export default Notice;