import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Icon } from '@iconify/react';
import { LoadingOutlined } from '@ant-design/icons';
import { updateSearchErrorModal, searchBeforeDiagnosis, getBeforeDiagnoses, updateSearchBeforeLoading, updateBeforeLoading, updateBeforeRandomNumber } from "./store/action";
import DiagnosisCardFrame from "./DiagnosisCardFrame";
import TakeOnModal from "./TakeOnModal";
import AlreadyTakeOnModal from "./AlreadyTakeOnModal";
import Pagination from "../Pagination";
import SearchErrorModal from "../SearchErrorModal";
import Loading from "../Loading";
import "./style/before.scss";

function BeforeDiagnoses(props: any) {
  const isDoctor = useSelector<any, any>((state) => state.login.isDoctor);
  const isDeliver = useSelector<any, any>((state) => state.login.isDeliver);
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const beforeDiagnoses = useSelector<any, any>((state) => state.before.beforeDiagnoses);
  const assignedCount = useSelector<any, any>((state) => state.before.assignedCount);
  const unAssignedCount = useSelector<any, any>((state) => state.before.unAssignedCount);
  const showTakeOnModal = useSelector<any, any>((state) => state.before.showTakeOnModal);
  const showAlreadyTakeOnModal = useSelector<any, any>((state) => state.before.showAlreadyTakeOnModal);
  const isSearchBeforeMode = useSelector<any, any>((state) => state.before.isSearchBeforeMode);
  const pages = useSelector<any, any>((state) => state.before.pages);
  const showSearchErrorModal = useSelector<any, any>((state) => state.before.showSearchErrorModal);
  const searchBeforeLoading = useSelector<any, any>((state) => state.before.searchBeforeLoading);
  const takeOnLoading = useSelector<any, any>((state) => state.before.takeOnLoading);
  const beforeRandomNumber = useSelector<any, any>((state) => state.before.beforeRandomNumber);

  //検索フィールド
  const [searchName, setSearchName] = useState("");
  //検索中かどうかフラグ
  const [isSearched, setIsSearched] = useState(false);
  //姓
  let surname = "";
  //名
  let givenName = "";

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  //検索フィールドstateの設定
  function handleSearchName(event: any) {
    setSearchName(event.target.value);
  }

  //姓と名を生成する
  function generateLastAndFirstName(val: string) {
    const names = val.split(/\s/).filter((v: string) => v);
    return names;
  }

  //「検索」ボタンのクリック事件
  function searchDiagnosis() {
    const names = generateLastAndFirstName(searchName);
    if (names.length < 1 || names.length > 2) {
      dispatch(updateSearchErrorModal(true));
      return
    }
    surname = names[0];
    givenName = names[1];
    //URLにページNoの取得
    const params = new URLSearchParams(window.location.search);
    const pageNo = params.get("page");
    if (pageNo) {
      if (names.length === 1) {
        window.history.replaceState('', '', '?page=' + pageNo + '&surname=' + surname);
      } else {
        window.history.replaceState('', '', '?page=' + pageNo + '&surname=' + surname + '&givenName=' + givenName);
      }
    } else {
      if (names.length === 1) {
        window.history.replaceState('', '', '?surname=' + surname);
      } else {
        window.history.replaceState('', '', '?surname=' + surname + '&givenName=' + givenName);
      }
    }
    dispatch(updateSearchBeforeLoading(true));
    dispatch(searchBeforeDiagnosis(msalToken, surname, givenName));
    setIsSearched(true);
  }

  //キーボードイベント
  function inputKeyUp(e: any) {
    if ((e.keyCode === 13 || e.key === "Enter") && searchName) {
      searchDiagnosis();
    }
  }

  //検索ワードエラーダウンロードのクロース
  function closeSearchErrorModal() {
    dispatch(updateSearchErrorModal(false));
  }

  //検索フィールドをクリア
  function clearSearchContent() {
    dispatch(updateSearchBeforeLoading(true));
    setIsSearched(false);
    setSearchName("");
    navigate("/beforeDiagnoses");
    dispatch(getBeforeDiagnoses(msalToken, 1, 20, navigate));
  }

  useEffect(() => {
    if (props.searchParams.surname) {
      setIsSearched(true);
      if (props.searchParams.givenName) {
        setSearchName(props.searchParams.surname + ' ' + props.searchParams.givenName);
      } else {
        setSearchName(props.searchParams.surname);
      }
    }
  }, [props.searchParams]);

  useEffect(() => {
    //診療前一覧の画面を定期的に更新する
    const params = new URLSearchParams(window.location.search);
    const pageNo = params.get("page") as any;
    if ((window.location.href.endsWith('/beforeDiagnoses') || (window.location.href.indexOf('/beforeDiagnoses') !== -1 && parseInt(pageNo) === 1))
      && isDoctor && !isSearchBeforeMode && !showTakeOnModal && !showAlreadyTakeOnModal && searchName === "") {
      const timer = setTimeout(() => {
        dispatch(updateBeforeLoading(true));
        dispatch(getBeforeDiagnoses(msalToken, 1, 20, navigate));
        dispatch(updateBeforeRandomNumber(Math.floor(Math.random() * 100000)));
        //10s
      }, 10 * 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoctor, window.location.href, isSearchBeforeMode, showTakeOnModal, showAlreadyTakeOnModal, msalToken, beforeRandomNumber, navigate, dispatch, searchName]);

  useEffect(() => {
    //診療前一覧ページには配送者がアクセスできない
    if (isDeliver) {
      navigate("/404");
    }
  }, [isDeliver, navigate]);

  return (
    <>
      {takeOnLoading ?
        <div className='login-loading-background'>
          <LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} />
        </div>
        : ''}
      {showTakeOnModal ? <TakeOnModal /> : ''}
      {showAlreadyTakeOnModal ? <AlreadyTakeOnModal /> : ''}
      {showSearchErrorModal ? <SearchErrorModal close={closeSearchErrorModal} /> : ''}
      <section className="before-diagnoses">
        <div className="before-diagnoses-content">
          <div id="beforeDiagContent" className="main-content">
            <div className="space-between-block-before">
              <div id="beforeDiagCount" className="count-box">
                <div className="has-font-size-14">担当済みメッセージなし：<span className="red-font">{assignedCount}</span>件</div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="has-font-size-14">担当医未決定：<span className="red-font">{unAssignedCount}</span>件</div>
              </div>
              {isDoctor ?
                <div className="field is-grouped">
                  <p className="control is-expanded has-icons-right">
                    <input
                      id="searchDiagnosisByNameInput"
                      className="input"
                      type="text"
                      placeholder="姓名で検索"
                      value={searchName}
                      onChange={handleSearchName}
                      onKeyUp={inputKeyUp}
                    />
                    {
                      isSearched ?
                        <span id="clearSearchIcon" className="icon is-right is-clickable">
                          <Icon icon="mdi:close" style={{ fontSize: '18px' }} onClick={clearSearchContent} />
                        </span>
                        : ''
                    }
                  </p>
                  <p className="control">
                    <button
                      id="searchDiagnosisByNameButton"
                      type="button"
                      className="button is-primary"
                      disabled={searchName ? false : true}
                      onClick={searchDiagnosis}
                    >
                      検索
                    </button>
                  </p>
                </div>
                : ''}
            </div>
            {searchBeforeLoading ? <Loading /> :
              <>
                {
                  beforeDiagnoses.length > 0 ?
                    <div id="beforeDiagnosisList" className="diagnosis-list" >
                      {beforeDiagnoses.map((item: any) => {
                        return (
                          <DiagnosisCardFrame diagnosis={item} key={item.diagnosisCode} />
                        )
                      })}
                    </div>
                    : ''
                }
                {
                  beforeDiagnoses.length <= 0 && isSearchBeforeMode ?
                    <div className="has-text-centered white">
                      <p id="searchNoResultTitle" className="has-margin-1 has-text-left has-padding-top-5">
                        検索結果はありません
                      </p>
                      <div id="searchNoResultContent" className="searchHelp has-margin-1">
                        <p>ヒント</p>
                        <ul>
                          <li>
                            姓名の間にスペースを入れて検索をするか、姓または名のみで検索してください
                          </li>
                          <li>姓名以外の情報（患者IDや記号）では検索できません</li>
                        </ul>
                      </div>
                    </div>
                    : ''
                }
                {
                  beforeDiagnoses.length <= 0 && !isSearchBeforeMode ?
                    <div id="searchNoResultFooter" className="has-margin-1">
                      現在、一覧にデータがありません
                    </div>
                    : ''
                }
                {(!isSearchBeforeMode && beforeDiagnoses.length > 0) ? <Pagination pages={pages} pageTab={3} /> : ''}
              </>
            }
          </div>
        </div>
      </section >
    </>
  )
}

export default BeforeDiagnoses;