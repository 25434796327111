import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatMessageType } from "@azure/communication-chat";
import moment from "moment";
import { TimePicker, DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import { post } from '../../../api/FetchAPI';
import { VIDEO_DIAGNOSIS } from '../../../api/Api';
import { updateVideoScheduleModal } from './store/action';
import { updateVideoDateTime } from '../chatList/store/action';
import { updateDiagnosisStartTime, updateDiagnosisEndTime, updateClinicRandomNumber } from '../clinicRoom/store/action';
import { jstTimeFormat, setBeforeHalfHour } from '../../../utils/TimeFormat';
import {
  ERR_MSG_001, ERR_MSG_002, SENTRT_CONTEXT_ID, VIDEO_DATETIME_MESSAGE_001, VIDEO_DATETIME_MESSAGE_002, VIDEO_DATETIME_MESSAGE_003,
  VIDEO_DATETIME_MESSAGE_004, VIDEO_DATETIME_MESSAGE_005, VIDEO_DATETIME_MESSAGE_006, VIDEO_DATETIME_MESSAGE_007, VIDEO_DATETIME_MESSAGE_008
} from "../../../config/constant";
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';
import { sendVideoUrl, startVideo } from "../../../utils/VideoFuc";

function VideoScheduleModal(props: any) {
  const msalName = useSelector<any, any>((state) => state.login.msalName);
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const videoGroupId = useSelector<any, any>((state) => state.video.videoGroupId);
  const dispatch = useDispatch() as any;

  const formatDateTime = "YYYY/MM/DD HH:mm";
  const formatDate = "YYYY/MM/DD";
  const formatTime = "HH:mm";

  const initFromDate = dayjs();
  const initToDate = dayjs();
  const initFrom = dayjs(setBeforeHalfHour());
  const initTo = dayjs(initFrom.add(30, 'minutes'));

  const [dateFrom, setDateFrom] = useState<any>(initFromDate);
  const [dateTo, setDateTo] = useState<any>(initToDate);
  const [timeFrom, setTimeFrom] = useState<any>(initFrom);
  const [timeTo, setTimeTo] = useState<any>(initTo);
  const [videoTimeErrorMsg, setVideoTimeErrorMsg] = useState<any>(null);
  const [videoTimeErrorCode, setVideoTimeErrorCode] = useState<any>(null);
  const [isClickable, setIsClickable] = useState<boolean>(true);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // //今日以降の日は選択できません
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  //ビデオ日時ダイアログを閉じる
  function closeVideoScheduleModal() {
    //sentry log
    sendSentryLog('ビデオ日時ダイアログを閉じる', 'closeVideoTimeDialog', 'log',
      { 'event_name': 'set_video_time', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    dispatch(updateVideoScheduleModal(false));
  }

  //ビデオ日付の設定
  function setDate(date: any) {
    setDateFrom(date);
    setDateTo(date);
  }

  //ビデオ時間の設定
  function setTime(date: any) {
    setTimeFrom(date);
    if (date) {
      setTimeTo(date.add(30, 'minutes'));
      if (date.format(formatTime) === "23:30" && dateFrom) {
        setDateTo(dateFrom.add(1, 'days'));
      }
    } else {
      setTimeTo(null);
    }
  }

  //「確定」ボタンのクリック事件
  const handleClick = () => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      setVideoDiagnosisDateTime();
      setTimeout(() => {
        setIsClickable(true);
      }, 3000)
    }
  }

  //ビデオ日時をDBとreducerに設定する
  async function setVideoDiagnosisDateTime() {
    try {
      //ビデオ診療開始日付
      let startDate = null;
      //ビデオ診療開始時間
      let startTime = null;
      //ビデオ診療終了日付
      let endDate = null;
      //ビデオ診療終了時間
      let endTime = null;
      //ビデオ診療開始日時フォマード
      let dateFromJst = null;
      //ビデオ診療終了日時フォマード
      let dateToJst = null;

      //開始日付と開始時間が設定される
      if (dateFrom && timeFrom) {
        //ビデオ開始日時処理
        startDate = moment(dateFrom.toDate()).format(formatDate);
        startTime = moment(timeFrom.toDate()).format(formatTime);
        const videoStartTime = startDate + ' ' + startTime;
        if (videoStartTime < initFrom.format(formatDateTime)) {
          setVideoTimeErrorMsg(ERR_MSG_002);
          return;
        }
        //ビデオ終了日時処理
        endDate = moment(dateTo.toDate()).format(formatDate);
        endTime = moment(timeTo.toDate()).format(formatTime);
        const videoEndTime = endDate + ' ' + endTime;
        //日時フォマード
        dateFromJst = jstTimeFormat(new Date(videoStartTime));
        dateToJst = jstTimeFormat(new Date(videoEndTime));

        //開始日付のみ
      } else if (dateFrom && !timeFrom) {
        //ビデオ開始日時処理
        startDate = moment(dateFrom.toDate()).format(formatDate);
        startTime = moment(setBeforeHalfHour()).format(formatTime);
        setTimeFrom(dayjs(setBeforeHalfHour()));
        const videoStartTime = startDate + ' ' + startTime;
        //ビデオ終了日時処理
        if (startTime === "23:30") {
          endDate = moment(dayjs(startDate).add(1, 'days').toDate()).format(formatDate);
        } else {
          endDate = moment(dateTo.toDate()).format(formatDate);
        }
        endTime = moment(dayjs(setBeforeHalfHour()).add(30, 'minutes').toDate()).format(formatTime);
        setTimeTo(dayjs(setBeforeHalfHour()).add(30, 'minutes'));
        const videoEndTime = endDate + ' ' + endTime;
        //日時フォマード
        dateFromJst = jstTimeFormat(new Date(videoStartTime));
        dateToJst = jstTimeFormat(new Date(videoEndTime));

        //開始時間のみ
      } else if (!dateFrom && timeFrom) {
        //ビデオ開始日時処理
        startDate = moment().format(formatDate);
        startTime = moment(timeFrom.toDate()).format(formatTime);
        setDateFrom(dayjs());
        const videoStartTime = startDate + ' ' + startTime;
        //ビデオ終了日時処理
        if (startTime === "23:30") {
          endDate = moment(dayjs(startDate).add(1, 'days').toDate()).format(formatDate);
          setDateTo(dayjs(startDate).add(1, 'days'));
        } else {
          endDate = moment().format(formatDate);
          setDateTo(dayjs());
        }
        endTime = moment(timeTo.toDate()).format(formatTime);
        const videoEndTime = endDate + ' ' + endTime;
        //日時フォマード
        dateFromJst = jstTimeFormat(new Date(videoStartTime));
        dateToJst = jstTimeFormat(new Date(videoEndTime));
      }
      //sentry log
      const sentryContextApi: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          api: VIDEO_DIAGNOSIS,
          dateFrom: dateFromJst as any,
          dateTo: dateToJst as any
        }
      };
      sendSentryLog('ビデオ日時を設定する', 'setVideoTime', 'log',
        { 'event_name': 'set_video_time', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContextApi]
      );
      const data = {
        diagnosisCode: selectedDiagnosisCode,
        statusId: props.statusId,
        startDatetimeJst: dateFromJst,
        endDatetimeJst: dateToJst,
      };
      const response = await post(VIDEO_DIAGNOSIS, data, msalToken) as any;
      if (response.data.errorCode === null) {
        await dispatch(updateVideoDateTime(dateFromJst, dateToJst));
        await dispatch(updateDiagnosisStartTime(dateFromJst));
        await dispatch(updateDiagnosisEndTime(dateToJst));
        await dispatch(updateClinicRandomNumber(Math.floor(Math.random() * 100000)));
        closeVideoScheduleModal();
        //日時確定メッセージとビデオリンクのメッセージ（必要な場合）が送られる
        sendVideoDateTimeMessagesAndVideoUrlMessages(dateFromJst, dateToJst);
      } else {
        //sentry log
        const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': response.data.errorCode } };
        sendSentryLog('Api Error', 'apiError', 'error',
          { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
          , [sentryContextApiError]
        );
        setVideoTimeErrorMsg(ERR_MSG_001);
        setVideoTimeErrorCode(response.data.errorCode);
      }
    } catch (e: any) {
      //sentry log
      const sentryContextApiError: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          api: VIDEO_DIAGNOSIS,
          errCode: e.errorCode ?? e.code
        }
      };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContextApiError]
      );
      setVideoTimeErrorMsg(ERR_MSG_001);
      setVideoTimeErrorCode(e.errorCode ?? e.code);
    }
  }

  //日時確定メッセージとビデオリンクのメッセージ（必要な場合）
  async function sendVideoDateTimeMessagesAndVideoUrlMessages(dateFrom: any, dateTo: any) {
    const sentryContext: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    };
    let msgTypeText: ChatMessageType = "text";
    if (dateFrom !== null) {
      const sendMessageRequest1 = {
        content: VIDEO_DATETIME_MESSAGE_001 + moment(new Date(dateFrom)).format("YYYY年MM月DD日 HH:mm") + VIDEO_DATETIME_MESSAGE_002 + "\n\n" + VIDEO_DATETIME_MESSAGE_003 + "\n\n"
          + VIDEO_DATETIME_MESSAGE_004 + "\n\n" + VIDEO_DATETIME_MESSAGE_005 + "\n" + VIDEO_DATETIME_MESSAGE_006 + "\n" + VIDEO_DATETIME_MESSAGE_007 + "\n\n" + VIDEO_DATETIME_MESSAGE_008
      };
      let sendMessageOptions1 = {
        senderDisplayName: msalName,
        type: msgTypeText,
        metadata: {
          linkURL: VIDEO_DATETIME_MESSAGE_007
        }
      };
      //日時確定メッセージを送信
      await props.chatThreadClient.sendMessage(sendMessageRequest1, sendMessageOptions1);
      //sentry log
      sendSentryLog('ビデオ日時確定のメッセージ送信処理が走る', 'execSendVideoTimeMsg', 'log',
        { 'event_name': 'set_video_time', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContext]
      );

      //現在日時と設定する日時で判断して、必要な場合はビデオリンクを発行して送信する
      const sentryContextVideo: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          groupId: videoGroupId,
          threadId: props.chatThreadClient.threadId,
        }
      };
      // 設定したビデオ開始時間の「時（hour）」
      const dateFromHours = new Date(dateFrom).getHours();
      // 設定したビデオ開始時間の「日付」
      const dateFromStr = moment(new Date(dateFrom)).format(formatDate);
      // 現在時刻の「時（hour）」
      const nowHours = new Date(Date.now()).getHours();
      // 現在時刻の「日付」
      const nowStr = moment(new Date(Date.now())).format(formatDate);
      // 明日の「日付」
      const tomrrowStr = moment(new Date(Date.now())).add(1, 'days').format(formatDate);
      if ((nowHours >= 0 && nowHours < 6 && dateFromStr === nowStr && dateFromHours < 7)
          || (nowHours >= 6 && nowHours < 11 && dateFromStr === nowStr && dateFromHours < 12)
          || (nowHours >= 11 && nowHours < 16 && dateFromStr === nowStr && dateFromHours < 17)
          || (nowHours >= 16 && nowHours < 20 && dateFromStr === nowStr && dateFromHours < 21)
          || (nowHours >= 20 && nowHours < 24 && (dateFromStr === nowStr || (dateFromStr === tomrrowStr && dateFromHours < 7)))
          ) {
        // 新しい一時トークン取得
        const response = await dispatch(startVideo(msalToken, selectedDiagnosisCode));
        if (response.data.errorCode === null && response.data.temporaryToken !== null) {
          sendVideoUrl(msalName, response.data.temporaryToken, selectedDiagnosisCode, dateFrom,
            personalInfoDetail.personalUid, sentryContextVideo, props.chatThreadClient);
        }
      }
    }
  }

  return (
    <>
      <div id="videoScheduleDialog" className="modalsort" style={{ display: 'block', height: 'unset' }}>
        <div id="closeVideoScheduleDialog" className="closebtn" onClick={closeVideoScheduleModal}></div>
        <div className="inner" style={{ paddingBottom: '30px' }}>
          {videoTimeErrorMsg || videoTimeErrorCode ?
            <div id="videoTimeErrorMsg" className="has-text-danger has-padding-1 has-text-weight-bold error-message">
              {videoTimeErrorMsg}
              {videoTimeErrorCode ? <span><br />&lt;エラーコード：{videoTimeErrorCode}&gt;</span> : ''}
            </div> : ''}
          <div id="videoScheduleInputArea" style={{ marginBottom: '40px' }}>
            <span id="videoScheduleDialogTitle">ビデオ診療</span>
            <DatePicker
              className="picker"
              value={dateFrom}
              disabledDate={disabledDate}
              onChange={(date) => {
                if (date) {
                  setDate(date);
                } else {
                  setDate(null);
                }
              }}
            />
            <TimePicker
              className="picker"
              style={{ marginRight: '8px' }}
              value={timeFrom}
              format={'HH:mm'}
              minuteStep={30}
              onChange={(date) => {
                if (date) {
                  setTime(date);
                } else {
                  setTime(null);
                }
              }}
            />
            ～
            <DatePicker
              className="picker"
              value={dateTo}
              disabled
            />
            <TimePicker
              className="picker"
              value={timeTo}
              format={'HH:mm'}
              minuteStep={30}
              disabled
            />
          </div>
          <button
            id="confirmVideoScheduleBtn"
            className="sort"
            style={{ width: '240px' }}
            onClick={handleClick}
          >
            確定
          </button>
        </div>
      </div>
      <div className="modalbg" style={{ display: 'block' }}></div>
    </>
  )
}

export default VideoScheduleModal;