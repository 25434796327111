"use client";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/locale/ja_JP';
import 'dayjs/locale/ja';
import type { RangePickerProps } from 'antd/es/date-picker';
import { ConfigProvider, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DELIVERY_CSV_REGULAR, DELIVERY_CSV_SUMMARY } from "../../api/Api";
import { updateShippingMethod, updatDownloadLoading, updateDownloadErrorCode, updateDownloadErrorMsg } from "./store/action";
import { ERR_CODE_A001, ERR_MSG_001 } from "../../config/constant";
import ErrorModal from "../ErrorModal";
import "./style/deliveries.scss";
import "../diagnosing/clinicRoom/style/clinic.scss";

function Deliveries() {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const isDeliver = useSelector<any, any>((state) => state.login.isDeliver);
  const shippingMethodOptions = useSelector<any, any>((state) => state.deliveries.shippingMethodOptions);
  const shippingMethod = useSelector<any, any>((state) => state.deliveries.shippingMethod);
  const isDownloadable = useSelector<any, any>((state) => state.deliveries.isDownloadable);
  const isDownloadLoading = useSelector<any, any>((state) => state.deliveries.isDownloadLoading);
  const downloadErrorCode = useSelector<any, any>((state) => state.deliveries.downloadErrorCode);
  const downloadErrorMessage = useSelector<any, any>((state) => state.deliveries.downloadErrorMessage);

  const initDate = dayjs();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(initDate);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    //今日以降の日は選択できません
    return current && current > dayjs().subtract(0, 'day').endOf('day');
  };

  //輸送方法の変更
  function changeShippingMethod(e: any) {
    dispatch(updateShippingMethod(parseInt(e.target.value)));
  }

  //エラーモードをクロース
  function closeDownloadErrorModal() {
    dispatch(updateDownloadErrorCode(null));
    dispatch(updateDownloadErrorMsg(''));
  }

  //配送ファイルのダウンロード
  async function downloadDeliveryCsv() {
    dispatch(updatDownloadLoading(true));
    const targetDate = selectedDate.format('YYYY-MM-DD');
    let path = '';
    switch (selectedItem.priceType) {
      case 2:
        path = DELIVERY_CSV_SUMMARY;
        break
      default:
        path = DELIVERY_CSV_REGULAR;
    }
    const res = await axios.get(path, {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + msalToken },
      params: {
        shippingMethod: shippingMethod.toString(),
        targetDate: targetDate,
        paymentClassification: selectedItem.paymentClassification.toString(),
      },
      responseType: 'blob',
    })
    if (res.headers['content-type'] === 'application/json') {
      const str = await res.data.text();
      const result = JSON.parse(str);
      if (result.errorCode === ERR_CODE_A001) {
        dispatch(updateDownloadErrorMsg("※決済データがありません。"));
      } else {
        dispatch(updateDownloadErrorMsg(ERR_MSG_001));
      }
      dispatch(updateDownloadErrorCode(result.errorCode));
    } else {
      const fileName = 'export-' + dayjs().format("YYYYMMDDHHmmss") + '.csv';
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(res.data, fileName);
      } else {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        );
        const link = document.createElement('a') as any;
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      }
    }
    dispatch(updatDownloadLoading(false));
  }

  //配送管理ページには管理者と配送者がアクセスできる
  useEffect(() => {
    if (!(isManager || isDeliver) && window.location.href.indexOf("/deliveries")) {
      navigate("/404");
    }
  }, [isManager, isDeliver, navigate]);

  return (
    <>
      {downloadErrorCode ? <ErrorModal errCode={downloadErrorCode} errMessage={downloadErrorMessage} closeErrorModal={closeDownloadErrorModal} /> : ''}
      <div>
        <div className="center mb-8">
          <div>
            <div className="shipping-method-center">
              <div className="field is-horizontal shipping-method-body">
                <div className="field-label is-normal">
                  <label id="download-shippingMethod-label" className="label">配送方法</label>
                </div>
                <div className="field-body shipping-method-body">
                  <p className="control is-expanded select is-normal">
                    <select
                      id="download-shippingMethod-body"
                      className="select-body"
                      value={shippingMethod}
                      onChange={(e) => { changeShippingMethod(e) }}
                    >
                      {shippingMethodOptions.map((item: any) => {
                        return (
                          <option key={item.value} value={item.value}>{item.text}</option>
                        )
                      })}
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="has-margin-top-1">
            <div className="datepicker-center">
              <div className="field is-horizontal datepicker-body">
                <div className="field-label is-normal">
                  <label id="download-date-label" className="label">対象日付</label>
                </div>
                <div className="field-body datepicker-body">
                  <div className="block select">
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        id="download-date-body"
                        className="deliver_picker"
                        defaultValue={selectedDate}
                        allowClear={false}
                        suffixIcon=""
                        format='YYYY/MM/DD(ddd)'
                        onChange={(date) => { if (date) setSelectedDate(date) }}
                        disabledDate={disabledDate}
                        inputReadOnly
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="has-margin-top-2">
            <label id="regular-plan-continuation" className="b-radio radio">
              <input
                name="plan"
                type="radio"
                value={JSON.stringify({
                  priceType: 1,
                  paymentClassification: 2,
                  isDownloadable: isDownloadable.regularContinuationPossible,
                })}
                onChange={(e: any) => { setSelectedItem(JSON.parse(e.target.value)) }}
              />
              <span className="check"></span>
              <span className="control-label">
                定期プラン継続
              </span>
            </label>
            <label id="summary-plan-continuation" className="b-radio radio new-radio">
              <input
                name="plan"
                type="radio"
                value={JSON.stringify({
                  priceType: 2,
                  paymentClassification: 4,
                  isDownloadable: isDownloadable.sixSummaryContinuationPossible,
                })}
                onChange={(e: any) => { setSelectedItem(JSON.parse(e.target.value)) }}
              />
              <span className="check"></span>
              <span className="control-label">
                おまとめプラン継続
              </span>
            </label>
          </div>
          <div className="has-margin-top-2">
            <label id="regular-plan-new" className="b-radio radio">
              <input
                name="plan"
                type="radio"
                value={JSON.stringify({
                  priceType: 1,
                  paymentClassification: 1,
                  isDownloadable: isDownloadable.regularNewPossible,
                })}
                onChange={(e: any) => { setSelectedItem(JSON.parse(e.target.value)) }}
              />
              <span className="check"></span>
              <span className="control-label">
                定期プラン新規
              </span>
            </label>
            <label id="summary-plan-new" className="b-radio radio new-radio">
              <input
                name="plan"
                type="radio"
                value={JSON.stringify({
                  priceType: 2,
                  paymentClassification: 3,
                  isDownloadable: isDownloadable.sixSummaryNewPossible,
                })}
                onChange={(e: any) => { setSelectedItem(JSON.parse(e.target.value)) }}
              />
              <span className="check"></span>
              <span className="control-label">
                おまとめプラン新規
              </span>
            </label>
          </div>
          <button
            id="download-button"
            className="button is-primary mt-6"
            disabled={!selectedItem || !selectedItem.isDownloadable}
            onClick={downloadDeliveryCsv}
          >
            ダウンロード
          </button>
        </div>
        {isDownloadLoading ?
          <div className='login-loading-background'><LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} /></div>
          : ''}
      </div >
    </>
  )
}

export default Deliveries;