import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from 'moment';
import { post } from ".././../api/FetchAPI";
import { CSV_UPLOAD, CSV_DOWNLOAD, BANNER_UPLOAD, PUT_NOTICE, UPDATE_NOTICE } from "../../api/Api";
import InputNoticeForm from "./InputNoticeForm";
import { getNoticeDetailByNo, setNotice, updateNoticeErrorCode, updateNoticeErrorMsg, setSpecificUserFileUrl, setBannerUrl, updateNoticeLoading } from "./store/action";
import { ERR_CODE_B001, ERR_MSG_012, ERR_CODE_A013, ERR_MSG_013 } from "../../config/constant";

function New(props: any) {
  const msalToken = useSelector<any, any>((state) => state.login.msalToken);
  const isManager = useSelector<any, any>((state) => state.login.isManager);
  const notice = useSelector<any, any>((state) => state.notice.notice);
  const noticeLoading = useSelector<any, any>((state) => state.notice.noticeLoading);

  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const defaultNotice = {
    noticeNo: '',
    noticeType: '',
    noticeTitle: '',
    noticeContent: '',
    internalManageName: '',
    noticeDisplayOrder: null,
    bannerUrl: '',
    noticeDisplayStartTime: null,
    noticeDisplayEndTime: null,
    isDeleted: false,
    specificUserFileUrl: '',
  };

  const params = useMemo(() => {
    return new URLSearchParams(window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const noticeNo = useMemo(() => {
    return params.get("noticeNo") as string;
  }, [params]);

  const isValidDisplayOrder = useMemo(() => {
    // 0または正の数字のみ有効。-0や-1, -0.11等はエラーとなるため、バリデーションをかけています。
    return notice.noticeDisplayOrder !== '-0' && notice.noticeDisplayOrder >= 0
  }, [notice.noticeDisplayOrder]);

  //掲載順は入力されていますか
  const isDisplayOrderFilled = useMemo(() => {
    return (
      isValidDisplayOrder &&
      !isNaN(notice.noticeDisplayOrder) &&
      notice.noticeDisplayOrder !== null &&
      notice.noticeDisplayOrder !== ''
    )
  }, [isValidDisplayOrder, notice.noticeDisplayOrder]);

  //必須フィールドは入力されていますか
  const isRequiredFieldsFilled = useMemo(() => {
    return !!(
      notice.noticeType &&
      notice.noticeTitle &&
      notice.noticeContent &&
      isDisplayOrderFilled &&
      (notice.noticeType === "0" ||
        notice.specificUserFileUrl)
    )
  }, [notice.noticeType, notice.noticeTitle, notice.noticeContent, isDisplayOrderFilled, notice.specificUserFileUrl]);

  //日時の書式設定
  function formatLocalDateTimeWithSecond(datetime: any) {
    const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    return datetime.replace(regex, '$1:00');
  }

  //特定ユーザデータのアプロード
  async function uploadUserCSV(file: any) {
    const formData = new FormData() as any;
    formData.append('specificuserfile', file);
    try {
      dispatch(updateNoticeLoading(true));
      const res = await axios.post(CSV_UPLOAD,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: 'Bearer ' + msalToken
          },
        }
      );
      if (res.data.errorCode) {
        dispatch(updateNoticeErrorCode(res.data.errorCode));
      } else {
        dispatch(setSpecificUserFileUrl(res.data.filePath));
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
      dispatch(updateNoticeLoading(false));
    }
  }

  //バナー画像のアプロード
  async function uploadBanner(file: any) {
    const formData = new FormData() as any;
    formData.append('UploadImage', file);
    try {
      dispatch(updateNoticeLoading(true));
      const res = await axios.post(BANNER_UPLOAD,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: 'Bearer ' + msalToken
          },
        }
      );
      if (res.data.errorCode) {
        dispatch(updateNoticeErrorCode(res.data.errorCode));
      } else {
        dispatch(setBannerUrl(res.data.imagePath));
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
      dispatch(updateNoticeLoading(false));
    }
  }

  //CSVファイルの作成とダウンロード
  function createAndDownloadCSV(rawString: string, filePath: string) {
    const fileName = "特定ユーザー_" + filePath.split('/')[2].split(".csv")[0] + "_" + moment().format("YYYYMMDD") + ".csv";
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, rawString], { type: 'text/csv' });
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = fileName;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  }

  //特定ユーザデータのダウンロード
  async function downloadUserCSV() {
    if (!notice.specificUserFileUrl) return
    try {
      dispatch(updateNoticeLoading(true));
      const res = await axios.post(CSV_DOWNLOAD,
        {
          filePath: notice.specificUserFileUrl,
        },
        {
          headers: {
            Authorization: 'Bearer ' + msalToken
          }
        },
      );
      if (res.data.errorCode === ERR_CODE_B001) {
        dispatch(updateNoticeErrorCode(res.data.errorCode));
        dispatch(updateNoticeErrorMsg(ERR_MSG_012));
      } else if (res.data.errorCode) {
        dispatch(updateNoticeErrorCode(res.data.errorCode));
      } else {
        createAndDownloadCSV(res.data, notice.specificUserFileUrl);
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
      dispatch(updateNoticeLoading(false));
    }
  }

  //お知らせ登録あるいは更新
  async function send(isNew: boolean) {
    dispatch(updateNoticeLoading(true));
    try {
      let response = {} as any;
      const requestBody = {
        noticeType: notice.noticeType,
        noticeTitle: notice.noticeTitle,
        noticeContent: notice.noticeContent,
        internalManageName: notice.internalManageName,
        noticeDisplayOrder: Number(notice.noticeDisplayOrder),
        bannerUrl: notice.bannerUrl,
        specificUserFileUrl: notice.specificUserFileUrl,
        isDeleted: notice.isDeleted,
      } as any;
      if (!isNew) {
        requestBody.noticeNo = notice.noticeNo;
        requestBody.isPublished = false;
      }
      if (notice.noticeDisplayStartTime) {
        requestBody.noticeDisplayStartTime = formatLocalDateTimeWithSecond(notice.noticeDisplayStartTime);
      }
      if (notice.noticeDisplayEndTime) {
        requestBody.noticeDisplayEndTime = formatLocalDateTimeWithSecond(notice.noticeDisplayEndTime);
      }
      if (isNew) {
        response = await post(PUT_NOTICE, requestBody, msalToken);
      } else {
        response = await post(UPDATE_NOTICE, requestBody, msalToken);
      }
      if (response.data.errorCode === ERR_CODE_A013) {
        dispatch(updateNoticeErrorCode(response.data.errorCode));
        dispatch(updateNoticeErrorMsg(ERR_MSG_013));
      } else if (response.data.errorCode) {
        dispatch(updateNoticeErrorCode(response.data.errorCode));
      } else {
        //登録成功時、検索画面に戻る
        navigate("notice/search");
      }
      dispatch(updateNoticeLoading(false));
    } catch (e: any) {
      dispatch(updateNoticeErrorCode(e.errorCode ?? e.code));
      dispatch(updateNoticeLoading(false));
    }
  }

  //noticeNoによってお知らせ詳細情報を取得
  useEffect(() => {
    if (isManager) {
      if (noticeNo !== null && noticeNo !== '') {
        dispatch(setNotice(defaultNotice));
        dispatch(getNoticeDetailByNo(msalToken, noticeNo));
      } else if (noticeNo === '') {
        navigate("notice/search");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-content">
      <InputNoticeForm
        isNew={props.isNew}
        uploadUserCSV={uploadUserCSV}
        downloadUserCSV={downloadUserCSV}
        uploadBanner={uploadBanner}
      />
      <div className="has-text-right">
        <button
          id='noticeCommit'
          disabled={!isRequiredFieldsFilled}
          className="button is-primary"
          onClick={() => { send(props.isNew) }}
        >
          登録
        </button>
      </div>
      {noticeLoading ?
        <div className='login-loading-background'><LoadingOutlined style={{ fontSize: '500%', color: '#7f7f7f' }} /></div>
        : ''}
    </div>
  )
}

export default New;