import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateAlreadyTakeOnModal } from "./store/action";

function AlreadyTakeOnModal() {
  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  //担当済みダイアログを閉じる
  function closeAlreadyTakeOnModal() {
    dispatch(updateAlreadyTakeOnModal(false));
    navigate("/beforeDiagnoses");
  }

  return (
    <div id="alreadyTakeOnDialog" className="dialog">
      <div className="wrap">
        <div id="alreadyTakeOnDialogClose" className="close" onClick={closeAlreadyTakeOnModal}></div>
        <div className="inner">
          <p id="alreadyTakeOnDialogContent" className="read">
            すでに他の医師が担当しました。<br />
            ダイアログを閉じて、診療中一覧にお戻りください。
          </p>
        </div>
      </div>
    </div>
  )
}

export default AlreadyTakeOnModal;