import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWithoutReasonModal, updateEndDiagnosisErrCode, updateEndDiagnosisErrMessage } from './store/action';
import { sendSentryLog } from '../../../utils/SentryFuc';

function ConfirmWithoutPrescribingModal(props: any) {
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);
  const withoutPrescribingReason = useSelector<any, any>((state) => state.prescription.withoutPrescribingReason);

  //診療終了理由ID
  const [reasonId, setReasonId] = useState(null);

  const dispatch = useDispatch() as any;

  //コメント
  const formatComment = useMemo(() => {
    return props.comment || 'コメント入力はありません。'
  }, [props.comment]);

  //「処方せず診療終了」ダイアログを閉じる
  function closeWithoutPrescribingReasonsModal() {
    //sentry log
    sendSentryLog('「処方せず診療終了」ダイアログを閉じる', 'closeWithoutPrescribeDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
    );
    //エラーコードとエラーメッセージをクリア
    dispatch(updateEndDiagnosisErrCode(null));
    dispatch(updateEndDiagnosisErrMessage(null));
    //「処方せずに診療終了」ダイアログをクロース
    dispatch(updateWithoutReasonModal(false));
  }

  return (
    <div id="endDiagnosisWithoutPrescribeDialog" className="dialog">
      <div className="wrap width">
        <div id="closeEndDiagnosisWithoutPrescribeDialog" className="close" onClick={closeWithoutPrescribingReasonsModal}></div>
        <div className="inner">
          <p id="endDiagnosisWithoutPrescribeTitle" className="read">処方せず診療を終了してよろしいですか？</p>
          <p id="endDiagnosisWithoutPrescribeSubtitle" className="sentence color-gray">
            ※診療を終了すると、診療中一覧に表示されなくなります<br />
            ※操作をやり直すことはできません
          </p>
          <div id="endDiagnosisWithoutPrescribeReasonTitle" className="has-padding-1 has-text-weight-bold reasons-header">
            診療終了理由
          </div>
          <div id="endDiagnosisWithoutPrescribeReasonList" className="reasons-box">
            {withoutPrescribingReason.filter((r: any) => r.reasonId !== 2).map((r: any) => {
              return (
                <div className="reason" key={r.reasonContent}>
                  <label className="b-radio radio">
                    <input
                      name="reason"
                      type="radio"
                      value={r.reasonId}
                      onChange={(e: any) => { setReasonId(e.target.value) }}
                    />
                    <span className="check"></span>
                    <span className="control-label">
                      <span className={'medicine-name'}>
                        {r.reasonContent}
                      </span>
                    </span>
                  </label>
                </div>
              )
            })}
          </div>
          <div id="endDiagnosisWithoutPrescribeComment" className="comment-box has-padding-1">{formatComment}</div>
          <div className="button-wrap">
            <button
              id="endDiagnosisWithoutPrescribeBtn"
              onClick={() => { props.endDiagnosis(reasonId) }}
              disabled={reasonId === null ? true : false}
            >
              診察終了にする
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmWithoutPrescribingModal;