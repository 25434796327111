import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfirmMedcineModal, updateEndDiagnosisErrCode, updateEndDiagnosisErrMessage } from './store/action';
import { sentryContext, sendSentryLog } from '../../../utils/SentryFuc';
import { SENTRT_CONTEXT_ID } from "../../../config/constant";

function ConfirmMedicineModal(props: any) {
  const displayPatientsLists = useSelector<any, any>((state) => state.chat.displayPatientsLists);
  const selectedDiagnosisCode = useSelector<any, any>((state) => state.chat.selectedDiagnosisCode);
  const personalInfoDetail = useSelector<any, any>((state) => state.clinic.personalInfoDetail);

  const dispatch = useDispatch() as any;

  //氏
  const personalFamilyName = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.personalFamilyName
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //名
  const personalFirstName = useMemo(() => {
    return displayPatientsLists.filter((item: any) => {
      return item.diagnosisCode === selectedDiagnosisCode;
    })[0]?.personalFirstName
  }, [selectedDiagnosisCode, displayPatientsLists]);

  //処方プラン
  const medicinePlan = useMemo(() => {
    return props.medicine.planMasterId === 2
      ? 'おまとめプラン 6シート'
      : '定期プラン 13シート'
  }, [props.medicine.planMasterId]);

  //コメント
  const formatComment = useMemo(() => {
    return props.comment || 'コメント入力はありません。'
  }, [props.comment]);

  //「処方確定する」ダイアログを閉じる
  function closeConfirmMedicineModal() {
    //sentry log
    const sentryContextEndDiagnosis: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        medicineProductName: props.medicine.medicineProductName,
        sheetCount: props.medicine.sheetCount
      }
    };
    sendSentryLog('「処方確定する」ダイアログを閉じる', 'closeWithPrescribeDialog', 'log',
      { 'event_name': 'end_diagnosis', 'patientUid': personalInfoDetail.personalUid, 'diagnosisCode': selectedDiagnosisCode }
      , [sentryContextEndDiagnosis]
    );
    //エラーコードとエラーメッセージをクリア
    dispatch(updateEndDiagnosisErrCode(null));
    dispatch(updateEndDiagnosisErrMessage(null));
    //「処方内容の確認」ダイアログをクロース
    dispatch(updateConfirmMedcineModal(false));
  }

  return (
    <div id="endDiagnosisDialog" className="dialog">
      <div className="wrap">
        <div id="closeEndDiagnosisDialog" className="close" onClick={closeConfirmMedicineModal}></div>
        <div className="inner">
          <p id="endDiagnosistitle" className="read">処方内容を確定し、診察終了してよろしいですか？</p>
          <div className="detail-wrap">
            <div className="detail">
              <dl>
                <dt id="endDiagnosisName">患者名：</dt>
                <dd id="endDiagnosisNameText">
                  {personalFamilyName}&nbsp;{personalFirstName}
                </dd>
              </dl>
              <dl>
                <dt id="endDiagnosisMedicine" className="medicine-box-label">処方内容：</dt>
                <dd id="endDiagnosisMedicineText" className={props.medicine.planMasterId === 2
                  ? 'medicine-box-content color-blue'
                  : 'medicine-box-content'}>
                  {props.medicine.medicineProductName}&nbsp;&nbsp;
                  {props.medicine.sheetCount}シート×{props.medicine.times}回
                </dd>
              </dl>
              <dl>
                <dt id="endDiagnosisPlan">処方プラン：</dt>
                <dd id="endDiagnosisPlanText" className={props.medicine.planMasterId === 2
                  ? 'medicine-box-content color-blue'
                  : 'medicine-box-content'}>
                  {medicinePlan}
                </dd>
              </dl>
              <p id="endDiagnosisComment" className="detail-text">{formatComment}</p>
            </div>
            <p id="endDiagnosisSentence" className="sentence color-red">
              ※診療を終了すると、診療中一覧に表示されなくなります<br />
              ※操作をやり直すことはできません
            </p>
          </div>
          <div className="button-wrap button-red">
            <button
              id="endDiagnosisBtn"
              onClick={() => { props.endDiagnosis() }}
            >
              処方確定し、診察終了する
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmMedicineModal;