import { get, post } from '../../../../api/FetchAPI';
import { DIAGNOSIS_PERSONAL_INFO_DETAIL, MEDICAL_INTERVIEW_DETAIL, DIAGNOSIS_HISTORY, MEDICINE_LIST, VIDEO_DIAGNOSIS_INFO, SAVE_BROWSE_HISTORY, ACS_VIDEO_INFO, PROPOSE_MEDICINE_INFO } from '../../../../api/Api';
import { ERR_CODE_A001, SENTRT_CONTEXT_ID } from '../../../../config/constant';
import { setApiErrorCode } from '../../chatList/store/action';
import { setFirstStartVideoFlag, setVideoGroupId, setVideoToken, setVideoExpiredOn, setIssueNewLink, setGetAcsVideoInfoTime } from '../../video/store/action';
import { sentryContext, sendSentryLog } from '../../../../utils/SentryFuc';

//処方薬マスタをReducersに保存
const setMedicines = (list: any) => ({
  type: 'set_medicines',
  list
})

//患者情報をReducersに保存
export const setPersonalInfoDetail = (info: any) => ({
  type: 'set_diagnosis_personal_info',
  info
})

//問診結果情報をReducersに保存
export const setMedicalInterview = (info: any) => ({
  type: 'set_medical_interview',
  info
})

//診療履歴をReducersに保存
export const setDiagnosisHistory = (info: any) => ({
  type: 'set_diagnosis_history',
  info
})

export const updateCommentModal = (flag: boolean) => ({
  type: 'update_comment_modal',
  flag
})

export const updateSelectedComment = (comment: string) => ({
  type: 'update_selected_comment',
  comment
})

export const setClinicErrorCode = (errCode: any) => ({
  type: 'set_clinic_err_code',
  errCode
})

export const updateProposeMedicineModal = (flag: boolean) => ({
  type: 'update_propose_midicine_modal',
  flag
})

export const updateVideoScheduleModal = (flag: boolean) => ({
  type: 'update_video_schedule_modal',
  flag
})

export const setVideoDiagnosisErrorCode = (errCode: any) => ({
  type: 'update_video_diagnosis_err_code',
  errCode
})

export const setShowVideoRelatedModalIndex = (index: number) => ({
  type: 'update_video_related_modal_index',
  index
})

export const updateVideoStatusId = (videoStatusId: number) => ({
  type: 'update_video_status_id',
  videoStatusId
})

export const updateDiagnosisStartTime = (time: any) => ({
  type: 'set_diagnosis_start_time',
  time
})

export const updateDiagnosisEndTime = (time: any) => ({
  type: 'set_diagnosis_end_time',
  time
})

export const updateClinicRandomNumber = (number: number) => ({
  type: 'update_clinic_random_number',
  number
})

export const updateProposeMedicineProductName = (name: any) => ({
  type: 'update_propose_medicine_product_name',
  name
})

export const updateProposePricePlan = (plan: any) => ({
  type: 'update_propose_price_plan',
  plan
})

export const updateCallAgent = (agent: any) => ({
  type: 'update_call_agent',
  agent
})

export const updateDesiredVideoTime = (desiredVideoTime: any) => ({
  type: 'update_desired_video_time',
  desiredVideoTime
})

//処方薬の情報を取得
export const getMedicineList = (auth: any) => {
  return async (dispatch: any) => {
    try {
      const response = await get(MEDICINE_LIST, null, auth) as any;
      if (response.data.errorCode === null) {
        let arr: any[] = [];
        response.data.planMedicines.forEach(function (plan: any) {
          switch (plan.planMasterId) {
            case 1:
              arr.push({ ...plan, planName: '定期プラン 13シート' })
              break
            case 2:
              arr.push({ ...plan, planName: 'おまとめプラン 6シート' })
              break
            default:
              arr.push({ ...plan, planName: '定期プラン 13シート' })
          }
        });
        arr.forEach(function (plan: any) {
          plan.medicines.forEach(function (medicine: any) {
            medicine.planMasterId = plan.planMasterId
          })
        })
        await dispatch(setMedicines(arr));
      } else {
        await dispatch(setApiErrorCode(response.data.errorCode));
      }
    } catch (e: any) {
      await dispatch(setApiErrorCode(e.errorCode ?? e.code));
    }
  }
}

//患者情報、問診結果情報、診療履歴、ビデオ診療情報、提案薬情報とACSビデオ情報を取得
export const getAllPersonalDetail = (auth: string, diagnosisCode: string, isDoctor: boolean) => {
  return async (dispatch: any) => {
    const sentryContextApi: sentryContext = {
      contextID: SENTRT_CONTEXT_ID,
      info: {
        apiMethod: "getAllPersonalDetail()"
      }
    };
    try {
      let response1 = [] as any;
      let response2 = [] as any;
      let response3 = [] as any;
      let response4 = [] as any;
      let response5 = [] as any;
      let response6 = [] as any;
      const params = {
        diagnosisCode: diagnosisCode
      };
      const requests = [
        get(DIAGNOSIS_PERSONAL_INFO_DETAIL, params, auth),
        get(MEDICAL_INTERVIEW_DETAIL, params, auth),
        get(DIAGNOSIS_HISTORY, params, auth),
        get(VIDEO_DIAGNOSIS_INFO, params, auth),
        get(PROPOSE_MEDICINE_INFO, params, auth)
      ];
      if (isDoctor) {
        requests.push(get(ACS_VIDEO_INFO, params, auth));
      }
      [response1, response2, response3, response4, response5, response6] = await Promise.all(requests);
      //患者情報閲覧履歴を保存
      const data = {
        diagnosisCode: diagnosisCode,
        browsingDiagnosisStatus: 0
      };
      post(SAVE_BROWSE_HISTORY, data, auth);
      if (response1.data.errorCode || response2.data.errorCode || (response3.data.errorCode && response3.data.errorCode !== ERR_CODE_A001) || response4.data.errorCode || (response5.data.errorCode && response5.data.errorCode !== ERR_CODE_A001)) {
        dispatch(setClinicErrorCode(response1.data.errorCode ?? response2.data.errorCode ?? response3.data.errorCode ?? response4.data.errorCode ?? response5.data.errorCode));
        //sentry log
        sendSentryLog('Api Error', 'apiError', 'error',
          { 'event_name': 'api_error', 'diagnosisCode': diagnosisCode }
          , [sentryContextApi]
        );
        return null;
      } else {
        if (response1.data.errorCode === null) {
          await dispatch(setPersonalInfoDetail(response1.data));
        }
        if (response2.data.errorCode === null) {
          await dispatch(setMedicalInterview(response2.data.medicalInterviewQuestions));
          // 希望する曜日・時間帯の設定
          const desiredVideoTime = response2.data.desiredDiagnosisTime;
          if (desiredVideoTime !== null && desiredVideoTime !== undefined && desiredVideoTime.length > 0) {
            await dispatch(updateDesiredVideoTime(desiredVideoTime.split(',')));
          } else {
            await dispatch(updateDesiredVideoTime(null));
          }
        }
        if (response3.data.errorCode === ERR_CODE_A001) {
          await dispatch(setDiagnosisHistory([]));
        } else if (response3.data.errorCode === null) {
          await dispatch(setDiagnosisHistory(response3.data.diagnosisHistories));
        }
        if (response4.data.errorCode === null) {
          await dispatch(updateVideoStatusId(response4.data.videoDiagnosisStatusId));
          await dispatch(updateDiagnosisStartTime(response4.data.diagnosisScheduledStartDatetimeJst));
          await dispatch(updateDiagnosisEndTime(response4.data.diagnosisScheduledEndDatetimeJst));
        }
        if (response5.data.errorCode === ERR_CODE_A001) {
          await dispatch(updateProposeMedicineProductName(null));
          await dispatch(updateProposePricePlan(null));
        } else {
          await dispatch(updateProposeMedicineProductName(response5.data.proposeMedicineProductName));
          await dispatch(updateProposePricePlan(response5.data.proposePricePlan));
        }
        if (response6?.data.errorCode === null) {
          const videoInfo = response6.data.videoInfo;
          dispatch(setFirstStartVideoFlag(false));
          dispatch(setIssueNewLink(false));
          dispatch(setVideoGroupId(videoInfo.videoId));
          dispatch(setVideoToken(videoInfo.temporaryToken));
          dispatch(setVideoExpiredOn(videoInfo.tempTokenExpiredDTJst));
        } else if (response6?.data.errorCode === ERR_CODE_A001) {
          dispatch(setFirstStartVideoFlag(true));
          dispatch(setIssueNewLink(true));
          dispatch(setVideoGroupId(""));
          dispatch(setVideoToken(""));
          dispatch(setVideoExpiredOn(""));
        }
        dispatch(setGetAcsVideoInfoTime(new Date(Date.now())));
        return response1.data.personalUid;
      }
    } catch (e: any) {
      //sentry log
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': diagnosisCode }
        , [sentryContextApiError]
      );
      dispatch(setClinicErrorCode(e.errorCode ?? e.code));
    }
  }
}

export const getNewFirstStartVideoFlag = (auth: string, selectedDiagnosisCode: string) => {
  return async (dispatch: any) => {
    try {
      let newFirstStartVideoFlag = true;
      const params = {
        diagnosisCode: selectedDiagnosisCode
      };
      const response = await get(ACS_VIDEO_INFO, params, auth) as any;
      if (response?.data.errorCode === null) {
        const videoInfo = response.data.videoInfo;
        dispatch(setFirstStartVideoFlag(false));
        dispatch(setIssueNewLink(false));
        dispatch(setVideoGroupId(videoInfo.videoId));
        dispatch(setVideoToken(videoInfo.temporaryToken));
        dispatch(setVideoExpiredOn(videoInfo.tempTokenExpiredDTJst));
        newFirstStartVideoFlag = false;
      }
      dispatch(setGetAcsVideoInfoTime(new Date(Date.now())));
      return newFirstStartVideoFlag;
    } catch (e: any) {
      //sentry log
      const sentryContextApi: sentryContext = {
        contextID: SENTRT_CONTEXT_ID,
        info: {
          api: ACS_VIDEO_INFO
        }
      };
      const sentryContextApiError = { ...sentryContextApi, info: { ...sentryContextApi.info, 'errCode': e.errorCode ?? e.code } };
      sendSentryLog('Api Error', 'apiError', 'error',
        { 'event_name': 'api_error', 'diagnosisCode': selectedDiagnosisCode }
        , [sentryContextApiError]
      );
      dispatch(setClinicErrorCode(e.errorCode ?? e.code));
    }
  }
}